import {
  GET_MADRID_SETTINGS,
  GET_MADRID_SETTINGS_SUCCESS,
  SAVE_MADRID_SETTINGS,
  SAVE_MADRID_SETTINGS_SUCCESS,
  SET_SUCCESS,
  SET_RESULT,
  GET_MADRID_NOTIFIER,
  GET_MADRID_NOTIFIER_SUCCESS,
  GET_MADRID_NOTIFIER_INFO,
  GET_MADRID_NOTIFIER_INFO_SUCCESS,
  GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK,
  CHANGE_MADRID_NOTIFIER,
  MULTIPLE_HIDE_MADRID,
  MULTIPLE_UNHIDE_MADRID,
  DOWN_MADRID_EXCEL,
  DOWN_MADRID_EXCEL_SUCCESS,
  DOWN_MULTIPLE_MADRID_EXCEL,
  DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS,
  DOWN_MADRID_PDF,
  DOWN_MADRID_PDF_SUCCESS,
  DOWN_MULTIPLE_MADRID_PDF,
  DOWN_MULTIPLE_MADRID_PDF_SUCCESS,
  SEND_MADRID_EMAIL,
  PREVIEW_MADRID_EMAIL,
  PREVIEW_MADRID_EMAIL_SUCCESS,
  SET_PREVIEW_EMAIL,
  CLEAR_MADRID_NOTIFIER,
  SET_MADRID_SETTINGS,
  SET_MADRID_LOADING,
  SET_UPGRADE_ALERT_MESSAGE,
  SET_MADRID_SETTINGS_DRU,
  SET_IS_DRU_SEARCH,
  CHANGE_SENT_RESULT,
  SET_SENT_SUCCESS,
  GET_OCMN_LIST,
  GET_OCMN_LIST_SUCCESS,
  SAVE_OCMN_SHOW_STARTUP,
  SAVE_OCMN_SHOW_STARTUP_SUCCESS,
  SAVE_OCMN_AUTO_SEND_YN,
  SAVE_OCMN_AUTO_SEND_YN_SUCCESS,
  GET_OCMN_SUGGESTED_CONTACTS,
  GET_OCMN_SUGGESTED_CONTACTS_SUCCESS,
  SEND_OCMN_EMAIL,
  SEND_OCMN_EMAIL_SUCCESS,
  SET_OCMN_OPEN,
  SET_OCMN_LOADING,
  SET_OCMN_INDEX,
  OCMN_PREVIEW_MADRID_EMAIL,
  OCMN_PREVIEW_MADRID_EMAIL_SUCCESS,
  SET_OCMN_PREVIEW_EMAIL,
  UNLOCK_OCMN,
  CHANGE_OCMN_LIST_UNLOCK,
  CHANGE_OCMN_SUGGESTED_CONTACTS_LIST,
  SET_OCMN_SHOW_STARTUP,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  success: "",
  result: -1,
  searchSettings: {},
  freeMemberAutosearch: "N",
  userGrade: "00",
  madridNotifier: [],
  totalPage: 1,
  noticeStr: "",
  previewEmail: {},
  previewPdf: "",
  isSearched: false,
  upgradeAlertMessage: {title:"",text:""},
  isDruSearch: false,
  sentCnt: 0,
  unsubsEmails: [],
  disableEmails: [],
  sentIn24Emails: [],
  sentSuccess: "",
  ocmnOpen: false,
  ocmnLoaded: false,
  ocmnLoading: false,
  ocmnIndex: 0,
  ocmnList: [],
  ocmnPreviousStartDate: "00000000",
  ocmnStartDate: "00000000",
  ocmnEndDate: "00000000",
  ocmnPreviewEmail: {},
  ocmnPreviewPdf: "",
  ocmnPreviewLoading: false,
  ocmnSuggestedContactsList: [],
  ocmnCurPage: 1,
  ocmnTotalPage: 1,
  ocmnDataPosition: "",
  showStartup: 'N',
  ocmnAutoSendYn: "N",
}

const madridNotifier = (state = initialState, action) => {
  switch (action.type) {
    case GET_MADRID_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case GET_MADRID_SETTINGS_SUCCESS:
      return {
        ...state,
        searchSettings: action.payload.searchSettings,
        freeMemberAutosearch: action.payload.freeMemberAutosearch,
        userGrade: action.payload.userGrade,
        loading: false,
      }
    case SAVE_MADRID_SETTINGS:
      return {
        ...state,
        loading: true,
      }
    case SAVE_MADRID_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        // searchSettings: action.payload.searchSettings,
        success: action.payload.result,
        freeMemberAutosearch: "N",
      }
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case SET_RESULT:
      return {
        ...state,
        loading: false,
        result: action.payload,
      }
    case GET_MADRID_NOTIFIER:
      return {
        ...state,
        loading: true,
        madridNotifier: [],
      }
    case GET_MADRID_NOTIFIER_SUCCESS:
      return {
        ...state,
        madridNotifier: action.payload.madridNotifier,
        noticeStr: action.payload.noticeStr,
        totalPage: action.payload.totalPage,
        loading: false,
        freeMemberAutosearch: "N",
        userGrade: action.payload.userGrade,
      }
    case GET_MADRID_NOTIFIER_INFO:
      return {
        ...state,
        loading: false
      }
    case GET_MADRID_NOTIFIER_INFO_SUCCESS:
      // console.log('state.madridNotifier : ' + JSON.stringify(state.madridNotifier));
      // console.log('action.payload.madridNotifier : ' + JSON.stringify(action.payload.madridNotifier));

      if (action.payload.madridNotifier.length > 0) {
        let _newMadridNotifier = [];
        state.madridNotifier.map((madrid, index) => {
          let isChange = false;
          action.payload.madridNotifier.map((newMadrid, index2) => {
            if (madrid.irNumber == newMadrid.irNumber && madrid.ftpDate == newMadrid.ftpDate && madrid.crCode == newMadrid.crCode) {
              isChange = true;
              _newMadridNotifier.push(newMadrid);

              // console.log(isChange + ' : ' + newMadrid.irNumber + ', ' + madrid.ftpDate + ', ' + madrid.crCode);
            }
          });

          if (!isChange) {
            _newMadridNotifier.push(madrid);
          }
        });

        return {
          ...state,
          madridNotifier: _newMadridNotifier,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK:
      if (action.payload.madridNotifier.length > 0) {
        let _newMadridNotifier = [];
        state.madridNotifier.map((madrid, index) => {
          let isChange = false;
          action.payload.madridNotifier.map((newMadrid, index2) => {
            if (madrid.repRankCode == newMadrid.repRankCode) {
              isChange = true;
              _newMadridNotifier.push(newMadrid);

              // console.log(isChange + ' : ' + newMadrid.irNumber + ', ' + madrid.ftpDate + ', ' + madrid.crCode);
            }
          });

          if (!isChange) {
            _newMadridNotifier.push(madrid);
          }
        });

        return {
          ...state,
          madridNotifier: _newMadridNotifier,
          loading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case CHANGE_MADRID_NOTIFIER:
      return {
        ...state,
        madridNotifier: action.payload,
      }
    case MULTIPLE_HIDE_MADRID:
      return {
        ...state,
        loading: true,
      }
    case MULTIPLE_UNHIDE_MADRID:
      return {
        ...state,
        loading: true,
      }
    case DOWN_MADRID_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_MADRID_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_MADRID_EXCEL:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MADRID_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_MADRID_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DOWN_MULTIPLE_MADRID_PDF:
      return {
        ...state,
        loading: true
      }
    case DOWN_MULTIPLE_MADRID_PDF_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case SEND_MADRID_EMAIL:
      return {
        ...state,
        loading: true
      }
    case PREVIEW_MADRID_EMAIL:
      return {
        ...state,
        loading: true
      }
    case PREVIEW_MADRID_EMAIL_SUCCESS:
      return {
        ...state,
        previewEmail: action.payload.previewEmail,
        previewPdf: action.payload.previewPdf,
        // loading: false
      }
    case SET_PREVIEW_EMAIL:
      return {
        ...state,
        previewEmail: action.payload,
        previewPdf: "",
      }
    case CLEAR_MADRID_NOTIFIER:
      return {
        ...state,
        madridNotifier: [],
      }
    case SET_MADRID_SETTINGS:
      return {
        ...state,
        isSearched: true,
        searchSettings: action.payload,
      }
    case SET_MADRID_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_UPGRADE_ALERT_MESSAGE:
      return {
        ...state,
        upgradeAlertMessage: action.payload,
      }
    case SET_MADRID_SETTINGS_DRU:
      return {
        ...state,
        isDruSearch: true,
        searchSettings: action.payload,
      }
    case SET_IS_DRU_SEARCH:
      return {
        ...state,
        isDruSearch: action.payload,
      }
    case CHANGE_SENT_RESULT:
      // console.log('sentCnt : ' + action.payload.sentCnt);

      return {
        ...state,
        sentCnt: action.payload.sentCnt,
        unsubsEmails: action.payload.unsubsEmails,
        disableEmails: action.payload.disableEmails,
        sentIn24Emails: action.payload.sentIn24Emails,
        sentSuccess: 'Success',
      }
    case SET_SENT_SUCCESS:
      return {
        ...state,
        sentSuccess: action.payload,
      }
    case GET_OCMN_LIST:
      return {
        ...state,
        ocmnLoading: true,
      }
    case GET_OCMN_LIST_SUCCESS:
      if (action.payload.ocmnList) {
        return {
          ...state,
          ocmnLoading: false,
          ocmnLoaded: action.payload.dataPosition === "" ? true : action.payload.ocmnList.length > 0,
          ocmnIndex: action.payload.changeFlag === 'Previous' ? action.payload.ocmnList.length-1 : 0,
          ocmnList: action.payload.ocmnList ? action.payload.ocmnList : [],
          ocmnPreviousStartDate: action.payload.previousStartDate,
          ocmnStartDate: action.payload.startDate,
          ocmnEndDate: action.payload.endDate,
          ocmnDataPosition: action.payload.dataPosition,
          ocmnCurPage: action.payload.curPage ? action.payload.curPage : 1,
          ocmnTotalPage: action.payload.totalPage ? action.payload.totalPage : 1,
          ocmnAutoSendYn: action.payload.ocmnAutoSendYn ? action.payload.ocmnAutoSendYn : "N",
        }
      } else {
        return {
          ...state,
          ocmnLoading: false,
          ocmnDataPosition: action.payload.dataPosition,
        }
      }
    case SAVE_OCMN_AUTO_SEND_YN_SUCCESS:
      return {
        ...state,
        ocmnAutoSendYn: action.payload,
      }
    case GET_OCMN_SUGGESTED_CONTACTS:
      return {
        ...state,
        ocmnSuggestedContactsList: [],
      }
    case GET_OCMN_SUGGESTED_CONTACTS_SUCCESS:
      return {
        ...state,
        ocmnSuggestedContactsList: action.payload.ocmnSuggestedContactsList,
      }
    case SEND_OCMN_EMAIL:
      return {
        ...state,
        ocmnLoading: true
      }
    case SEND_OCMN_EMAIL_SUCCESS:
      // TODO 2023-06-22 이메일 발송 후 페이징 처리되면서 다음페이지의 앞 데이터가 앞 페이지로 넘어가는 현상 발견, 우선은  현재 버전으로 진행하고 추후에 모니터링 후 배치로 데이터 수집하여 처리하는 방향으로 갈지 정하기로 함 
      // let today = new Date();
      // let contactDate = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" +today.getDate()).slice(-2);

      let oldOcmnIndex = state.ocmnIndex;
      let oldOcmnListLength = state.ocmnList.length;

      let _newMadridNotifier = [];
      state.ocmnList.map((madrid, index) => {
        let isSent = false;
        action.payload.madridNotifierList.map((newMadrid, index2) => {
          if (madrid.irNumber == newMadrid.irNumber && madrid.ftpDate == newMadrid.ftpDate && madrid.crCode == newMadrid.crCode) {
            isSent = true;
            // newMadrid.lastContactDate = contactDate;
            // _newMadridNotifier.push(newMadrid);
          }
        });

        if (!isSent) {
          _newMadridNotifier.push(madrid);
        }
      });

      // 2025-01-28 마지막 데이터 일 때 화면 사라지는 현상 제거 위해 마지막 더미데이터 등록  
      if (_newMadridNotifier.length == 0) {
        action.payload.madridNotifierList.map((newMadrid, index2) => {
          _newMadridNotifier.push(newMadrid);
        });
      }

      return {
        ...state,
        ocmnLoading: false,
        ocmnIndex: oldOcmnIndex <= _newMadridNotifier.length-1 ? oldOcmnIndex : _newMadridNotifier.length > 0 ? _newMadridNotifier.length-1 : 0,
        ocmnList: _newMadridNotifier,
        sentCnt: action.payload.sentCnt,
        unsubsEmails: action.payload.unsubsEmails,
        disableEmails: action.payload.disableEmails,
        sentIn24Emails: action.payload.sentIn24Emails,
        sentSuccess: oldOcmnIndex === oldOcmnListLength-1 ? 'Next' : 'Success',
      }
    case SET_OCMN_OPEN: 
      return {
        ...state,
        ocmnOpen: action.payload,
        showStartup: action.payload ? state.showStartup : 'N',
      }
    case SET_OCMN_LOADING:
      return {
        ...state,
        ocmnLoading: action.payload,
      }
    case SET_OCMN_INDEX:
      return {
        ...state,
        ocmnIndex: action.payload,
      }
    case OCMN_PREVIEW_MADRID_EMAIL:
      return {
        ...state,
        ocmnPreviewLoading: true,
        ocmnPreviewEmail: {},
        ocmnPreviewPdf: "",
      }
    case OCMN_PREVIEW_MADRID_EMAIL_SUCCESS:
      return {
        ...state,
        ocmnPreviewEmail: action.payload.previewEmail,
        ocmnPreviewPdf: action.payload.previewPdf,
        ocmnPreviewLoading: false,
      }
    case SET_OCMN_PREVIEW_EMAIL:
      return {
        ...state,
        ocmnPreviewEmail: action.payload,
        previewPdf: "",
      }
    case UNLOCK_OCMN:
      return {
        ...state,
        ocmnLoading: true,
      }
    case CHANGE_OCMN_LIST_UNLOCK:
      if (action.payload.madridNotifier.length > 0) {
        let _newMadridNotifier = [];
        state.ocmnList.map((madrid, index) => {
          let isChange = false;
          action.payload.madridNotifier.map((newMadrid, index2) => {
            if (madrid.repRankCode == newMadrid.repRankCode) {
              isChange = true;
              _newMadridNotifier.push(newMadrid);

              // console.log(isChange + ' : ' + newMadrid.irNumber + ', ' + madrid.ftpDate + ', ' + madrid.crCode);
            }
          });

          if (!isChange) {
            _newMadridNotifier.push(madrid);
          }
        });

        return {
          ...state,
          ocmnList: _newMadridNotifier,
          ocmnLoading: false,
        }
      } else {
        return {
          ...state,
          loading: false,
        }
      }
    case CHANGE_OCMN_SUGGESTED_CONTACTS_LIST:
      return {
        ...state,
        ocmnSuggestedContactsList: action.payload,
        previewPdf: "",
      }
    case SET_OCMN_SHOW_STARTUP:
      return {
        ...state,
        showStartup: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default madridNotifier
