import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import classnames from "classnames"
import AdmDomainActionHistory from "./AdmDomainActionHistory";
import SelectAllJurisdictions from '../../components/Filter/SelectAllJurisdictions';
import EntityDetail from "./EntityDetail";

import {
  getDomainList,
  changeDomainList,
  getDomainActionHistory,
  saveDomainInfo,
  getBlackList,
  addBlackList,
  downExcelBlackList,
  setAdmDomainManagementSuccess,
  setAdmDomainManagementError,
  setAdmDomainLoading as onSetAdmDomainLoading,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const AdmDomainManagement = props => {
  const dispatch = useDispatch();

  const { loading, error, success, searchSettingsDm, domainList, totalPageDm, searchSettingsBl, blackList, totalPageBl } = useSelector(state => ({
    loading: state.AdmDomainManagement.loading,
    error: state.AdmDomainManagement.error,
    success: state.AdmDomainManagement.success,
    searchSettingsDm: state.AdmDomainManagement.searchSettingsDm,
    domainList: state.AdmDomainManagement.domainList,
    totalPageDm: state.AdmDomainManagement.totalPageDm,
    domainActionHistory: state.AdmDomainManagement.domainActionHistory,
    searchSettingsBl: state.AdmDomainManagement.searchSettingsBl,
    blackList: state.AdmDomainManagement.blackList,
    totalPageBl: state.AdmDomainManagement.totalPageBl,
  }));

  const [curPageInputDm, setCurPageInputDm] = useState(1);
  const [curPageInputBl, setCurPageInputBl] = useState(1);
  const [chkAllFlag, setChkAllFlag] = useState(false);

  const [activeTab, setactiveTab] = useState("Management")
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [columns, setColumns] = useState([
    {label: "Gold + Trial Count", asc:"GACA", desc:"GACD", ascActive:null, descActive:null},
  ]);

  const viewDomainList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPageDm) {
        curPage = totalPageDm;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      columns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "viewDm");
   
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPageDm", curPage);
      setCurPageInputDm(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPageDm", 1);
      setCurPageInputDm(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPageDm", perPage);
    }
    // madridValidation.setValues(_values);

    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const [blackListColumns, setblackListColumns] = useState([
    {label: "Domain", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Username", asc:null, desc:null, ascActive:null, descActive:null},
    {label: "Regdate", asc:null, desc:null, ascActive:null, descActive:null},
  ]);

  const viewBlackList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPageBl) {
        curPage = totalPageBl;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }
    //빨간색으로 변경
    if(sortType){
      const _columns = [];
      blackListColumns.map((col, index)=>{
        let _col = cloneDeep(col);
        if(col.asc && col.asc === sortType){
          _col.ascActive = "text-danger";
          _col.descActive = "";
        }else if(col.desc && col.desc === sortType ){
          _col.ascActive = "";
          _col.descActive = "text-danger";
        }else{
          _col.ascActive = "";
          _col.descActive = "";
        }
        _columns.push(_col);
      });

      setblackListColumns(_columns);
    }

    // console.log("viewMadridNotifier [sortType: "+sortType+", curPage: "+curPage+", perPage: "+perPage);
    // let _values = cloneDeep(madridValidation.values);
    // _values.submitType = "view";
    filterValidation.setFieldValue("submitType", "viewBl");
    if(curPage){
      // _values.curPage = curPage;
      filterValidation.setFieldValue("curPageBl", curPage);
      setCurPageInputBl(curPage);
    }else{
      // _values.curPage = 1;
      filterValidation.setFieldValue("curPageBl", 1);
      setCurPageInputBl(1);
    }
    if(perPage){
      // _values.perPage = perPage;
      filterValidation.setFieldValue("perPageBl", perPage);
    }
    // madridValidation.setValues(_values);
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      
      sortType: "",
      entity: searchSettingsDm && searchSettingsDm.entity? searchSettingsDm.entity : "",
      domain: searchSettingsDm && searchSettingsDm.domain? searchSettingsDm.domain : "",
      emailAddress: searchSettingsDm && searchSettingsDm.emailAddress? searchSettingsDm.emailAddress : "",
      entityType: searchSettingsDm && searchSettingsDm.entityType? searchSettingsDm.entityType : "Y",
      procesYn: searchSettingsDm && searchSettingsDm.procesYn? searchSettingsDm.procesYn : "",
      domainCorrectYn: searchSettingsDm && searchSettingsDm.domainCorrectYn? searchSettingsDm.domainCorrectYn : "",
      emailScrapYn: searchSettingsDm && searchSettingsDm.emailScrapYn? searchSettingsDm.emailScrapYn : "",
      domainCorrectHis: searchSettingsDm && searchSettingsDm.domainCorrectHis? searchSettingsDm.domainCorrectHis : "",
      jurType: "",
      jrCdList: [],
      fromDate: undefined,
      toDate: undefined,
      perPageDm: 20,
      curPageDm: 1,

      blackDomain: searchSettingsBl && searchSettingsBl.domain? searchSettingsBl.domain : "",
      perPageBl: 20,
      curPageBl: 1,
    },
    
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));

      if (activeTab === "Management") {
        const filterParam = {
          entity: values["entity"],
          domain: values["domain"],
          emailAddress: values["emailAddress"],
          entityType: values["entityType"],
          procesYn: values["procesYn"],
          domainCorrectYn: values["domainCorrectYn"],
          emailScrapYn: values["emailScrapYn"],
          domainCorrectHis: values["domainCorrectHis"],
          jrCdList: values["jurType"] === "All"? [] : values["jrCdList"],
          startDate: values["fromDate"] && values["fromDate"].replaceAll('-', ''),
          endDate: values["fromDate"] && values["fromDate"].replaceAll('-', ''),
          perPage: values["perPageDm"],
          curPage: values["curPageDm"],
          sortType: values["sortType"],
        };

        let submitType = values["submitType"];
        
        if(submitType === "viewDm"){

          console.log('jurType : ' + values["jurType"] + ', fromDate : ' + values["fromDate"]);

          if (((values["jurType"] !== '' && values["jurType"] !== "All") && (values["fromDate"] === "" || values["fromDate"] === undefined))) {
            dispatch(setAdmDomainManagementError("If you select a jurisdiction, please select Inscription date for search."));
          } else {
            dispatch(getDomainList(filterParam));
          }
          
        } else if(submitType === "viewDmAc"){

          // console.log('filterParam >> ' + JSON.stringify(filterParam));
  
          dispatch(getDomainActionHistory(filterParam));
  
        } else if(submitType === "saveDm"){

          const _domainList = [];
          domainList.map((domain, entityIdx) =>{
            if(domain.checked) {
              domain.action = "save"; 
              _domainList.push(domain);
            }
          }); 

          if (_domainList.length > 0) dispatch(saveDomainInfo(_domainList));
        } else if(submitType === "clearDm"){
          
          let hasUserLinkYn = false;
          const _domainList = [];
          domainList.map((domain, entityIdx) =>{
            if(domain.checked) {

              if (domain.userLinkYn == 'Y') {
                hasUserLinkYn = true;
                return false;
              }

              domain.action = "clear"; 
              _domainList.push(domain);
            }
          }); 

          if (hasUserLinkYn) {
            dispatch(setAdmDomainManagementError("This entity is linked to a user and cannot be clear."));
          } else if (_domainList.length > 0) {
            dispatch(saveDomainInfo(_domainList));
          }
        }
      } else {
        // console.log("searchNoList : " + JSON.stringify(values["searchNoList"]));

        const filterParam = {
          domain: values["blackDomain"],
        };

        let fileName = dateFormat(new Date(), "yymmdd") + " WeCrest Admin Black Domain Management"; 
        
        let submitType = values["submitType"];
        
        if(submitType === "viewBl"){
  
          // console.log('filterParam >> ' + JSON.stringify(filterParam));
  
          dispatch(getBlackList(filterParam));
        }else if(submitType === "addBl"){
  
          dispatch(addBlackList(filterParam));

        }else if(submitType === "excelBl"){
          dispatch(downExcelBlackList(filterParam, fileName));
        }
      }
    }
  })

  const entityCheck = (entityChkIdx, value) => {
    let _domainList = cloneDeep(domainList);

    if(entityChkIdx == "chkAll"){
      setChkAllFlag(value);
      _domainList = _domainList.map((domain, entityIdx) => {
        domain.checked = value;
        
        return domain;
      })
    }else{
      _domainList[entityChkIdx].checked = value
    }

    dispatch(changeDomainList(_domainList));
  }

  const setAdmDomainLoading = () => {
    dispatch(onSetAdmDomainLoading(!loading));
  }

  const [detailTargetGroupNo, setDetailTargetGroupNo] = useState('');
  const [isOpenEntityDetail, setIsOpenEntityDetail] = useState(false);
  function togEntityDetail() {
    setIsOpenEntityDetail(!isOpenEntityDetail);
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
  }

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Nav tabs className="col-md-6 nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Management",
                })}
                onClick={() => {
                  toggleActiveTab("Management")
                }}
              >
                <span>Domain management</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "Blacklist",
                })}
                onClick={() => {
                  toggleActiveTab("Blacklist")
                }}
              >
                <span>Domain blacklist</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            className="text-muted"
          >
            <TabPane tabId="Management">
              <Row>
                <Col lg={12}>
                  <Card>
                    <Link onClick={toggle} className="text-dark" to="#">
                      <div className="p-4">
                        <Media className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <i className="uil uil-globe text-primary h2"></i>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-16 mb-1">Domain management</h5>
                            {/*
                            <p className="text-muted mb-0">Use this data to evaluate the performance of your emails sent through WeCrest and to optimize your future emails for a higher deliverability. 
                            In order to improve the your email deliverability, please visit <a href="https://www.wecrest.com/insights" target="_blank" rel="noreferrer">our blog</a> for more ideas.
                            *Please note that this data is available only after you have updated your sender email in the <a href="/settings">Settings</a> page.</p>
                            */}
                          </div>
                          <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                        </Media>
                      </div>
                    </Link>
                    <Collapse isOpen={isOpen}>
                      <div className="p-4 border-top">
                        <div>
                          <Row className="mb-3">
                            <label
                            htmlFor="irNumber"
                            className="col-md-2 col-form-label"
                            >
                            Entity
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter entity"
                                id="entity"
                                name="entity"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.entity}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    setCurPageInputDm(1);
                                    filterValidation.setFieldValue("curPageDm", 1);
                                    filterValidation.setFieldValue("submitType", "viewDm");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }                      
                                }}
                            />
                            </div>
                          </Row>
                          
                          <Row className="mb-3">
                            <label
                            htmlFor="toEmailMn"
                            className="col-md-2 col-form-label"
                            >
                            Domain
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter domain"
                                id="domain"
                                name="domain"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.domain}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    setCurPageInputDm(1);
                                    filterValidation.setFieldValue("curPageDm", 1);
                                    filterValidation.setFieldValue("submitType", "viewDm");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }                      
                                }}
                            />
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <label
                            htmlFor="toEmailMn"
                            className="col-md-2 col-form-label"
                            >
                            Email address
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter email address"
                                id="emailAddress"
                                name="emailAddress"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.emailAddress}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    setCurPageInputDm(1);
                                    filterValidation.setFieldValue("curPageDm", 1);
                                    filterValidation.setFieldValue("submitType", "viewDm");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }                      
                                }}
                            />
                            </div>
                          </Row>

                          <Row className="mb-3 align-items-center">
                            <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                            Type
                            </label>
                            <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="entityTypeBoth"
                                    name="entityType"
                                    onChange={filterValidation.handleChange}
                                    value=""
                                    checked={filterValidation.values.entityType === ""}
                                  />
                                  <Label className="form-check-label" htmlFor="entityTypeBoth">
                                  Both
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="entityTypeYes"
                                    name="entityType"
                                    onChange={filterValidation.handleChange}
                                    value="Y"
                                    checked={filterValidation.values.entityType === "Y"}
                                  />
                                  <Label className="form-check-label" htmlFor="entityTypeYes">
                                  Representative
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="entityTypeNo"
                                    name="entityType"
                                    onChange={filterValidation.handleChange}
                                    value="N"
                                    checked={filterValidation.values.entityType === "N"}
                                  />
                                  <Label className="form-check-label" htmlFor="entityTypeNo">
                                  Holder
                                  </Label>
                              </div>
                              
                            </div>
                          </Row>

                          <Row className="mb-3 align-items-center">
                            <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                            Domain searched?
                            </label>
                            <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="procesYnBoth"
                                    name="procesYn"
                                    onChange={filterValidation.handleChange}
                                    value=""
                                    checked={filterValidation.values.procesYn === ""}
                                  />
                                  <Label className="form-check-label" htmlFor="procesYnBoth">
                                  Both
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="procesYnYes"
                                    name="procesYn"
                                    onChange={filterValidation.handleChange}
                                    value="Y"
                                    checked={filterValidation.values.procesYn === "Y"}
                                  />
                                  <Label className="form-check-label" htmlFor="procesYnYes">
                                    Yes
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="procesYnNo"
                                    name="procesYn"
                                    onChange={filterValidation.handleChange}
                                    value="N"
                                    checked={filterValidation.values.procesYn === "N"}
                                  />
                                  <Label className="form-check-label" htmlFor="procesYnNo">
                                    No
                                  </Label>
                              </div>
                              
                            </div>
                          </Row>
                          
                          <Row className="mb-3 align-items-center">
                            <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                            Domain verified?
                            </label>
                            <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectYnBoth"
                                    name="domainCorrectYn"
                                    onChange={filterValidation.handleChange}
                                    value=""
                                    checked={filterValidation.values.domainCorrectYn === ""}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectYnBoth">
                                  Both
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectYnYes"
                                    name="domainCorrectYn"
                                    onChange={filterValidation.handleChange}
                                    value="Y"
                                    checked={filterValidation.values.domainCorrectYn === "Y"}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectYnYes">
                                    Yes
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectYnNo"
                                    name="domainCorrectYn"
                                    onChange={filterValidation.handleChange}
                                    value="N"
                                    checked={filterValidation.values.domainCorrectYn === "N"}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectYnNo">
                                    No
                                  </Label>
                              </div>
                              
                            </div>
                          </Row>

                          <Row className="mb-3 align-items-center">
                            <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                            Emails scraped?
                            </label>
                            <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                            <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="emailScrapYnBoth"
                                    name="emailScrapYn"
                                    onChange={filterValidation.handleChange}
                                    value=""
                                    checked={filterValidation.values.emailScrapYn === ""}
                                  />
                                  <Label className="form-check-label" htmlFor="emailScrapYnBoth">
                                  Both
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="emailScrapYnCorrect"
                                    name="emailScrapYn"
                                    onChange={filterValidation.handleChange}
                                    value="Y"
                                    checked={filterValidation.values.emailScrapYn === "Y"}
                                  />
                                  <Label className="form-check-label" htmlFor="emailScrapYnCorrect">
                                  Yes
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="emailScrapYnIncorrect"
                                    name="emailScrapYn"
                                    onChange={filterValidation.handleChange}
                                    value="N"
                                    checked={filterValidation.values.emailScrapYn === "N"}
                                  />
                                  <Label className="form-check-label" htmlFor="emailScrapYnIncorrect">
                                  No
                                  </Label>
                              </div>
                              
                            </div>
                          </Row>

                          <Row className="mb-3 align-items-center">
                            <label htmlFor="entityStatus" className="col-md-2 col-form-label">
                            Correct domain?
                            </label>
                            <div className="col-md-4 d-flex" style={{justifyContent: "space-between"}}>
                            <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectHisBoth"
                                    name="domainCorrectHis"
                                    onChange={filterValidation.handleChange}
                                    value=""
                                    checked={filterValidation.values.domainCorrectHis === ""}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectHisBoth">
                                  Both
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectHisCorrect"
                                    name="domainCorrectHis"
                                    onChange={filterValidation.handleChange}
                                    value="Y"
                                    checked={filterValidation.values.domainCorrectHis === "Y"}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectHisCorrect">
                                  Yes
                                  </Label>
                              </div>
                              <div className="form-check">
                                  <input type="radio" className="form-check-input"
                                    id="domainCorrectHisIncorrect"
                                    name="domainCorrectHis"
                                    onChange={filterValidation.handleChange}
                                    value="N"
                                    checked={filterValidation.values.domainCorrectHis === "N"}
                                  />
                                  <Label className="form-check-label" htmlFor="domainCorrectHisIncorrect">
                                  No
                                  </Label>
                              </div>
                              
                            </div>
                          </Row>

                          <SelectAllJurisdictions
                            formik={filterValidation}
                            firstColMd={2}
                          />

                          <Row>
                            <label htmlFor="InscriptionDate" className="col-md-2 col-form-label">
                            Inscription date
                            </label>
                            <div className="col-md-2">
                              <Input
                                className="form-control"
                                type="date"
                                id="fromDate"
                                name="fromDate"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.fromDate}
                                onPaste={(e) => {
                                    // console.log("onpaste :: " + e.clipboardData.getData('Text'));
                                    try {
                                        let checkDate = String(e.clipboardData.getData('Text'));
                                        if (checkDate.indexOf('-') > -1 && checkDate.length == 10) {
                                          filterValidation.setFieldValue("fromDate", checkDate);
                                        } else if (checkDate.length == 8) {
                                          filterValidation.setFieldValue("fromDate", checkDate.substring(0,4) + '-' + checkDate.substring(4,6) + '-' + checkDate.substring(6,8));
                                        }
                                    } catch (error) {
                                        console.log("error :: " + error);
                                    }
                                }}
                                />
                            </div>
                          </Row>
                        
                          <Row >
                            <Col md={2}>&nbsp;</Col>
                            <Col md={3}>&nbsp;</Col>
                            <Col md={3}>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light col-md-5"
                                onClick={()=>{
                                  setCurPageInputDm(1);
                                  filterValidation.setFieldValue("curPageDm", 1);
                                  filterValidation.setFieldValue("submitType", "viewDm");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
              {domainList && domainList.length ?
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="text-muted mb-0">
                        <Row className="mb-3">
                          <span className="col-md-8 button-items">
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light col-md-2"
                              onClick={()=>{
                                filterValidation.setFieldValue("submitType", "saveDm");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                            >
                              Save entity info
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light col-md-2"
                              onClick={()=>{
                                filterValidation.setFieldValue("submitType", "clearDm");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                            >
                              Full reset entity info
                            </Button>
                          </span>
                        </Row>
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllFlag} onClick={(e)=>entityCheck("chkAll", e.target.checked)} /></th>
                            <th>Entity</th>
                            <th>Domain</th>
                            <th>URL</th>
                            <th>Type</th>
                            <th style={{textAlign: "center"}}>Domain searched?</th>
                            <th style={{textAlign: "center"}}>Domain verified?</th>
                            <th style={{textAlign: "center"}}>Emails scraped?</th>
                            <th style={{textAlign: "center"}}>Email count</th>
                            <th style={{textAlign: "center"}}>Correct domain</th>
                            <th style={{textAlign: "center"}}>Incorrect domain</th>
                            <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center", verticalAlign: "middle"}}>
                              Gold Count
                              <Link to='#' onClick={()=> {
                                 if (filterValidation.values["fromDate"] === "" || filterValidation.values["fromDate"] === undefined) {
                                  dispatch(setAdmDomainManagementError("If you wish to sort the number of gold accounts, please select an inscription date."));
                                 } else {
                                  viewDomainList(columns[0].asc)
                                 }
                              }} className={columns[0].ascActive}><i className="btn-asc-40 uil uil-angle-up font-size-18"></i></Link>
                              <Link to='#' onClick={()=> {
                                if (filterValidation.values["fromDate"] === "" || filterValidation.values["fromDate"] === undefined) {
                                  dispatch(setAdmDomainManagementError("If you wish to sort the number of gold accounts, please select an inscription date."));
                                } else {
                                  viewDomainList(columns[0].desc)
                                }
                              }} className={columns[0].descActive}><i className="btn-desc-40 uil uil-angle-down font-size-18"></i></Link>
                            </th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            domainList.map((domainInfo, index) => {
                              return (
                                  <tr key={index}>
                                    <td align="center">
                                      <input type="checkbox" 
                                        checked={domainInfo.checked}
                                        onClick={(e)=>entityCheck(index, e.target.checked)}
                                        />
                                    </td>
                                    <td>
                                      <Row>
                                        <div className="d-flex vertical-align-center gap-2">
                                        {
                                          domainInfo.userNo > 0 ?
                                          <i className="uil uil-link font-size-18 text-danger"></i>
                                          : null
                                        }
                                        <a href='#' onClick={() => {
                                          setDetailTargetGroupNo(domainInfo.targetGroupNo)
                                          setIsOpenEntityDetail(true)
                                        }} >{domainInfo.targetGroupName}</a>
                                        <a href='#' onClick={() => {dispatch(getDomainActionHistory({targetGroupNo:domainInfo.targetGroupNo}))}} ><i className="uil uil-history font-size-18"></i></a>
                                        </div>
                                      </Row>
                                    </td>
                                    <td align="center">
                                      <Input
                                          className="form-control"
                                          type="text"
                                          placeholder="Enter domain"
                                          onChange={(e) => {
                                            if (e.target.value.indexOf("/") > -1) {
                                              dispatch(setAdmDomainManagementError("Domain cannot include slash('/')."));
                                            } else if (e.target.value.indexOf(",") > -1) {
                                              dispatch(setAdmDomainManagementError("Domain cannot include comma(',')."));
                                            } else {
                                              let _domainList = cloneDeep(domainList);
                                              _domainList[index].domain = e.target.value;
                                              dispatch(changeDomainList(_domainList));
                                            }
                                          }}
                                          value={domainInfo.domain}
                                      />
                                    </td>
                                    <td align="center">
                                      <Input
                                          className="form-control"
                                          type="text"
                                          placeholder="Enter url"
                                          onChange={(e) => {
                                            let _domainList = cloneDeep(domainList);
                                            _domainList[index].url = e.target.value;
                                            dispatch(changeDomainList(_domainList));
                                          }}
                                          value={domainInfo.url}
                                      />
                                    </td>
                                    <td align="center">{domainInfo.entityType}</td>
                                    <td align="center">{domainInfo.procesYn}</td>
                                    <td align="center">
                                      <select 
                                        className={"form-select select-option-default"}
                                        name="domainCorrectYn"
                                        onChange={(e) => {
                                          let _domainList = cloneDeep(domainList);
                                            _domainList[index].domainCorrectYn = e.target.value;
                                            dispatch(changeDomainList(_domainList));
                                        }}
                                        value={domainInfo.domainCorrectYn}
                                      >
                                        <option value='Y'>Y</option>
                                        <option value='N'>N</option>
                                      </select>
                                    </td>
                                    <td align="center">
                                      <select 
                                        className={"form-select select-option-default"}
                                        name="emailScrapYn"
                                        onChange={(e) => {
                                          let _domainList = cloneDeep(domainList);
                                            _domainList[index].emailScrapYn = e.target.value;
                                            dispatch(changeDomainList(_domainList));
                                        }}
                                        value={domainInfo.emailScrapYn}
                                      >
                                        <option value='Y'>Y</option>
                                        <option value='N'>N</option>
                                      </select>
                                    </td>
                                    <td align="center">{domainInfo.emailCnt}</td>
                                    <td align="center">{domainInfo.correctCnt}</td>
                                    <td align="center">{domainInfo.incorrectCnt}</td>
                                    <td align="center">{domainInfo.goldAccountCount}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-2">
                          <label
                            htmlFor="perPage"
                            >
                            Entries per page
                          </label>
                          <div style={{width: "100px"}}>
                            <select 
                              className="form-select" 
                              name="perPage"
                              onChange={e => {viewDomainList(null, 1, e.target.value);}}
                              value={filterValidation.values.perPageDm}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </span>
                        <span className="d-flex align-items-center gap-2">
                          <div className="d-flex gap-1">
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewDomainList(null, 1);}}
                              disabled={(filterValidation.values.curPageDm === 1)}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewDomainList(null, Number(filterValidation.values.curPageDm)-1);}}
                              disabled={(filterValidation.values.curPageDm === 1)}
                            >
                              {"<"}
                            </Button>
                          </div>
                          Page{" "}
                          <strong>
                            {filterValidation.values.curPageDm} of {totalPageDm}
                          </strong>
                          
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={totalPageDm}
                            name="curPage"
                            defaultValue={filterValidation.values.curPageDm}
                            value={curPageInputDm}
                            onChange={(e) => { setCurPageInputDm(e.target.value)}}
                            onKeyPress={(e) => {
                              if(e.key === 'Enter'){
                                viewDomainList(null, e.target.value);
                              }                      
                            }}
                          />
                          <div className="d-flex gap-1">
                            <Button 
                              type="button"
                              color="primary"
                              onClick={()=>{viewDomainList(null, Number(filterValidation.values.curPageDm)+1);}}
                              disabled={(filterValidation.values.curPageDm === totalPageDm)}
                            >
                              {">"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewDomainList(null, totalPageDm);}}
                              disabled={(filterValidation.values.curPageDm === totalPageDm)}
                            >
                              {">>"}
                            </Button>
                          </div>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :null}
            </TabPane>
            <TabPane tabId="Blacklist">
            <Row>
                <Col lg={12}>
                  <Card>
                    <Link onClick={toggle} className="text-dark" to="#">
                      <div className="p-4">
                        <Media className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <i className="uil uil-globe text-primary h2"></i>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="font-size-16 mb-1">Black list management</h5>
                            {/*
                            <p className="text-muted mb-0">Use this data to evaluate the performance of your emails sent through WeCrest and to optimize your future emails for a higher deliverability. 
                            In order to improve the your email deliverability, please visit <a href="https://www.wecrest.com/insights" target="_blank" rel="noreferrer">our blog</a> for more ideas.
                            *Please note that this data is available only after you have updated your sender email in the <a href="/settings">Settings</a> page.</p>
                            */}
                          </div>
                          <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                        </Media>
                      </div>
                    </Link>
                    <Collapse isOpen={isOpen}>
                      <div className="p-4 border-top">
                        <div>
                          <Row className="mb-3">
                            <label
                            htmlFor="toEmailMn"
                            className="col-md-2 col-form-label"
                            >
                            Domain
                            </label>
                            <div className="col-md-3">
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Enter domain"
                                id="blackDomain"
                                name="blackDomain"
                                onChange={filterValidation.handleChange}
                                value={filterValidation.values.blackDomain}
                                onKeyPress={(e) => {
                                  if(e.key === 'Enter'){
                                    setCurPageInputBl(1);
                                    filterValidation.setFieldValue("curPageBl", 1);
                                    filterValidation.setFieldValue("submitType", "viewBl");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }                      
                                }}
                            />
                            </div>
                            <Button
                              type="button"
                              color="primary"
                              outline
                              className="waves-effect waves-light col-md-2"
                              onClick={()=>{
                                filterValidation.setFieldValue("submitType", "addBl");
                                filterValidation.handleSubmit(filterValidation.values);
                              }}
                            >
                              Add
                            </Button>
                          </Row>
                          
                        
                          <Row>
                            <div className="col-md-12" style={{borderRight : "1px solid #f5f6f8!important"}}>
                              <Row >
                                <Button
                                  type="button"
                                  color="primary"
                                  outline
                                  className="waves-effect waves-light col-md-1"
                                  onClick={()=>{
                                    filterValidation.setFieldValue("submitType", "excelBl");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }}
                                >
                                  Excel
                                </Button>
                                <Col md={4}>&nbsp;</Col>
                                <button
                                  type="button"
                                  className="waves-effect waves-light btn btn-primary col-md-2"
                                  onClick={()=>{
                                    setCurPageInputBl(1);
                                    filterValidation.setFieldValue("curPageBl", 1);
                                    filterValidation.setFieldValue("submitType", "viewBl");
                                    filterValidation.handleSubmit(filterValidation.values);
                                  }}
                                >
                                  Search
                                </button>
                              </Row>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
              {blackList && blackList.length ?
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            {
                              blackListColumns && blackListColumns.length ?
                              blackListColumns.map((col, index) => {
                                  return (
                                    <th style={{position: "relative", whiteSpace: "pre-line", textAlign: "center"}}>
                                      {col.label}
                                      {col.asc && col.desc? <>
                                      <Link to='#' onClick={()=> {viewBlackList(col.asc)}} className={col.ascActive}><i className="btn-asc-30 uil uil-angle-up font-size-18"></i></Link>
                                      <Link to='#' onClick={()=> {viewBlackList(col.desc)}} className={col.descActive}><i className="btn-desc-30 uil uil-angle-down font-size-18"></i></Link>
                                      </>
                                    :null}
                                    </th>
                                  )
                              })
                              :null
                            }
                            </tr>
                          </thead>
                          <tbody>
                          {
                            blackList.map((blackDomain, index) => {
                              return (
                                  <tr key={index}>
                                    <td align="center">{blackDomain.domain}</td>
                                    <td align="center">{blackDomain.userName}</td>
                                    <td align="center">{blackDomain.regDate}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="d-flex align-items-center gap-2">
                          <label
                            htmlFor="perPage"
                            >
                            Entries per page
                          </label>
                          <div style={{width: "100px"}}>
                            <select 
                              className="form-select" 
                              name="perPage"
                              onChange={e => {viewBlackList(null, 1, e.target.value);}}
                              value={filterValidation.values.perPageBl}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                        </span>
                        <span className="d-flex align-items-center gap-2">
                          <div className="d-flex gap-1">
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewBlackList(null, 1);}}
                              disabled={(filterValidation.values.curPageBl === 1)}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewBlackList(null, Number(filterValidation.values.curPageBl)-1);}}
                              disabled={(filterValidation.values.curPageBl === 1)}
                            >
                              {"<"}
                            </Button>
                          </div>
                          Page{" "}
                          <strong>
                            {filterValidation.values.curPageBl} of {totalPageBl}
                          </strong>
                          
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={totalPageBl}
                            name="curPage"
                            defaultValue={filterValidation.values.curPageBl}
                            value={curPageInputBl}
                            onChange={(e) => { setCurPageInputBl(e.target.value)}}
                            onKeyPress={(e) => {
                              if(e.key === 'Enter'){
                                viewBlackList(null, e.target.value);
                              }                      
                            }}
                          />
                          <div className="d-flex gap-1">
                            <Button 
                              type="button"
                              color="primary"
                              onClick={()=>{viewBlackList(null, Number(filterValidation.values.curPageBl)+1);
                              }}
                              disabled={(filterValidation.values.curPageBl === totalPageBl)}
                            >
                              {">"}
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              onClick={()=>{viewBlackList(null, totalPageBl);}}
                              disabled={(filterValidation.values.curPageBl === totalPageBl)}
                            >
                              {">>"}
                            </Button>
                          </div>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :null}

            </TabPane>
          </TabContent>

            {
              loading ?
              <LoadingModal loading={loading} setLoading={setAdmDomainLoading}></LoadingModal>
              : null
            }

            {
              success ? 
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(setAdmDomainManagementSuccess(""));
                }}
              ></SweetAlert>
              : null
            }

            {
              error ?
              <SweetAlert
                warning
                title={error}
                onConfirm={() => {
                  dispatch(setAdmDomainManagementError(""));
                }}
              >
              </SweetAlert>
              : null
            }

            <AdmDomainActionHistory />
            <EntityDetail targetGroupNo={detailTargetGroupNo} isOpenEntityDetail={isOpenEntityDetail} togEntityDetail={togEntityDetail}/>
           
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default AdmDomainManagement;