import {
  GET_MULTIPLE_JURISDICTIONS_LIST,
  GET_MULTIPLE_JURISDICTIONS_LIST_SUCCESS,
  CHANGE_MULTIPLE_JURISDICTIONS_LIST,
} from "./actionTypes"

export const getMultipleJurisdictionsList = (inOutType, screenGrade, filterName, subscriptionType) => ({
    type: GET_MULTIPLE_JURISDICTIONS_LIST,
    payload: {inOutType, screenGrade, filterName, subscriptionType},
})

export const getMultipleJurisdictionsListSuccess = (data, filterName) => {
  return {
    type: GET_MULTIPLE_JURISDICTIONS_LIST_SUCCESS,
    payload: {data, filterName},
  }
}

export const changeMultipleJurisdictionsList = (multipleJurisdictionList, filterName) => {
  return {
    type: CHANGE_MULTIPLE_JURISDICTIONS_LIST,
    payload: {multipleJurisdictionList, filterName},
  }
}