import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Label,
    Button,
    CardHeader,
    Collapse,
    FormFeedback,
    Input,
    UncontrolledTooltip,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    Media,
  } from "reactstrap"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SimpleBar from "simplebar-react"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal";
import {
  setSuccess as onSetSuccess,
  setContacsDetailResult as onSetContacsDetailResult,
  getContactsDetails as onGetContactsDetails,
  changeContactsDetails as onChangeContactsDetails,
  getImportedContacts as onGetImportedContacts,
  getSuggestedContacts as onGetSuggestedContacts,
  getContactsMemo as onGetContactsMemo,
  saveMemo as onSaveMemo,
  changeImportedContacts as onChangeImportedContacts,
  changeSuggestedContacts as onChangeSuggestedContacts,
  changeMemberConfirmedContacts as onChangeMemberConfirmedContacts,
  multipleStarContacts as onMultipleStarContacts,
  multipleUnstarContacts as onMultipleUnstarContacts,
  multipleHideContacts as onMultipleHideContacts,
  multipleUnhideContacts as onMultipleUnhideContacts,
  multipleUnsubscribeContacts as onMultipleUnsubscribeContacts,
  checkEmailUnsubscribe as onCheckEmailUnsubscribe,
  saveContacts as onSaveContacts,
  saveContactsSimple as onSaveContactsSimple,
  deleteContacts as onDeleteContacts,
  // getDomainCorrectYnCount as onGetDomainCorrectYnCount,
  saveDomainCorrectYn as onSaveDomainCorrectYn,
  changeUserSuggestDomain as onChangeUserSuggestDomain,
  changeShowSuccessAddToastFlag as onChangeShowSuccessAddToastFlag,
  changeCheckUnsubsEmailAddressFlag as onChangeCheckUnsubsEmailAddressFlag,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import classnames from "classnames"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const ViewDetail = props => {
  // console.log("props : "+JSON.stringify(props));
  const dispatch = useDispatch();
  
  const { contactsDetails, importedContacts, importedTotalPage, suggestedContacts, suggestedTotalPage, suggestedContactsExist, memo, loading, success, detailResult, domainCorrectYnCount, userSuggestDomain, showSuccessAddToastFlag, unsubsEmailAddress, checkUnsubsEmailAddressFlag, toastMessage, saveType, screenName, memberConfirmedContacts} = useSelector(state => ({
    contactsDetails: state.Contacts.contactsDetails,
    importedContacts: state.Contacts.importedContacts,
    importedTotalPage: state.Contacts.importedTotalPage,
    suggestedContacts: state.Contacts.suggestedContacts,
    suggestedTotalPage: state.Contacts.suggestedTotalPage,
    suggestedContactsExist: state.Contacts.suggestedTotalPage,
    memo: state.Contacts.memo,
    loading: state.Contacts.loading,
    success: state.FilerRankings.success,
    detailResult: state.Contacts.detailResult,
    domainCorrectYnCount: state.Contacts.domainCorrectYnCount,
    userSuggestDomain: state.Contacts.userSuggestDomain,
    showSuccessAddToastFlag: state.Contacts.showSuccessAddToastFlag,
    unsubsEmailAddress: state.Contacts.unsubsEmailAddress,
    checkUnsubsEmailAddressFlag: state.Contacts.checkUnsubsEmailAddressFlag,
    toastMessage: state.Contacts.toastMessage,
    saveType: state.Contacts.saveType,
    screenName: state.Contacts.screenName,
    memberConfirmedContacts: state.Contacts.memberConfirmedContacts,
  }));

  const targetGroupNo = props.targetGroupNo != undefined ? props.targetGroupNo : props.match.params.targetGroupNo;

  const [importedContactsOpen, setImportedContactsOpen] = useState(true);
  const importedToggle = () => setImportedContactsOpen(!importedContactsOpen);
  const [suggestedContactsOpen, setSuggestedContactsOpen] = useState(true);
  const suggestedToggle = () => setSuggestedContactsOpen(!suggestedContactsOpen);

  const [confirmMsg, setConfirmMsg] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);

  const [chkAll, setChkAll] = useState(false);
  const [chkAll2, setChkAll2] = useState(false);
  const [chkAllMemberConfirmedContacts, setChkAllMemberConfirmedContacts] = useState(false);
  const [importedChkAll, setImportedChkAll] = useState(false);

  const [domainSuggestAlert, setDomainSuggestAlert] = useState(false);
  const [requireDomainAlert, setRequireDomainAlert] = useState(false);
  
  const [keyword, setKeyword] = useState("");
  const [curPageUp, setCurPageUp] = useState(1);
  const [curPageInput, setCurPageInput] = useState(1);

  const [suggestedKeyword, setSuggestedKeyword] = useState("");
  const [suggestedCurPage, setSuggestedCurPage] = useState(1);
  const [suggestedCurPageInput, setSuggestedCurPageInput] = useState(1);
  const [sendableEmailFlag, setSendableEmailFlag] = useState(true);
  const [sendableHagueEmailFlag, setSendableHagueEmailFlag] = useState(true);

  /**
   * errMsg[0] : .csv 파일이 아님
   * errMsg[1] : 선택된 entity 없음.
   * errMsg[2] : 선택된 contact 없음.
   * errMsg[3] : save되지 않은 contact 선택함.
   * errMsg[4] : 필수항목 미입력
   * errMsg[5] : email 형식 잘못됨
   * errMsg[6] : 필수항목 미입력
   */
  const errMsg = [
      "not csv file"
    , "Please select a entity."
    , "Please select a contact."
    , "There are unsaved rows."
    , "Email address is required."
    , <>Invalid email format: Please check if you accidentally included any special characters or blank spaces.
      <br/><br/>Please refer to the following website to check for any hidden special characters or blank spaces: <a href='https://www.regextester.com/99810' target='_blank'>https://www.regextester.com/99810</a>
      <br/>*Please note that we do not have any affiliation with this website, but we found it helpful.</>
    , "First name is required."
    , "Please select email address!"
    , "This domain is verified!"
    , "This email address already exists for this entity!"
    , "The following email addresses have already been unsubscribed:"
  ];

  const [dupeEmailAddress, setDupeEmailAddress] = useState([]);
  const [addSimpleAdressList, setAddSimpleAdressList] = useState([]);

  const [activeTab, setactiveTab] = useState("Contacts")
  function toggleActiveTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  useEffect(() => { 
    // dispatch(onGetImportedContacts({perPage: 10, curPage: curPageUp}));
    // dispatch(onGetSuggestedContacts({targetGroupNo : targetGroupNo}));
    dispatch(onGetContactsDetails({targetGroupNo : targetGroupNo}));
    // dispatch(onGetContactsMemo({targetGroupNo : targetGroupNo}));
    // dispatch(onGetDomainCorrectYnCount({targetGroupNo : targetGroupNo}));
  }, [targetGroupNo]);

  useEffect(() => { 
    if (showSuccessAddToastFlag) {
      showSuccessAddToast();
      dispatch(onChangeShowSuccessAddToastFlag(false));
    }
  }, [showSuccessAddToastFlag]);

  useEffect(() => { 
    if (contactsDetails && Object.keys(contactsDetails).length !== 0) {
      let dontSendEmailCnt = 0;
      let dontSendHagueEmailCnt = 0;
      contactsDetails.contactsList.map((contact, contactIdx) => {
        if (contact.unsubscribe === "Y" || contact.sendYn === "N") {
          dontSendEmailCnt++;
        }
        if(contact.unsubscribe === "Y" || contact.hagueSendYn === "N") {
          dontSendHagueEmailCnt++;
        }
      })

      if (dontSendEmailCnt == contactsDetails.contactsList.length) {
        setSendableEmailFlag(false);
      } else {
        setSendableEmailFlag(true);
      }

      if (dontSendHagueEmailCnt == contactsDetails.contactsList.length) {
        setSendableHagueEmailFlag(false);
      } else {
        setSendableHagueEmailFlag(true);
      }
    }
  }, [contactsDetails]);

  useEffect(() => { 
    if (checkUnsubsEmailAddressFlag && screenName == 'Detail') {
      dispatch(onChangeCheckUnsubsEmailAddressFlag(false));
      if (saveType == 'INPUT') {
        validation.setFieldValue("submitType", "Save");
        setConfirmMsg("Are you sure you want to save the selected email(s)?");
        setConfirmAlert(true);
      } else {
        dispatch(onSaveContactsSimple(addSimpleAdressList));
        // setAddSimpleAdressList([]);
      }
    }
  }, [checkUnsubsEmailAddressFlag]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "",
      textarea: memo && memo.memo? memo.memo : "",
    },
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      // console.log("targetGroupNo : "+targetGroupNo);
      let submitType = values["submitType"];

      if(submitType == "memo"){
        const param = {
          targetGroupNo: targetGroupNo,
          memo: values["textarea"],
        };
        dispatch(onSaveMemo(param));
      }else if(submitType == "Star"){
        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.starred = true;

        dispatch(onMultipleStarContacts([_contactsDetails], null));
      }else if(submitType == "Unstar"){
        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.starred = false;

        dispatch(onMultipleUnstarContacts([_contactsDetails], null));
      }else if(submitType == "Hide"){
        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.hidden = true;

        dispatch(onMultipleHideContacts([_contactsDetails], null));
      }else if(submitType == "Unhide"){
        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.hidden = false;

        dispatch(onMultipleUnhideContacts([_contactsDetails], null));
      }else if(submitType.indexOf("Unsubscribed_") === 0
                || submitType.indexOf("Confirmed_") === 0){
        let emailSeq = submitType.substring(submitType.lastIndexOf("_")+1, submitType.length);
        let value = submitType.indexOf("Unsubscribed_") === 0? "Y" : "N"
        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.contactsList = _contactsDetails.contactsList.map((contact, idx) => {
          if(contact.emailSeq == emailSeq){
            contact.unsubscribe = value;
          }

          return contact;
        });

        dispatch(onMultipleUnsubscribeContacts(_contactsDetails.contactsList, null));
      }else if(submitType == "Save" || submitType == "Delete"){
        const _contactsList = [];
        contactsDetails.contactsList.map((contact, contactIdx) => {
          if(contact.checked) _contactsList.push(contact);
        });

        if(submitType == "Save") dispatch(onSaveContacts(_contactsList, null));
        else if(submitType == "Delete") dispatch(onDeleteContacts(_contactsList, null));

      }else if(submitType == "Unsubscribed" || submitType == "Confirmed"){
        let value = submitType == "Unsubscribed"? "Y" : "N"
        let _contactsDetails = cloneDeep(contactsDetails);
        const _contactsList = [];
        _contactsDetails.contactsList.map((contact, contactIdx) => {
          if(contact.checked){
            contact.unsubscribe = value;
            _contactsList.push(contact);
          }
        });

        dispatch(onMultipleUnsubscribeContacts(_contactsList, null));
      }
    },
  });

  const contactCheck = (contactChkIdx, value) => {
    let _contactsDetails = cloneDeep(contactsDetails);
    let contactChkCnt = 0;

    _contactsDetails.contactsList = _contactsDetails.contactsList.map((contact, contactIdx) =>{
      if(contactChkIdx == "chkAll"){
        contact.checked = value;
      }else if(contactChkIdx == contactIdx){
        contact.checked = value;
      }
      if(contact.checked == true){
        contactChkCnt++;
      }
      return contact;
    })
    if(contactChkCnt === contactsDetails.contactsList.length){
      _contactsDetails.checked = true;
    }else{
      _contactsDetails.checked = false;
    }

    dispatch(onChangeContactsDetails(_contactsDetails));
  }
  
  const handleTypeSelect = (contactIdx, value) => {
    let _contactsDetails = cloneDeep(contactsDetails);
    _contactsDetails.contactsList[contactIdx].type = value;
    
    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const handleInput = (attr, contactIdx, value) => {
    let _contactsDetails = cloneDeep(contactsDetails);
    _contactsDetails.contactsList[contactIdx][attr] = value;
    
    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const addContacts = () => {
    let _contactsDetails = cloneDeep(contactsDetails);
    let newRow = {"targetGroupNo":targetGroupNo,"emailSeq":null,"emailAddress":null,"modFlag":"I","type":"To","firstName":null,"lastName":null,"title":null,"position":null,"unsubscribe":"N","checked":true,"sendYn":"Y","hagueSendYn":"Y"};
    _contactsDetails.contactsList.push(newRow);

    dispatch(onChangeContactsDetails(_contactsDetails));
  }

  const searchImportedContacts = (keywordValue) => {
    let chkCnt = 0;
    let showCnt = 0;
    
    keywordValue = keywordValue.toLowerCase();
    setKeyword(keywordValue);
    setCurPageUp(1);
    setChkAll(false);
    /*
    let _importedContacts = cloneDeep(importedContacts);
    _importedContacts = _importedContacts.map((contact, idx) =>{
        if(contact.emailAddress.toLowerCase().includes(keyword) == true 
          || (contact.firstName && contact.firstName.toLowerCase().includes(keyword) == true)
            || (contact.lastName && contact.lastName.toLowerCase().includes(keyword) == true)
              || (contact.title && contact.title.toLowerCase().includes(keyword) == true)
                || (contact.position && contact.position.toLowerCase().includes(keyword) == true)){
          contact.show = true;
        }else{
          contact.show = false;
        }

        if(contact.show){
          showCnt++;
          if(contact.checked) chkCnt++;
        } 

        return contact;
    }); 

    if(showCnt === chkCnt){
      setChkAll(true);
    }else{
      setChkAll(false);
    }
    */

    dispatch(onGetImportedContacts({keyword: keywordValue, perPage: 10, curPage: 1}));
  }

  const viewContactsListUp = (curPageUp) => {
    // console.log("viewContactsListUp 1 [curPageUp: "+curPageUp);

    //curPage 유효성 체크
    if(curPageUp){
      if(curPageUp > importedTotalPage) {
        curPageUp = importedTotalPage;
      } else if (curPageUp <= 0) {
        curPageUp = 1;
      }
    }

    setCurPageUp(curPageUp);
    setCurPageInput(curPageUp);
    setChkAll(false);

    // filterValidation.setValues(_values);
    // filterValidation.handleSubmit(filterValidation.values);

    dispatch(onGetImportedContacts({keyword: keyword, perPage: 10, curPage: curPageUp}));
  }

  const searchSuggestedContacts = (keywordValue) => {
    /*
    let chkCnt = 0;
    let showCnt = 0;

    keyword = keyword.toLowerCase();

    let _suggestedContacts = cloneDeep(suggestedContacts);
    _suggestedContacts = _suggestedContacts.map((contact, idx) =>{
        if(contact.emailAddress.toLowerCase().includes(keyword) == true){
          contact.show = true;
        }else{
          contact.show = false;
        }

        if(contact.show){
          showCnt++;
          if(contact.checked) chkCnt++;
        } 

        return contact;
    }); 

    if(showCnt === chkCnt){
      setChkAll2(true);
    }else{
      setChkAll2(false);
    }

    dispatch(onChangeSuggestedContacts(_suggestedContacts));
    */
    keywordValue = keywordValue.toLowerCase();
    setSuggestedKeyword(keywordValue);
    setSuggestedCurPage(1);
    setSuggestedCurPageInput(1);
    setChkAll2(false);

    dispatch(onGetSuggestedContacts({targetGroupNo : targetGroupNo, keyword: keywordValue, curPage: 1}));
  }

  const viewSuggestedContactsList = (curPage) => {
    // console.log("viewSuggestedContactsList [curPage: "+curPage);

    //curPage 유효성 체크
    if(curPage){
      if(curPage > suggestedTotalPage) {
        curPage = suggestedTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    setSuggestedCurPage(curPage);
    setSuggestedCurPageInput(curPage);
    setChkAll2(false);

    dispatch(onGetSuggestedContacts({targetGroupNo : targetGroupNo, keyword: suggestedKeyword, curPage: curPage}));
  }

  const importedContactsChk = (chkIdx, value) => {
    let chkCnt = 0;
    let showCnt = 0;
    let _importedContacts = cloneDeep(importedContacts);
    _importedContacts = _importedContacts.map((contact, idx) => {
      if(chkIdx == "chkAll") {
        contact.checked = value;
      }else if(chkIdx == idx){
        contact.checked = value;
      }

      // if(contact.show){
      showCnt++;
      if(contact.checked) chkCnt++;
      // } 

      return contact;
    });

    if(showCnt === chkCnt){
      setImportedChkAll(true);
    }else{
      setImportedChkAll(false);
    }
    dispatch(onChangeImportedContacts(_importedContacts));
  }

  const suggestedContactsChk = (chkIdx, value) => {
    let chkCnt = 0;
    let showCnt = 0;
    let _suggestedContacts = cloneDeep(suggestedContacts);
    _suggestedContacts = _suggestedContacts.map((contact, idx) => {
      if(chkIdx == "chkAll") {
        if(contact.show) contact.checked = value;
      }else if(chkIdx == idx) {
        contact.checked = value;
      }

      if(contact.show){
        showCnt++;
        if(contact.checked) chkCnt++;
      } 

      return contact;
    });

    if(showCnt === chkCnt){
      setChkAll2(true);
    }else{
      setChkAll2(false);
    }

    dispatch(onChangeSuggestedContacts(_suggestedContacts));
  }

  const memberConfirmedContactsChk = (chkIdx, value) => {
    let chkCnt = 0;
    let showCnt = 0;
    let _memberConfirmedContacts = cloneDeep(memberConfirmedContacts);
    _memberConfirmedContacts = _memberConfirmedContacts.map((contact, idx) => {
      if(chkIdx == "chkAll") {
        if(contact.show) contact.checked = value;
      }else if(chkIdx == idx) {
        contact.checked = value;
      }

      if(contact.show){
        showCnt++;
        if(contact.checked) chkCnt++;
      } 

      return contact;
    });

    if(showCnt === chkCnt){
      setChkAllMemberConfirmedContacts(true);
    }else{
      setChkAllMemberConfirmedContacts(false);
    }

    dispatch(onChangeMemberConfirmedContacts(_memberConfirmedContacts));
  }

  const addAdress = (type, suggestIdx) => {
    // let _contactsDetails = cloneDeep(contactsDetails);
    let addAdressList = [];

    if (type == 'imported') {
      if (importedContacts && importedContacts.length) {
        importedContacts.map((contact, idx) => {
          if (suggestIdx == -1) {
            if(contact.show && contact.checked){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          } else {
            if(idx == suggestIdx){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          }
        });
      }
    } else if (type == 'suggest') {
      
      if (suggestedContacts && suggestedContacts.length) {
        suggestedContacts.map((contact, idx) => {
          if (suggestIdx == -1) {
            if(contact.show && contact.checked){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          } else {
            if(idx == suggestIdx){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          }
        });
      }
    } else {
      if (memberConfirmedContacts && memberConfirmedContacts.length) {
        memberConfirmedContacts.map((contact, idx) => {
          if (suggestIdx == -1) {
            if(contact.show && contact.checked){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          } else {
            if(idx == suggestIdx){
              let _contact = cloneDeep(contact);
              _contact.modFlag = "I";
              _contact.sendYn = "Y";
              _contact.hagueSendYn = "Y";
              _contact.targetGroupNo = targetGroupNo;
              addAdressList.push(_contact);
            }
          }
        });
      }
    }

    if (addAdressList.length === 0) {
      dispatch(onSetContacsDetailResult(7));
      return false;
    }

    let checkDupeEmailAddress = [];
    contactsDetails.contactsList.map((contact, contactIdx) => {
      addAdressList.map((contact2, contactIdx2) => {
        if(contact.emailAddress && contact.emailAddress != '' && contact.emailAddress.toLowerCase() == contact2.emailAddress.toLowerCase() && !checkDupeEmailAddress.includes(contact.emailAddress)) {
          checkDupeEmailAddress.push(contact.emailAddress)
        }
      })
    })

    if (checkDupeEmailAddress.length > 0) {
      setDupeEmailAddress(checkDupeEmailAddress);
      dispatch(onSetContacsDetailResult(9));
    } else {
      // dispatch(onSaveContactsSimple(addAdressList));
      setAddSimpleAdressList(addAdressList);
      dispatch(onCheckEmailUnsubscribe(addAdressList, 'SIMPLE', 'Detail'));
    }
    
    /*
    let oriEmailAdressList = [];
    contactsDetails.contactsList.map((contact, contactIdx) => {

      if(!(contact.modFlag == "I" && (!contact.emailAddress || contact.emailAddress == null || contact.emailAddress == ""))) {
        oriEmailAdressList.push(contact);
      }
    })

    _contactsDetails.contactsList  = oriEmailAdressList.concat(addAdressList); // contactsDetails.contactsList.concat(addAdressList);

    dispatch(onChangeContactsDetails(_contactsDetails));
    showSuccessAddToast();
    */
  }

  const contactChk = (type) => {
    let chkCnt = 0;
    let notSaveCnt = 0;

    // let notDelEmailAdressList = [];
    let _newContactsList = [];
    contactsDetails.contactsList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
      }

      if (type == 'Unsubscribed') {
        if(contact.checked === true) {
          // chkCnt++;
          if (contact.modFlag == "I") {
            notSaveCnt++;
          }
        }
      } else {
        // console.log('checked : ' + contact.checked + ', modFlag : ' + contact.modFlag);
        if(!(contact.checked && contact.modFlag == "I")) {
          _newContactsList.push(contact);
        }
      }
    })

    if(chkCnt === 0){
      dispatch(onSetContacsDetailResult(2));
      return false;
    } 

    if (type == 'Unsubscribed') {
      if (notSaveCnt > 0){
        dispatch(onSetContacsDetailResult(3));
        return false;
      }
    } else {
      if (_newContactsList.length == 0) {
        let _contactsDetails = cloneDeep(contactsDetails);
        let newRow = [{"targetGroupNo":targetGroupNo,"emailSeq":null,"emailAddress":null,"modFlag":"I","type":"To","firstName":null,"lastName":null,"title":null,"position":null,"unsubscribe":"N","checked":true,"sendYn":"Y","hagueSendYn":"Y"}];
        _contactsDetails.contactsList = newRow;

        dispatch(onChangeContactsDetails(_contactsDetails));

        return false;
      } else {
        chkCnt = 0;
        notSaveCnt = 0;

        _newContactsList.map((contact, contactIdx) => {
          if(contact.checked === true) {
            chkCnt++;
          } 
          
          if (contact.modFlag == "I") {
            notSaveCnt++;
          }
        })

        let _contactsDetails = cloneDeep(contactsDetails);
        _contactsDetails.contactsList = _newContactsList;
        dispatch(onChangeContactsDetails(_contactsDetails));

        if(chkCnt === 0){
          return false;
        } else if(notSaveCnt > 0){
          dispatch(onSetContacsDetailResult(3));
          return false;
        }
      }
    }

/*
    if(type == "Delete" && notSaveCnt > 0){
      let _contactsDetails = cloneDeep(contactsDetails);
      _contactsDetails.contactsList  = notDelEmailAdressList;

      dispatch(onChangeContactsDetails(_contactsDetails));

      if (chkCnt == notSaveCnt) {
        return false;
      }
    }
*/    

    return true;
  }

  const saveValidation = () => {
    let chkCnt = 0;
    let noEmail = false;
    // let noFirstName = false;
    let invalidEmail = false;
    let regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i;

    let checkUnsubsEmail = [];
    let checkDupeEmailAddress = [];
    
    contactsDetails.contactsList.map((contact, contactIdx) => {
      if(contact.checked === true) {
        chkCnt++;
        if(!contact.emailAddress){
          noEmail = true;
        } else {
          invalidEmail = !regex.test(contact.emailAddress);
        }

        // if (contact.modFlag === "I") {
          checkUnsubsEmail.push(contact);
        // }

        // if(!contact.firstName){
        //   noFirstName = true;
        // }

        contactsDetails.contactsList.map((contact2, contactIdx2) => {
          if(contactIdx != contactIdx2 && contact.emailAddress && contact.emailAddress != '' && contact.emailAddress.toLowerCase() == contact2.emailAddress.toLowerCase() && !checkDupeEmailAddress.includes(contact.emailAddress)) {
            checkDupeEmailAddress.push(contact.emailAddress)
          }
        })
      }
    })

    if (checkDupeEmailAddress.length > 0) {
      setDupeEmailAddress(checkDupeEmailAddress);
      dispatch(onSetContacsDetailResult(9));
      return false;
    }

    if(chkCnt === 0){
      dispatch(onSetContacsDetailResult(2));
      return false;
    }else if(noEmail){
      dispatch(onSetContacsDetailResult(4));
      return false;
    }else if(invalidEmail){
      dispatch(onSetContacsDetailResult(5));
      return false;
    }else{
      // console.log('checkUnsubsEmail.length : ' + checkUnsubsEmail.length)
      if (checkUnsubsEmail.length > 0) {
        // console.log('checkUnsubsEmail : ' + JSON.stringify(checkUnsubsEmail))
        dispatch(onCheckEmailUnsubscribe(checkUnsubsEmail, 'INPUT', 'Detail'));
        return false;
      } else {
        return true;
      }
    }
  }

  function showSuccessAddToast() {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    // let message = "Add contacts!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 1000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(toastMessage, title)
  }

  return (
    <React.Fragment>
      <div className={props.targetGroupNo != undefined? null : "page-content" }>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Contacts details</h4>
                { props.targetGroupNo != undefined? null :
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                      <Link to="/contacts">List</Link>
                      &nbsp;<span> &gt; Detail</span>
                  </ol>
                </div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                  { contactsDetails && Object.keys(contactsDetails).length !== 0 ?
                    <Col lg="5" xl="4">
                      <div className="d-flex gap-2">
                        <Link to="#" className={contactsDetails.starred? "text-warning": "text-muted"}
                          onClick={(e)=> {
                            validation.setFieldValue("submitType", contactsDetails.starred? "Unstar" : "Star")
                            validation.handleSubmit(validation.values);
                          }}>
                          <i className="uil uil-star font-size-18" id="Starred"></i>
                          <UncontrolledTooltip placement="top" target="Starred" className="domain-correct-tooltip">
                            {contactsDetails.starred? "Remove from starred": "Star"}
                          </UncontrolledTooltip>
                        </Link>
                        <Link to="#" className={contactsDetails.hidden? "text-danger": "text-muted"}
                              onClick={(e)=> {
                                validation.setFieldValue("submitType", contactsDetails.hidden? "Unhide" : "Hide")
                                validation.handleSubmit(validation.values);
                              }}>
                          <i className="uil uil-eye-slash font-size-18" id="Hidden"></i>
                          <UncontrolledTooltip placement="top" target="Hidden" className="domain-correct-tooltip">
                            {validation.hidden? "Unhide": "Hide"}
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                      <br/>
                      
                      Entity name: <h5>{contactsDetails.targetGroupName}</h5>
                      Entity domain: <h5>{contactsDetails.domainCorrectYn && contactsDetails.domainCorrectYn === "Y" ? <><i className="bx bx-badge-check font-size-18 text-success"></i> </>: null} 
                      {contactsDetails.domain && contactsDetails.domain !== "NOT_SAME" && contactsDetails.domain !== "NOT_MAX" ? 
                        <a href='#' onClick={() => {
                          window.open('http://' + contactsDetails.domain, "_blank");
                        }}>{contactsDetails.domain}</a> 
                        : "N/A"}</h5>
                      Is this domain correct?&nbsp;
                      <Link to="#" >
                        <i className="uil uil-question-circle font-size-18 text-success" id="domain_correct_info"></i>
                        <UncontrolledTooltip placement="top" target="domain_correct_info" className="domain-correct-tooltip">
                        WeCrest's AI-driven algorithm believes that this is the correct email/web domain for this entity. Please help us improve the accuracy of our data by letting us know whether you believe this domain is correct or incorrect. If you believe it is incorrect, you can also suggest a correction.
                        </UncontrolledTooltip>
                      </Link>
                      
                      <div>
                      <button type="button" class={userSuggestDomain.correctYn === 'Y' ? "waves-effect waves-light btn btn-success" : "waves-effect waves-light btn btn-outline-success"}
                        onClick={() => {
                          if (userSuggestDomain.correctYn !== 'Y') {
                            dispatch(onSaveDomainCorrectYn({targetGroupNo : targetGroupNo, correctYn : 'Y'}));
                          }
                        }}>
                        <i className="uil uil-check font-size-14"></i> Correct {domainCorrectYnCount && domainCorrectYnCount.correctY ? domainCorrectYnCount.correctY : 0}
                      </button>
                      &nbsp;&nbsp;
                      <button type="button" class={userSuggestDomain.correctYn === 'N' ? "waves-effect waves-light btn btn-danger" : "waves-effect waves-light btn btn-outline-danger"}
                        onClick={() => {
                          if (userSuggestDomain.correctYn !== 'N') {
                            setDomainSuggestAlert(true);
                          }
                        }}>
                        <i className="uil uil-times font-size-14"></i> Incorrect {domainCorrectYnCount && domainCorrectYnCount.correctN ? domainCorrectYnCount.correctN : 0}
                      </button>
                      </div>
                      
                      Last updated: <h5>{contactsDetails.lastTargetEmailModDate ? contactsDetails.lastTargetEmailModDate : "N/A"}</h5>
                    </Col>
                     : null}
                    <Col lg="7" xl="8">
                      <h4 className="card-title mb-3">Memo</h4>
                      <SimpleBar style={{ maxHeight: "200px" }}>
                      <div className={validation.touched.textarea && validation.errors.textarea ? null:"mb-3"}>
                        <Input type="textarea" name="textarea"
                          placeholder="Write your memo here"
                          rows="3"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.textarea || ""}
                          invalid={
                            validation.touched.textarea && validation.errors.textarea ? true : false
                          }
                        />
                        {validation.touched.textarea && validation.errors.textarea ? (
                          <FormFeedback type="invalid">{validation.errors.textarea}</FormFeedback>
                        ) : null}
                      </div>
                      <div class="float-md-end">
                        <button type="submit" className="btn btn-primary waves-effect waves-light"
                                onClick={(e) => {
                                  validation.setFieldValue("submitType", "memo");
                                  validation.handleSubmit(validation.values);
                                }}>save</button>
                      </div>
                      </SimpleBar>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            loading ?
            <LoadingModal loading={loading}></LoadingModal>
            : null
          }
          {success? (
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(onSetSuccess(""));
              }}
            ></SweetAlert>
          ) : null}
          {detailResult !== -1 ? (
            <SweetAlert
              onConfirm={() => {
                dispatch(onSetContacsDetailResult(-1));
                setDupeEmailAddress([]);
              }}
            >{detailResult < 9 ? errMsg[detailResult] : <>{errMsg[detailResult]}<br/>{detailResult == 9 ? "["+ dupeEmailAddress + "]" : unsubsEmailAddress}</>}
            </SweetAlert>
            ) : null}
          {confirmAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="No, go back"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                validation.handleSubmit(validation.values);
                setConfirmAlert(false);
              }}
              onCancel={() => setConfirmAlert(false)}
            >
              {confirmMsg}
            </SweetAlert>
            ) : null}
          {domainSuggestAlert ? (
            <SweetAlert
              title="Do you know the correct domain?"
              showCancel
              confirmBtnText="Confirm"
              cancelBtnText="No, go back"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                if (userSuggestDomain.knowCorrectDomainYn === 'Y' && (userSuggestDomain.suggestDomain == null || userSuggestDomain.suggestDomain == '')) {
                  setRequireDomainAlert(true);
                } else {
                  dispatch(onSaveDomainCorrectYn(
                    {
                      ...userSuggestDomain,
                      correctYn : 'N',
                      targetGroupName: contactsDetails.targetGroupName,
                    }
                  ));
                  setDomainSuggestAlert(false);
                  setRequireDomainAlert(false);
                }
                
              }}
              onCancel={() => setDomainSuggestAlert(false)}
            >
              <Row className="mb-1">
                <div className="col-md-2 form-check" style={{paddingLeft : '2em', paddingTop: "5px"}}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="emailSent"
                    id="emailSentN"
                    onChange={() => {
                      dispatch(onChangeUserSuggestDomain(
                        {
                          ...userSuggestDomain,
                          knowCorrectDomainYn: 'Y',
                        }
                      ));
                    }}
                    value="Y"
                    checked={userSuggestDomain.knowCorrectDomainYn === 'Y'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="emailSentN"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-2 form-check" style={{paddingLeft : '2em', paddingTop: "5px"}}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="emailSent"
                    id="emailSentY"
                    onChange={() => {
                      dispatch(onChangeUserSuggestDomain(
                        {
                          ...userSuggestDomain,
                          knowCorrectDomainYn: 'N',
                          suggestDomain: '',
                        }
                      ));
                      setRequireDomainAlert(false);
                    }}
                    value="N"
                    checked={userSuggestDomain.knowCorrectDomainYn === 'N'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="emailSentY"
                  >
                    No
                  </label>
                </div>
                <div className="col-md-8">
                <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter correct domain"
                    onChange={(e) => {
                      dispatch(onChangeUserSuggestDomain(
                        {
                          ...userSuggestDomain,
                          suggestDomain: e.target.value,
                        }
                      ));
                    }}
                    value={userSuggestDomain.suggestDomain}
                    disabled={userSuggestDomain.knowCorrectDomainYn === 'N' ? true : false}
                />
                </div>
              </Row>
              {requireDomainAlert ?
              <div class="mt-2 text-danger row display-flex horizon-align-center">Please enter your suggestion.</div>
              : null
              }
            </SweetAlert>
            ) : null}

            <Nav pills className="col-md-6 nav-justified bg-light">
              <NavItem className="waves-effect waves-light">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "Contacts",
                  })}
                  onClick={() => {
                    toggleActiveTab("Contacts")
                  }}
                >
                  <span>Contacts</span>
                </NavLink>
              </NavItem>
              {memberConfirmedContacts.length > 0?
              <NavItem className="waves-effect waves-light">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "MemberConfirmedContacts",
                  })}
                  onClick={() => {
                    toggleActiveTab("MemberConfirmedContacts")
                  }}
                >
                  <span>Member Confirmed contacts</span>
                </NavLink>
              </NavItem>
              : null}
              {memberConfirmedContacts.length === 0 && suggestedContactsExist?
              <NavItem className="waves-effect waves-light">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "WeCrestSuggest",
                  })}
                  onClick={() => {
                    toggleActiveTab("WeCrestSuggest")
                  }}
                >
                  <span>WeCrest suggested contacts</span>
                </NavLink>
              </NavItem>
              : null}
              <NavItem className="waves-effect waves-light">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "ImportedEmail",
                  })}
                  onClick={() => {
                    toggleActiveTab("ImportedEmail")
                  }}
                >
                  <span>Imported email addresses</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="text-muted"
            >
              <TabPane tabId="Contacts">
                <Row>
                  <Col lg="12">
                    <Card>
                      <div className="p-4">
                        <Media className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className={sendableEmailFlag && sendableHagueEmailFlag ? "text-muted mb-0" : "text-danger mb-0"}>In order to send an email from {sendableHagueEmailFlag ? null : "Hague Notifier"} {sendableEmailFlag ? null: "Madrid Notifier, and Filer Rankings,"} at least one contact information must be saved with sending enabled.</p>
                          </div>
                        </Media>
                      </div>
                      <CardBody>
                        <CardTitle className="text-muted mb-0">
                          <Row className="mb-3">
                            <span className="button-items">
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  if(saveValidation()){
                                    validation.setFieldValue("submitType", "Save");
                                    setConfirmMsg("Are you sure you want to save the selected email(s)?");
                                    setConfirmAlert(true);
                                  }
                                }}
                              >Save contact
                              </Button>
                              {/*
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  if(contactChk()){
                                    validation.setFieldValue("submitType", "Confirmed");
                                    setConfirmMsg("Are you sure you want to confirm the selected email(s)?");
                                    setConfirmAlert(true);
                                  }
                                }}
                              >Confirmed contact
                              </Button>
                              */}
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  if(contactChk("Unsubscribed")){
                                    validation.setFieldValue("submitType", "Unsubscribed");
                                    setConfirmMsg("Are you sure you want to unsubscribe the selected email(s)?");
                                    setConfirmAlert(true);
                                  }
                                }}
                              >Unsubscribe contact
                              </Button>
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  if(contactChk("Delete")){
                                    validation.setFieldValue("submitType", "Delete");
                                    setConfirmMsg("Are you sure you want to delete the selected email(s)?");
                                    setConfirmAlert(true);
                                  }
                                }}
                              >Delete contact
                              </Button>
                            </span>
                          </Row>
                        </CardTitle>
                        <div className="table-responsive">
                        { contactsDetails && Object.keys(contactsDetails).length !== 0 ?
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={contactsDetails.checked} onClick={(e)=>contactCheck("chkAll", e.target.checked)} /></th>
                              <th>Email address*</th>
                              <th>First name</th>
                              <th>Last name</th>
                              <th>Title</th>
                              <th>Position</th>
                              <th>Send via Madrid Notifier?</th>
                              <th>Send via Hague Notifier?</th>
                              <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {contactsDetails.contactsList.map((contact, contactIdx) => {
                                return (
                                        <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                          <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                            <input type="checkbox" 
                                              checked={contact.checked}
                                              onClick={(e)=> contactCheck(contactIdx, e.target.checked)}
                                              />
                                          </td>
                                          {/*
                                          <td>
                                            
                                          <select className="form-select"
                                              value={contact.type}
                                              onChange={(e) => handleTypeSelect(contactIdx, e.target.value)}
                                            >
                                              <option value="To">To</option>
                                              <option value="cc">cc</option>
                                            </select>
                                          </td>
                                          */}
                                          <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("emailAddress", contactIdx, e.target.value)}} value={contact.emailAddress ? contact.emailAddress : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                          <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("firstName", contactIdx, e.target.value)}} value={contact.firstName ? contact.firstName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                          <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("lastName", contactIdx, e.target.value)}} value={contact.lastName ? contact.lastName : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                          <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("title", contactIdx, e.target.value)}} value={contact.title ? contact.title : "" } disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                          <td><input type="text" className="form-control" onChange={(e) => { contact.unsubscribe === "N" && handleInput("position", contactIdx, e.target.value)}} value={contact.position ? contact.position : ""} disabled={contact.unsubscribe === "Y" ? true : false}/></td>
                                          <td style={{verticalAlign: "middle"}}>
                                            <div className="d-flex horizon-align-center">
                                            {contact.unsubscribe === "Y" ? 
                                            <>
                                            <i className="uil uil-envelope-block font-size-22 text-danger" id={"Unsubscribed_"+contactIdx}></i>
                                            <UncontrolledTooltip placement="top" target={"Unsubscribed_"+contactIdx} className="domain-correct-tooltip">
                                              Unsubscribed
                                            </UncontrolledTooltip>
                                            </>
                                            :
                                            <div className="form-check form-switch" style={{marginLeft: "0.5rem"}}>
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={contact.sendYn === "Y" ? true : false}
                                                onClick={e => {
                                                  handleInput("sendYn", contactIdx, (e.target.checked ? "Y" : "N"));
                                                }}
                                              />
                                            </div>
                                              /*
                                              <div className={contact.unsubscribe === "N"? "text-success": "text-primary"} style={contact.unsubscribe === "N"? null : {cursor : "not-allowed"}}>
                                                <i className="uil uil-envelope-check font-size-22" id={"Confirmed_"+contactIdx}></i>
                                                <UncontrolledTooltip placement="top" target={"Confirmed_"+contactIdx}>
                                                  Confirmed
                                                </UncontrolledTooltip>
                                              </div> 
                                              <Link to="#" className={contact.unsubscribe === "Y"? "text-danger": null}
                                                    onClick={(e)=> {
                                                      validation.setFieldValue("submitType", "Unsubscribed_"+contact.emailSeq);
                                                      validation.handleSubmit(validation.values);
                                                    }}>
                                                <i className="uil uil-envelope-block font-size-22" id={"Unsubscribed_"+contactIdx}></i>
                                                <UncontrolledTooltip placement="top" target={"Unsubscribed_"+contactIdx}>
                                                  Unsubscribed
                                                </UncontrolledTooltip>
                                              </Link> 
                                              </>:null 
                                              */
                                            }
                                            </div>
                                          </td>
                                          <td style={{verticalAlign: "middle"}}>
                                            <div className="d-flex horizon-align-center">
                                            {contact.unsubscribe === "Y" ? 
                                            <>
                                            <i className="uil uil-envelope-block font-size-22 text-danger" id={"Unsubscribed_"+contactIdx}></i>
                                            <UncontrolledTooltip placement="top" target={"Unsubscribed_"+contactIdx} className="domain-correct-tooltip">
                                              Unsubscribed
                                            </UncontrolledTooltip>
                                            </>
                                            :
                                            <div className="form-check form-switch" style={{marginLeft: "0.5rem"}}>
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={contact.hagueSendYn === "Y" ? true : false}
                                                onClick={e => {
                                                  handleInput("hagueSendYn", contactIdx, (e.target.checked ? "Y" : "N"));
                                                  // console.log("send via Hague Notifier");
                                                }}
                                              />
                                            </div>
                                            }
                                            </div>
                                          </td>
                                          <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                            {contactIdx+1 === contactsDetails.contactsList.length ?
                                              <Link to="#" onClick={(e) => addContacts()}>
                                                <i className="uil uil-plus-square font-size-22" id="addContacts"></i>
                                                <UncontrolledTooltip placement="top" target="addContacts" className="domain-correct-tooltip">
                                                  Add more contacts
                                                </UncontrolledTooltip>
                                              </Link>
                                            :null}
                                          </td>
                                        </tr>
                                      )
                                  })
                                }
                            </tbody>
                          </Table>
                          :null}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              {memberConfirmedContacts.length > 0?
              <TabPane tabId="MemberConfirmedContacts">
                <Row>
                  <Col lg="12">
                    <Card>
                      <div className="p-4">
                        <Media className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-muted mb-0">These are the contacts suggested by the representative firm.</p>
                          </div>
                        </Media>
                      </div>
                      <CardBody>
                        <CardTitle className="text-muted mb-0">
                          <Row className="mb-3">
                            <span className="button-items">
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  addAdress('memberConfirmedContacts', -1)
                                }}
                              >Add to contacts
                              </Button>
                            </span>
                          </Row>
                        </CardTitle>
                        
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAllMemberConfirmedContacts} onClick={(e)=>memberConfirmedContactsChk("chkAll", e.target.checked)} /></th>
                            <th>Email address</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Title</th>
                            <th>Position</th>
                            <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {memberConfirmedContacts && memberConfirmedContacts.length ?
                              memberConfirmedContacts.map((contact, idx) => {
                              return (
                                      <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <input type="checkbox" className="form-check-input"
                                            id={"memberConfirmedContacts_" + idx}
                                            checked={contact.checked}
                                            onClick={(e) => memberConfirmedContactsChk(idx, e.target.checked)}/>
                                        </td>
                                        <td>{contact.scrapeType == 'Manual' ? <><i className="uil uil-lightbulb-alt font-size-18 text-success"></i>&nbsp;</> : null}{contact.emailAddress}</td>
                                        <td>{contact.firstName}</td>
                                        <td>{contact.lastName}</td>
                                        <td>{contact.title}</td>
                                        <td>{contact.position}</td>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <Link to="#" onClick={(e) => addAdress('memberConfirmedContacts', idx)}>
                                            <i className="uil uil-user-plus font-size-22" id={"addMemberConfirmedContacts_" + idx}></i>
                                            <UncontrolledTooltip placement="top" target={"addMemberConfirmedContacts_" + idx} className="domain-correct-tooltip">
                                              Add to contacts
                                            </UncontrolledTooltip>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                              })
                              : null
                            }
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              : null}
              {memberConfirmedContacts.length === 0 && suggestedContactsExist?
              <TabPane tabId="WeCrestSuggest">
                <Row>
                  <Col lg="12">
                    <Card>
                      <div className="p-4">
                        <Media className="d-flex align-items-center">
                          {/*
                          <div className="flex-shrink-0 me-3">
                            <i className="uil uil-envelope-info text-primary h2"></i>
                          </div>
                          */}
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-muted mb-0">These emails have been curated by WeCrest. These are not email addresses entered by you or any other WeCrest members. We very strictly do not share with anyone any of the data that you enter on our platform, unless explicitly authorized.</p>
                          </div>
                        </Media>
                      </div>
                      <CardBody>
                        {/*
                        <CardTitle className="text-muted mb-0">
                          <Row className="mb-3">
                          WeCrest suggested email addresses <Link to="#" >
                            <i className="uil uil-question-circle font-size-18 text-success" id="wecrest_suggested_email_addresses"></i>
                            <UncontrolledTooltip placement="top" target="wecrest_suggested_email_addresses" className="domain-correct-tooltip">
                            These emails have been curated by WeCrest. These are not email addresses entered by you or any other WeCrest members. We very strictly do not share with anyone any of the data that you enter on our platform, unless explicitly authorized.
                            </UncontrolledTooltip>
                          </Link>
                          </Row>
                          <Row className="mb-3">
                            <span className="button-items">
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => addAdress('suggest', -1)}
                              >Add <i className="uil uil-arrow-down ms-2"></i>
                              </Button>
                            </span>
                          </Row>
                        </CardTitle>
                        */}
                        <CardTitle className="text-muted mb-0">
                          <Row className="mb-3">
                            <span className="button-items">
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  addAdress('suggest', -1)
                                }}
                              >Add to contacts
                              </Button>
                            </span>
                          </Row>
                        </CardTitle>
                        <Row>
                          <div className="form-inline float-md-end">
                            <div className="search-box ml-2">
                              <div className="position-relative">
                                <Input type="text" className="orm-control bg-light border-light rounded" placeholder="Search..."
                                        onKeyPress={(e) => {
                                          if(e.key === 'Enter'){
                                            searchSuggestedContacts(e.target.value);
                                          }                      
                                        }}/>
                                <i className="mdi mdi-magnify search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={chkAll2} onClick={(e)=>suggestedContactsChk("chkAll", e.target.checked)} /></th>
                            <th>Email address</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Title</th>
                            <th>Position</th>
                            <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {suggestedContacts && suggestedContacts.length ?
                              suggestedContacts.map((contact, idx) => {
                              return (
                                      <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <input type="checkbox" className="form-check-input"
                                            id={"suggestedContacts_" + idx}
                                            checked={contact.checked}
                                            onClick={(e) => suggestedContactsChk(idx, e.target.checked)}/>
                                        </td>
                                        <td>{contact.scrapeType == 'Manual' ? <><i className="uil uil-lightbulb-alt font-size-18 text-success"></i>&nbsp;</> : null}{contact.emailAddress}</td>
                                        <td>{contact.firstName}</td>
                                        <td>{contact.lastName}</td>
                                        <td>{contact.title}</td>
                                        <td>{contact.position}</td>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <Link to="#" onClick={(e) => addAdress('suggest', idx)}>
                                            <i className="uil uil-user-plus font-size-22" id={"addSuggestedAdress_" + idx}></i>
                                            <UncontrolledTooltip placement="top" target={"addSuggestedAdress_" + idx} className="domain-correct-tooltip">
                                              Add to contacts
                                            </UncontrolledTooltip>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                              })
                              : null
                            }
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-end">
                          <span className="d-flex align-items-center gap-2">
                            <div className="d-flex gap-1">
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewSuggestedContactsList(1);}}
                                disabled={(suggestedCurPage == 1)}
                              >
                                {"<<"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewSuggestedContactsList(suggestedCurPage-1);}}
                                disabled={(suggestedCurPage == 1)}
                              >
                                {"<"}
                              </Button>
                            </div>
                            Page{" "}
                            <strong>
                              {suggestedCurPage} of {suggestedTotalPage}
                            </strong>
                            
                            <Input
                              type="number"
                              min={1}
                              style={{ width: 70 }}
                              max={suggestedTotalPage}
                              name="curPage"
                              defaultValue={suggestedCurPage}
                              value={suggestedCurPageInput}
                              onChange={(e) => { setSuggestedCurPageInput(e.target.value)}}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  viewSuggestedContactsList(e.target.value);
                                }                      
                              }}
                            />
                            <div className="d-flex gap-1">
                              <Button 
                                type="button"
                                color="primary"
                                onClick={()=>{viewSuggestedContactsList(suggestedCurPage+1);}}
                                disabled={(suggestedCurPage == suggestedTotalPage)}
                              >
                                {">"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewSuggestedContactsList(suggestedTotalPage);}}
                                disabled={(suggestedCurPage == suggestedTotalPage)}
                              >
                                {">>"}
                              </Button>
                            </div>
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
               : null}
              <TabPane tabId="ImportedEmail">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="text-muted mb-0">
                          <Row className="mb-3">
                            <span className="button-items">
                              <Button type="button" color="primary" outline className="waves-effect waves-light"
                                onClick={() => {
                                  addAdress('imported', -1)
                                }}
                              >Add to contacts
                              </Button>
                            </span>
                          </Row>
                        </CardTitle>
                        <Row>
                          <div className="form-inline float-md-end">
                            <div className="search-box ml-2">
                              <div className="position-relative">
                                <Input type="text" className="orm-control bg-light border-light rounded" placeholder="Search..."
                                        onKeyPress={(e) => {
                                          if(e.key === 'Enter'){
                                            searchImportedContacts(e.target.value);
                                          }                      
                                        }}/>
                                <i className="mdi mdi-magnify search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </Row>
                        {importedContacts && importedContacts.length ?
                        <>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                            <th style={{verticalAlign: "middle", textAlign: "center"}}><input type="checkbox" checked={importedChkAll} onClick={(e)=>importedContactsChk("chkAll", e.target.checked)} /></th>
                            <th>Email address</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Title</th>
                            <th>Position</th>
                            <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {importedContacts.map((contact, idx) => {
                              return (
                                      <tr className={contact.modFlag === "I" ? "bg-soft-danger" : null}>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <input type="checkbox" className="form-check-input"
                                            id={"importedContacts_" + idx}
                                            checked={contact.checked}
                                            onClick={(e) => importedContactsChk(idx, e.target.checked)}/>
                                        </td>
                                        <td>{contact.emailAddress}</td>
                                        <td>{contact.firstName}</td>
                                        <td>{contact.lastName}</td>
                                        <td>{contact.title}</td>
                                        <td>{contact.position}</td>
                                        <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                          <Link to="#" onClick={(e) => addAdress('imported', idx)}>
                                            <i className="uil uil-user-plus font-size-22" id={"addImportedAdress_" + idx}></i>
                                            <UncontrolledTooltip placement="top" target={"addImportedAdress_" + idx} className="domain-correct-tooltip">
                                              Add to contacts
                                            </UncontrolledTooltip>
                                          </Link>   
                                        </td>
                                      </tr>
                                    )
                                })
                              }
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-end">
                          <span className="d-flex align-items-center gap-2">
                            <div className="d-flex gap-1">
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewContactsListUp(1);}}
                                disabled={(curPageUp == 1)}
                              >
                                {"<<"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewContactsListUp(curPageUp-1);}}
                                disabled={(curPageUp == 1)}
                              >
                                {"<"}
                              </Button>
                            </div>
                            Page{" "}
                            <strong>
                              {curPageUp} of {importedTotalPage}
                            </strong>
                            
                            <Input
                              type="number"
                              min={1}
                              style={{ width: 70 }}
                              max={importedTotalPage}
                              name="curPage"
                              defaultValue={curPageUp}
                              value={curPageInput}
                              onChange={(e) => { setCurPageInput(e.target.value)}}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  viewContactsListUp(e.target.value);
                                }                      
                              }}
                            />
                            <div className="d-flex gap-1">
                              <Button 
                                type="button"
                                color="primary"
                                onClick={()=>{viewContactsListUp(curPageUp+1);}}
                                disabled={(curPageUp == importedTotalPage)}
                              >
                                {">"}
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                onClick={()=>{viewContactsListUp(importedTotalPage);}}
                                disabled={(curPageUp == importedTotalPage)}
                              >
                                {">>"}
                              </Button>
                            </div>
                          </span>
                        </div>
                        </>
                        : null}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewDetail;