import {
    GET_HAGUE_INFO,
    GET_HAGUE_NOTIFIER_SETTINGS,
    GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
    UPDATE_HAGUE_NOTIFIER_SETTINGS,
    GET_HAGUE_INFO_SUCCESS,
    GET_HAGUE_JURISDICTIONS_SUCCESS,
    CHANGE_HAGUE_NOTIFIER,
    GET_HAGUE_COUNTRIES_SUCCESS,
    GET_HAGUE_HOLDER_LIST_SUCCESS,
    GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK,
    SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
    SAVE_HAGUE_NOTIFIER_SETTINGS,
    SET_HAGUE_SUCCESS,
    SET_HAGUE_LOADING,
    MULTIPLE_STAR_HAGUE,
    MULTIPLE_STAR_HAGUE_SUCCESS,
    MULTIPLE_UNSTAR_HAGUE,
    MULTIPLE_UNSTAR_HAGUE_SUCCESS,
    MULTIPLE_HIDE_HAGUE,
    MULTIPLE_HIDE_HAGUE_SUCCESS,
    MULTIPLE_UNHIDE_HAGUE,
    MULTIPLE_UNHIDE_HAGUE_SUCCESS,
    DOWN_HAGUE_EXCEL,
    DOWN_HAGUE_EXCEL_SUCCESS,
    DOWN_MULTIPLE_HAGUE_EXCEL,
    DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS,
    DOWN_HAGUE_PDF,
    DOWN_HAGUE_PDF_SUCCESS,
    DOWN_MULTIPLE_HAGUE_PDF,
    DOWN_MULTIPLE_HAGUE_PDF_SUCCESS,
    SEND_HAGUE_EMAIL,
    PREVIEW_HAGUE_EMAIL,
    PREVIEW_HAGUE_EMAIL_SUCCESS,
    SET_PREVIEW_HAGUE_EMAIL,
    SEND_HAGUE_EMAIL_SUCCESS,
    GET_HAGUE_NOTIFIER_INFO_LIST,
    GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS,
    SET_UPGRADE_ALERT_MESSAGE,
    SET_HAGUE_RESULT,
    CHANGE_SENT_HAGUE_RESULT,
    SET_HAGUE_SENT_SUCCESS,
} from "./actionTypes"

const initialState = {
    countries : [],
    hagueInfo:[],
    jurisdictions:[],
    subsJurisdictionCount : 0,
    hagueSearchSettings:{},
    freeMemberAutosearch: "N",
    totalPages:1,
    loading: false,
    userGrade: "00",
    hagueHolderList : [],
    success: "",
    noticeStr: "",
    previewEmail:{},
    previewPdfList: [],
    upgradeAlertMessage: {title:"",text:""},
    result : -1,
    sentSuccess :"",
    sentCnt: 0,
    unsubsEmails: [],
    disableEmails: [],
    sentIn24Emails: [],
}

const hagueNotifier = (state = initialState, action) =>{
    switch(action.type){
        case GET_HAGUE_NOTIFIER_SETTINGS:
            return{
                ...state,
                loading: true
            }
        case GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS:
            return{
                ...state,
                hagueSearchSettings: action.payload.hagueSearchSettings,
                freeMemberAutosearch: action.payload.freeMemberAutosearch,
                userGrade: action.payload.userGrade,
                loading: false
            }
        case UPDATE_HAGUE_NOTIFIER_SETTINGS:
            return{
                ...state,
                hagueSearchSettings: {
                    ...state.hagueSearchSettings,
                    ...action.payload, 
                }
            }   
        case GET_HAGUE_INFO:
            return{
                ...state,
                loading: true
            }
        case GET_HAGUE_INFO_SUCCESS:
            return{
                ...state,
                hagueInfo: action.payload.hagueInfo,
                totalPages: action.payload.totalPages,
                noticeStr: action.payload.noticeStr,
                loading: false,
                userGrade: action.payload.userGrade,
                freeMemberAutosearch: "N",
            }
        case GET_HAGUE_NOTIFIER_INFO_LIST:
            return{
                ...state,
                loading: false
            }
        case GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS:
            if(action.payload.hagueInfo.length > 0){
                let _newHagueInfo = []
                state.hagueInfo.map((hague,index)=>{
                    let isChanged = false;
                    action.payload.hagueInfo.map((newHague)=>{
                        if(hague.irNumber == newHague.irNumber && hague.ftpDate == newHague.ftpDate && hague.jurisdiction == newHague.jurisdiction){
                            isChanged = true;
                            _newHagueInfo.push(newHague)
                            
                        }
                    })
                    if(!isChanged){
                        _newHagueInfo.push(hague)
                    }
                })

                return{
                    ...state,
                    hagueInfo: _newHagueInfo,
                    loading: false
                }
            }
        case GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK:
            if (action.payload.hagueInfo.length > 0) {
                let _newHagueNotifier = [];
                state.hagueInfo.map((hague, index) => {
                  let isChange = false;
                  action.payload.hagueInfo.map((newHague, index2) => {
                    if (hague.repRankCode == newHague.repRankCode) {
                      isChange = true;
                      _newHagueNotifier.push(newHague);
                    }
                  });
        
                  if (!isChange) {
                    _newHagueNotifier.push(hague);
                  }
                });
        
                return {
                  ...state,
                  hagueInfo: _newHagueNotifier,
                  loading: false,
                }
              } else {
                return {
                  ...state,
                  loading: false,
                }
              }
        case CHANGE_HAGUE_NOTIFIER:
            return{
                ...state,
                hagueInfo: action.payload
            }
        case GET_HAGUE_JURISDICTIONS_SUCCESS:
            return{
                ...state,
                jurisdictions:action.payload.multipleJurisdictionList,
                subsJurisdictionCount: action.payload.subsJurisdictionCount
            }
        case GET_HAGUE_COUNTRIES_SUCCESS:
            return{
                ...state,
                countries: action.payload
            }
        case GET_HAGUE_HOLDER_LIST_SUCCESS:
            return{
                ...state,
                hagueHolderList: action.payload
            }
        case SAVE_HAGUE_NOTIFIER_SETTINGS:
            return{
                ...state,
                loading: true
            }
        case SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS:
            return{
                ...state,
                loading: false,
                success: action.payload.result,
                hagueSearchSettings: action.payload,
                freeMemberAutosearch: "N",
            }
        case SET_HAGUE_SUCCESS:
            return{
                ...state,
                success: action.payload
            }
        case SET_HAGUE_RESULT:
            return{
                ...state,
                loading: false,
                result: action.payload
            }
        case SET_HAGUE_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case MULTIPLE_STAR_HAGUE:
            return{
                ...state,
                // loading: true
            }
        case MULTIPLE_STAR_HAGUE_SUCCESS:
            return{
                ...state,
                // loading: false
            }
        case MULTIPLE_UNSTAR_HAGUE:
            return{
                ...state,
                // loading: true
            }
        case MULTIPLE_UNSTAR_HAGUE_SUCCESS:
            return{
                ...state,
                // loading: false
            }
        case MULTIPLE_HIDE_HAGUE:
            return{
                ...state,
                // loading: true
            }
        case MULTIPLE_HIDE_HAGUE_SUCCESS:
            return{
                ...state,
                // loading: false
            }
        case MULTIPLE_UNHIDE_HAGUE:
            return{
                ...state,
                // loading: true
            }
        case MULTIPLE_UNHIDE_HAGUE_SUCCESS:
            return{
                ...state,
                // loading: false
            }
        case DOWN_HAGUE_EXCEL:
            return{
                ...state,
                loading: true
            }
        case DOWN_HAGUE_EXCEL_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DOWN_MULTIPLE_HAGUE_EXCEL:
            return{
                ...state,
                loading: true
            }
        case DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DOWN_HAGUE_PDF:
            return{
                ...state,
                loading: true
            }
        case DOWN_HAGUE_PDF_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case DOWN_MULTIPLE_HAGUE_PDF:
            return{
                ...state,
                loading: true
            }
        case DOWN_MULTIPLE_HAGUE_PDF_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case SEND_HAGUE_EMAIL:
            return{
                ...state,
                loading: true
            }
        case SEND_HAGUE_EMAIL_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case PREVIEW_HAGUE_EMAIL:
            return{
                ...state,
                loading: true
            }
        case PREVIEW_HAGUE_EMAIL_SUCCESS:
            return{
                ...state,
                previewEmail: action.payload.previewEmail,
                previewPdfList: action.payload.previewPdfList,
                loading: false
            }
        case SET_PREVIEW_HAGUE_EMAIL:
            return{
                ...state,
                previewEmail: action.payload,
                previewPdfList: []
            }
        case SET_UPGRADE_ALERT_MESSAGE:
            return {
                ...state,
                upgradeAlertMessage: action.payload,
            }
        case CHANGE_SENT_HAGUE_RESULT:
            return{
                ...state,
                sentCnt: action.payload.sentCnt,
                unsubsEmails: action.payload.unsubsEmails,
                disableEmails: action.payload.disableEmails, 
                sentIn24Emails: action.payload.sentIn24Emails, 
                sentSuccess: 'Success'
            }
        case SET_HAGUE_SENT_SUCCESS:
            return {
                ...state,
                sentSuccess: action.payload,
            }
        default:
                state = { ...state }
                break
    }
    return state
}

export default hagueNotifier;