import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_HAGUE_NOTIFIER_SETTINGS,
  GET_HAGUE_INFO,
  GET_HAGUE_JURISDICTIONS,
  GET_HAGUE_COUNTRIES,
  GET_HAGUE_HOLDER_LIST,
  SAVE_HAGUE_NOTIFIER_SETTINGS,
  MULTIPLE_STAR_HAGUE,
  MULTIPLE_HIDE_HAGUE,
  MULTIPLE_UNHIDE_HAGUE,
  MULTIPLE_UNSTAR_HAGUE,
  DOWN_HAGUE_EXCEL,
  STAR_HAGUE_TARGET,
  UNSTAR_HAGUE_TARGET,
  HIDE_HAGUE_TARGET,
  UNHIDE_HAGUE_TARGET,
  DOWN_MULTIPLE_HAGUE_EXCEL,
  DOWN_HAGUE_PDF,
  DOWN_MULTIPLE_HAGUE_PDF,
  SEND_HAGUE_EMAIL,
  PREVIEW_HAGUE_EMAIL,
  UNCLOCK_HAGUE,
  GET_HAGUE_NOTIFIER_INFO_LIST
} from "./actionTypes";
import {
  getHagueNotifierSettings,
  getHagueInfo,
  getHagueNotifierInfoList,
  getMultipleJurisdictionsList,
  getCountryList,
  getHagueHolderList,
  saveHagueNotifierSettings,
  multipleStarHague,
  multipleHideHague,
  multipleUnstarHague,
  multipleUnhideHague,
  downHagueExcel,
  multipleStarContacts,
  multipleUnstarContacts,
  multipleHideContacts,
  multipleUnhideContacts,
  downMultipleHagueExcel,
  downHaguePdf,
  downMultipleHaguePdf,
  sendHagueEmail,
  previewHagueEmail,
  unlockHague
} from "../../helpers/backend_helper";
import {
  getHagueNotifierInfoList as getHagueNotifierInfoListAction,
  getHagueNotifierInfoListSuccessUnlock,
  getHagueNotifierSettingsSuccess,
  getHagueInfoSuccess,
  getHagueNotifierInfoListSuccess,
  getHagueJurisdictionsSuccess,
  getHagueCountriesSuccess,
  getHagueHolderListSuccess,
  saveHagueNotifierSettingsSuccess,
  multipleStarHagueSuccess,
  multipleHideHagueSuccess,
  multipleUnstarHagueSuccess,
  multipleUnhideHagueSuccess,
  downHagueExcelSuccess,
  downMultipleHagueExcelSuccess,
  downHaguePdfSuccess,
  downMultipleHaguePdfSuccess,
  previewHagueEmailSuccess,
  sentHagueEmailSuccess,
  setUpgradeAlertMessage,
  setHagueResults,
  changeSentHagueResult
} from "./actions";
import { getUserUnlockInfo } from "../unlock/actions";
import { cloneDeep } from 'lodash';

//get hague notifier settings
function* onGetHagueNotifierSettings() {
  try {
    const response = yield call(getHagueNotifierSettings);
    yield put(getHagueNotifierSettingsSuccess(response));
  } catch (error) {
    console.log("Failed to get Hague notifier settings", error);
  }
}

//get hague info
function* onGetHagueInfo({ payload: data }) {
  try {
    const response = yield call(getHagueInfo, data);
    yield put(getHagueInfoSuccess(response));
  } catch (error) {
    console.log("Failed to get hague info", error);
  }
}

//get hague notifier info list
function* onGetHagueNotifierInfoList({ payload: data }) {
  try {
    const response = yield call(getHagueNotifierInfoList, data);
    yield put(getHagueNotifierInfoListSuccess(response));
  } catch (error) {
    console.log("Failed to get hague notifier info list", error);
  }
}

//get jurisdictions
function* onGetHagueJurisdictions({ payload: data }) {
  try {
    const response = yield call(getMultipleJurisdictionsList, data);
    yield put(
      getHagueJurisdictionsSuccess(
        response
      )
    );
  } catch (error) {
    console.log("Failed to get jurisdictions", error);
  }
}

function* onGetHagueCountries({ payload: inOutType }) {
  try {
    const response = yield call(getCountryList, inOutType);
    yield put(getHagueCountriesSuccess(response.countryList));
  } catch (error) {
    console.log("onGetCountryList error >> " + error);
  }
}

function* onGetHagueHolderList({ payload: data }) {
  try {
    const response = yield call(getHagueHolderList, data);
    yield put(getHagueHolderListSuccess(response.holders));
  } catch (error) {
    console.log("Failed to get hague holder list", error);
  }
}

function* onSaveHagueSettings({ payload: data }) {
  try {
    const response = yield call(saveHagueNotifierSettings, data);
    yield put(saveHagueNotifierSettingsSuccess(response));
  } catch (error) {
    console.log("Failed to save hague settings", error);
  }
}

//multiple star hague
function* onMultipleStarHague({ payload: data }) {
  try {
    const response = yield call(multipleStarHague, data.hagueNotifierList);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType));
    }
  } catch (error) {
    console.log("Failed to star hague", error);
  }
}

//multiple hide hague
function* onMultipleHideHague({ payload: data }) {
  try {
    const response = yield call(multipleHideHague, data.hagueNotifierList);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType));
    }
  } catch (error) {
    console.log("Failed to hide hague", error);
  }
}

//multiple unstar hague
function* onMultipleUnstarHague({ payload: data }) {
  try {
    const response = yield call(multipleUnstarHague, data.hagueNotifierList);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType));
    }
  } catch (error) {
    console.log("Failed to unstar hague", error);
  }
}

//multiple unhide hague
function* onMultipleUnhideHague({ payload: data }) {
  try {
    const response = yield call(multipleUnhideHague, data.hagueNotifierList);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType));
    }
  } catch (error) {
    console.log("Failed to unhide hague", error);
  }
}

//download hague excel
function* onDownHagueExcel({ payload: data }) {
  //console.log('onDownMadridExcel fileName : ' + data.fileName);
  try {
    yield call(downHagueExcel, data);
    yield put(downHagueExcelSuccess());
  } catch (error) {
    console.log("Failed to download hague excel", error);
  }
}

//star target
function* onStarHagueTarget({ payload: data }) {
  try {
    const response = yield call(multipleStarContacts, [{targetGroupNo: data.targetGroupNo}]);
    
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType))
    }
  } catch (error) {
    console.log("onStarTarget error >> " + error);
  }
}

//unstar target
function* onUnstarHagueTarget({ payload: data }) {
  try {
    const response = yield call(multipleUnstarContacts, [{targetGroupNo: data.targetGroupNo}]);

    if(response.result === 'Success'){
      // console.log("DATA.INOUTTYPE")
      // console.log(data.inOutType);
      // console.log("HAGUENOTIFIERLIST")
      // console.log(data.hagueNotifierList)
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType))
    }  
  } catch (error) {
    console.log("onUnstarTarget error >> " + error);
  }
}

//hide target
function* onHideHagueTarget({ payload: data }) {
  try {
    const response = yield call(multipleHideContacts, [{targetGroupNo: data.targetGroupNo}]);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType))
    }
  } catch (error) {
    console.log("onHideTarget error >> " + error);
  }
}

//unhide target
function* onUnhideHagueTarget({ payload: data }) {
  try {
    const response = yield call(multipleUnhideContacts, [{targetGroupNo: data.targetGroupNo}]);
    if(response.result === 'Success'){
      yield put(getHagueNotifierInfoListAction(data.hagueNotifierList, data.inOutType))
    }
  } catch (error) {
    console.log("onUnhideTarget error >> " + error);
  }
}

//download multiple hague excel
function* onDownMultipleHagueExcel({ payload: data }) {
  try {
    yield call(downMultipleHagueExcel, data);
    yield put(downMultipleHagueExcelSuccess());
  } catch (error) {
    console.log("Failed to download multiple hague excel", error);
  }
}

//download hague pdf
function* onDownHaguePdf({ payload: data }) {
  try {
    yield call(downHaguePdf, data);
    yield put(downHaguePdfSuccess());
  } catch (error) {
    console.log("Failed to download hague pdf", error);
  }
}

//download multiple hague pdf
function* onDownMultipleHaguePdf({ payload: data }) {
  try {
    yield call(downMultipleHaguePdf, data);
    yield put(downMultipleHaguePdfSuccess());
  } catch (error) {
    console.log("Failed to download multiple hague pdf", error);
  }
}

//send hague email
function* onSendHagueEmail({ payload: data }) {
  try {
    const response = yield call(sendHagueEmail, data);
    if(response.error !== undefined && response.error > -1){
      yield put(setHagueResults(response.error));
    }else{
      yield put(changeSentHagueResult(response));
      // console.log("SUCCESS")
    }
    // yield put(sentHagueEmailSuccess(response));
    const response2 = yield call(getHagueNotifierInfoList, {hagueNotifierList:data.hagueNotifierList, inOutType:data.inOutType});
    yield put (getHagueNotifierInfoListSuccess(response2));
  } catch (error) {
    console.log("onSendHagueEmail error >> " + error);
  }
}


//preview hague email
function* onPreviewHagueEmail({ payload: data }) {
  try {
    const response = yield call(previewHagueEmail, data);
    yield put(previewHagueEmailSuccess(response));
  } catch (error) {
    console.log("onPreviewHagueEmail error >> " + error);
  }
}

//unlock hague
function* onUnlockHague({ payload: data }) {
  try {
    const response = yield call(unlockHague, data);
    if(response.error !== undefined && response.error> -1){
      yield put(getUserUnlockInfo()); 
      yield put(setHagueResults(response.error));
      // console.log("set hague result")
  }else{
    if(response.result === "Success"){
      yield put(getUserUnlockInfo());

    if(response.remainUnlockCnt == 0){
      yield put(setUpgradeAlertMessage({title:"You have just used your final credit.",text:"Upgrade your WeCrest plan to receive unlimited access for your jurisdiction, or wait until you receive more free credits on Monday."}));
    }
  }else{
      yield put(getUserUnlockInfo());
      yield put(setHagueResults(response.result));
      // console.log("set hague result")
  }
}
    if (response.unlockList) {
      let _caseList = cloneDeep(data.hagueNotifierList);
      // console.log("DATA HAGUE")
      // console.log(data.hagueNotifierList)
      // console.log("INTIAL CASELIST >>" )
      // console.log( _caseList);
      let _hagueNotifierList = [];
      data.hagueNotifierList.map((caseData, index) => {
        response.unlockList.map((unlockData, index2) => {
          if (caseData.repRankCode == unlockData.repRankCode) {
            // console.log('repRankCode >> ' + unlockData.repRankCode);
            let _caseData = cloneDeep(caseData);
            _caseData.irNumber = unlockData.irNumber;
            _hagueNotifierList.push(_caseData);
            // console.log("HAGUENOTIFIERLIOST >>" )
            // console.log(_hagueNotifierList);
            return;
          }
        })
      })
      _caseList = _hagueNotifierList;
      // console.log("FINAL CASELIST >>") 
      // console.log(_caseList)

   const response2 =  yield call(getHagueNotifierInfoList, {hagueNotifierList:_caseList, inOutType:data.inOutType});
   yield put(getHagueNotifierInfoListSuccessUnlock(response2))
      
  }
}catch(error){
  console.log("onUnlockHague error >> " + error);
}
}


function* hagueNotifierSaga() {
  yield takeLatest(GET_HAGUE_INFO, onGetHagueInfo);
  yield takeLatest(GET_HAGUE_JURISDICTIONS, onGetHagueJurisdictions);
  yield takeLatest(GET_HAGUE_NOTIFIER_SETTINGS, onGetHagueNotifierSettings);
  yield takeLatest(GET_HAGUE_COUNTRIES, onGetHagueCountries);
  yield takeLatest(GET_HAGUE_HOLDER_LIST, onGetHagueHolderList);
  yield takeLatest(SAVE_HAGUE_NOTIFIER_SETTINGS, onSaveHagueSettings);
  yield takeLatest(MULTIPLE_STAR_HAGUE, onMultipleStarHague);
  yield takeLatest(MULTIPLE_HIDE_HAGUE, onMultipleHideHague);
  yield takeLatest(MULTIPLE_UNSTAR_HAGUE, onMultipleUnstarHague);
  yield takeLatest(MULTIPLE_UNHIDE_HAGUE, onMultipleUnhideHague);
  yield takeLatest(DOWN_HAGUE_EXCEL, onDownHagueExcel);
  yield takeLatest(STAR_HAGUE_TARGET, onStarHagueTarget);
  yield takeLatest(UNSTAR_HAGUE_TARGET, onUnstarHagueTarget);
  yield takeLatest(HIDE_HAGUE_TARGET, onHideHagueTarget);
  yield takeLatest(UNHIDE_HAGUE_TARGET, onUnhideHagueTarget);
  yield takeLatest(DOWN_MULTIPLE_HAGUE_EXCEL, onDownMultipleHagueExcel);
  yield takeLatest(DOWN_HAGUE_PDF, onDownHaguePdf);
  yield takeLatest(DOWN_MULTIPLE_HAGUE_PDF, onDownMultipleHaguePdf);
  yield takeLatest(SEND_HAGUE_EMAIL, onSendHagueEmail);
  yield takeLatest(PREVIEW_HAGUE_EMAIL, onPreviewHagueEmail);
  yield takeLatest(UNCLOCK_HAGUE, onUnlockHague);
  yield takeLatest(GET_HAGUE_NOTIFIER_INFO_LIST, onGetHagueNotifierInfoList);
}

export default hagueNotifierSaga;
