import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getMultipleCountryList as onGetMultipleCountryList,
    changeMultipleCountryList as onChangeMultipleCountryList,
    // changeMadridSearchSettingsCountry as onChangeMadridSearchSettingsCountry,
    // changeFilerSearchSettingsCountry as onChangeFilerSearchSettingsCountry,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectMultipleCountry = props => {
    const dispatch = useDispatch();

    const { multipleCountryList, subsCountryCount, multipleCountryList_MCC } = useSelector(state => ({
        multipleCountryList: state.MultipleCountrys.multipleCountryList,
        subsCountryCount: state.MultipleCountrys.subsCountryCount,
        multipleCountryList_MCC: state.MultipleCountrys.multipleCountryList_MCC,
    }));

    const [tright, settright] = useState(false);
  
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'jurType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    const [selectedJurList, setSelectedJurList] = useState([]);
    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    const [firstLoading, setFirstLoading] = useState(true);

    const getMultipleCountryList = () => {
        let _multipleCountryList = [];

        if (props.filterName && props.filterName == 'myConnectedCase') {
            _multipleCountryList = cloneDeep(multipleCountryList_MCC);
        } else {
            _multipleCountryList = cloneDeep(multipleCountryList);
        }

        return _multipleCountryList;
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        let _multipleCountryList = getMultipleCountryList();

        if(selectedJurList.length !== 0){
            let checkBoxCnt = 0;
            let chkCnt = 0;
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;

            setChkAllFlag(false);
            setNonEUFlag(false);

            if(selectedJurList.length === 2){
                _multipleCountryList = _multipleCountryList.map((jur) => {
                    let checkedFlag = false;

                    selectedJurList[1].jurList.map((selectedJur, index) => {
                        if(jur.crCode === selectedJur.crCode){
                            checkedFlag = true;
                            if (jur.buttonType === 'CHECK') {
                                if(jur.euYn === 'N'){
                                    chkNonEuCnt++;
                                }
                                chkCnt++;
                            }
                        }
                    });

                    jur.checked = checkedFlag;

                    if (jur.buttonType === 'CHECK') {
                        if(jur.euYn === 'N'){
                            nonEuCnt++; 
                        }

                        checkBoxCnt++;
                    }
                    return jur;
                });

                if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === chkCnt){
                    setNonEUFlag(true);
                }else{
                    setNonEUFlag(false);
                }
                
                if(checkBoxCnt === chkCnt){
                    setChkAllFlag(true);
                }else{
                    setChkAllFlag(false);
                }

                dispatch(onChangeMultipleCountryList(_multipleCountryList, props.filterName));
            }
        } else {
            // SelectCountry 여러개 호출 하는 경우 최초에 로딩시 clear
            _multipleCountryList = _multipleCountryList.map((jur) => {
                jur.checked = false;
                return jur;
            });

            setNonEUFlag(false);
            setChkAllFlag(false);

            dispatch(onChangeMultipleCountryList(_multipleCountryList, props.filterName));
        }

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _multipleCountryList = [];
        multipleCountryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || (jur.crName && jur.crName.toLowerCase().includes(keyword) == true)){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _multipleCountryList.push(_jur);
        }); 

        dispatch(onChangeMultipleCountryList(_multipleCountryList, props.filterName));
    }

    const jurCheck = (str, checked, isRadio) => {
        let checkBoxCnt = 0;
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        let _chkMultipleCountryList = getMultipleCountryList();

        const _multipleCountryList = [];
        _chkMultipleCountryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str === chkAll 
                || (str === nonEU && checked === false))
                && jur.buttonType === 'CHECK' && jur.show){
                _jur.checked = checked;
            }else if(str === nonEU && checked === true && jur.buttonType === 'CHECK' && jur.show){
                if(jur.euYn === 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode === str){
                    if (jur.buttonType === 'CHECK') {
                        _jur.checked = checked;
                    } else {
                        _jur.checked = !_jur.checked;
                    }
                }

                if (isRadio && jur.crCode !== str && jur.buttonType === 'RADIO') {
                    _jur.checked = false;
                }
            }

            if (jur.buttonType === 'CHECK'){
                if(_jur.checked === true){
                    chkCnt++;

                    if(jur.euYn === 'N'){
                        chkNonEuCnt++;
                    }
                }

                if(jur.euYn === 'N'){
                    nonEuCnt++;
                }

                checkBoxCnt++;
            }

            _multipleCountryList.push(_jur);
        }); 

        if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(checkBoxCnt === chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeMultipleCountryList(_multipleCountryList, props.filterName));
    }

    const jurConfirm = () => {
        let _crCdList = [];

        let _multipleCountryList = getMultipleCountryList();
        _multipleCountryList.map((jur, index) =>{
            if(jur.checked === true){
                _crCdList.push(jur.crCode);
            }
        });

        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(props.formik.values.holderCdListMyConnectedCase !== undefined){
                props.formik.setFieldValue("holderCdListMyConnectedCase", _crCdList);
            }
        } else {
            if (props.componentName==='Holder') {
                props.formik.setFieldValue("holderCdList", _crCdList);
            } else if (props.componentName==='Representative') {
                props.formik.setFieldValue("repCdList", _crCdList);
            } else {
                props.formik.setFieldValue("crCdList", _crCdList);

                // console.log("_crCdList : "+ _crCdList);
            }
        }

        // openCountryModal();
        closeCountryModal(_crCdList);
    }

    const closeCountryModal = (_crCdList) => {
        htmlSetting(_crCdList)
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const htmlSetting = (crCdList) => {
        if(crCdList && crCdList.length){
            let _selectedJurList = [];
            let _tempList = [];
            let crType = "";
            let chkNonEuCnt = 0;
            let nonEuAllCnt = 0;
            let checkBoxCnt = 0;
            let checkBoxAllCnt = 0;
            let checkCnt = 0;
            
            let _multipleCountryList = getMultipleCountryList();
            _multipleCountryList.map((jur, index) =>{
                if(crCdList.includes(jur.crCode)){
                    _tempList.push(jur);
                    if (jur.buttonType === 'CHECK') {
                        if (jur.euYn === 'N'){
                            chkNonEuCnt++;
                        }
                        checkBoxCnt++;
                    }
                    checkCnt++;
                }
                
                if (jur.buttonType === 'CHECK') {
                    if(jur.euYn === 'N'){
                        nonEuAllCnt++;
                    }
    
                    checkBoxAllCnt++;
                }
            });
            
            // console.log("htmlSetting crCdList.length : "+ crCdList.length + "//// checkBoxAllCnt : " + checkBoxAllCnt + "//// props.formik.values.inOutType : "+ props.formik.values.inOutType);

            if(checkCnt === checkBoxCnt && checkBoxCnt === checkBoxAllCnt){
                if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType){
                    crType = allSubs;
                }else{
                    crType = chkAll;
                }
            }else if(checkCnt === chkNonEuCnt && chkNonEuCnt === nonEuAllCnt){
                crType = nonEU;
            }else {
                crType = individual;
            }

            // console.log("crType : "+ crType);
            
            _selectedJurList.push({'crType' : crType});
            _selectedJurList.push({'jurList' : _tempList});

            //saveSetting 기능에 필요
            if (props.filterName && props.filterName == 'myConnectedCase') {
                if(props.componentName==='Holder' && props.formik.values.holderTypeMyConnectedCase !== undefined){
                    props.formik.setFieldValue("holderTypeMyConnectedCase", crType);
                }
            } else {
                if(props.formik.values.crType !== undefined 
                    || (props.componentName==='Holder' && props.formik.values.holderType !== undefined)
                    || (props.componentName==='Representative' && props.formik.values.repType !== undefined)){
    
                    if (props.componentName==='Holder') {
                        props.formik.setFieldValue("holderType", crType);
                    } else if (props.componentName==='Representative') {
                        props.formik.setFieldValue("repType", crType);
                    } else {
                        props.formik.setFieldValue("crType", crType);
                    }
                }
            }

            setSelectedJurList(_selectedJurList);
            
        }else{
            let crType = "";

            if (props.filterName && props.filterName == 'myConnectedCase') {
                if (props.componentName==='Holder' && props.formik.values.holderTypeMyConnectedCase && props.formik.values.holderTypeMyConnectedCase === nonEU) {
                    crType = nonEU;
                } else {
                    if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType){
                        crType = allSubs;
                    }else{
                        crType = chkAll;
                    }
                }

                if (props.componentName==='Holder' && props.formik.values.holderTypeMyConnectedCase !== undefined){
                    props.formik.setFieldValue("holderTypeMyConnectedCase", crType);
                }
            } else {
                if ((props.formik.values.crType && props.formik.values.crType === nonEU)
                    || (props.componentName==='Holder' && props.formik.values.holderType && props.formik.values.holderType === nonEU)
                    || (props.componentName==='Representative' && props.formik.values.repType && props.formik.values.repType === nonEU)) {
                    crType = nonEU;
                } else {
                    if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType){
                        crType = allSubs;
                    }else{
                        crType = chkAll;
                    }
                }

                if(props.formik.values.crType !== undefined 
                    || (props.componentName==='Holder' && props.formik.values.holderType !== undefined)
                    || (props.componentName==='Representative' && props.formik.values.repType !== undefined)){

                    if (props.componentName==='Holder') {
                        props.formik.setFieldValue("holderType", crType);

                        // console.log("holderType setFieldValue : "+ crType);

                        // let _values = cloneDeep(props.formik.values);
                        // console.log("_values : "+ JSON.stringify(_values));
                    } else if (props.componentName==='Representative') {
                        props.formik.setFieldValue("repType", crType);
                    } else {

                        // console.log('crType > ' +  crType)
                        props.formik.setFieldValue("crType", crType);
                    }
                }
            }

            if (crType === nonEU) {
                let _selectedJurList = [];
                let _tempList = [];
                let _multipleCountryList = getMultipleCountryList();
                _multipleCountryList.map((jur, index) => {
                    if(jur.euYn === 'N' && jur.buttonType === 'CHECK'){
                        _tempList.push(jur);
                    }
                });
                _selectedJurList.push({'crType' : crType});
                _selectedJurList.push({'jurList' : _tempList});
                setSelectedJurList(_selectedJurList);
            } else {
                setSelectedJurList([]);
            }
        }

        // if (props.formik.values.screenName === "MadridNotifier") {
        //     dispatch(onChangeMadridSearchSettingsCountry(props.componentName, props.formik.values.inOutType, props.formik.values.holderType, props.formik.values.holderCdList));
        // } else if (props.formik.values.screenName === "FilerRankings") {
        //     dispatch(onChangeFilerSearchSettingsCountry(props.componentName, props.formik.values.inOutType, props.formik.values.crType, props.formik.values.crCdList));
        // }
    }

    useEffect(()=> {
        //inOutType이 undefined여도 호출됨
        dispatch(onGetMultipleCountryList(props.formik.values.inOutType, props.formik.values.screenGrade, props.filterName, props.subscriptionType));

        if (props.filterName && props.filterName == 'myConnectedCase') {
            if (props.componentName && props.componentName === 'Holder' && multipleCountryList_MCC && multipleCountryList_MCC.length > 0) {
                htmlSetting(props.formik.values.holderCdListMyConnectedCase);
            }
        } else {
            if(multipleCountryList.length > 0){
                if (props.componentName && props.componentName === 'Holder') {
                    htmlSetting(props.formik.values.holderCdList);
                } else if (props.componentName && props.componentName === 'Representative') {
                    htmlSetting(props.formik.values.repCdList);
                } else {
                    htmlSetting(props.formik.values.crCdList);
                }
            }
        }
    },[props.formik.values.inOutType]);
    
    // useEffect(()=> {
    //     if (!props.filterName || props.filterName !== 'myConnectedCase') {
    //         if(!props.componentName || (props.componentName !== 'Holder' && props.componentName !== 'Representative' && multipleCountryList && multipleCountryList.length > 0)){
    //             // console.log('htmlSetting crCdList : ' + props.formik.values.crCdList);
    //             htmlSetting(props.formik.values.crCdList);
    //         }
    //     }
    // },[JSON.stringify(props.formik.values.crCdList)]);

    // useEffect(()=> {
    //     if (!props.filterName || props.filterName !== 'myConnectedCase') {
    //         if(props.componentName && props.componentName === 'Holder' && multipleCountryList && multipleCountryList.length > 0){
    //             // console.log('htmlSetting holderCdList : ' + props.formik.values.holderCdList);
    //             htmlSetting(props.formik.values.holderCdList);
    //         }
    //     }
    // },[JSON.stringify(props.formik.values.holderCdList)]);

    // useEffect(()=> {
    //     if (!props.filterName || props.filterName !== 'myConnectedCase') {
    //         if(props.componentName && props.componentName === 'Representative' && multipleCountryList && multipleCountryList.length > 0){
    //             // console.log('htmlSetting repCdList : ' + props.formik.values.repCdList);
    //             htmlSetting(props.formik.values.repCdList);
    //         }
    //     }
    // },[JSON.stringify(props.formik.values.repCdList)]);

    useEffect(()=> {
        if (props.filterName && props.filterName == 'myConnectedCase') {
            if(props.componentName && props.componentName === 'Holder' && multipleCountryList_MCC && multipleCountryList_MCC.length > 0){
                htmlSetting(props.formik.values.holderCdListMyConnectedCase);
            }
        }
    },[JSON.stringify(props.formik.values.holderCdListMyConnectedCase)]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if ((!props.filterName || props.filterName !== 'myConnectedCase') && firstLoading) {
            if(multipleCountryList && multipleCountryList.length > 0){
                if (props.componentName && props.componentName === 'Holder') {
                    htmlSetting(props.formik.values.holderCdList);
                } else if (props.componentName && props.componentName === 'Representative') {
                    htmlSetting(props.formik.values.repCdList);
                } else {
                    // console.log('useEffect htmlSetting crCdList : ' + props.formik.values.crCdList);
                    htmlSetting(props.formik.values.crCdList);
                }

                setFirstLoading(false);
            }
        }
    },[JSON.stringify(multipleCountryList)]);

    useEffect(()=> {
        //search settings 불러올때 inOutType하고 jrCdList가 동시에 변경됨
        //onGetJurisdictionsList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if ((props.filterName && props.filterName == 'myConnectedCase') && firstLoading) {
            if(multipleCountryList_MCC && multipleCountryList_MCC.length > 0){
                if (props.componentName && props.componentName === 'Holder') {
                    htmlSetting(props.formik.values.holderCdListMyConnectedCase);
                    setFirstLoading(false);
                }
            }
        }
    },[JSON.stringify(multipleCountryList_MCC)]);

    return (
        <>
        <Row className="mb-3">
            <Col md={props.firstColMd ? props.firstColMd : 3}>
                <label
                    className="col-form-label"
                >
                    {props.componentName ?
                        props.componentName === 'Holder' 
                        ? 'Holder’s country'
                        : 'Representative’s country'
                    : 'Countries of origin'}
                </label>
            </Col>
            <Col md={3}>
                <label
                    className="col-form-label"
                >
                {
                    (()=> {
                        if (selectedJurList && selectedJurList.length != 0) {
                            if(selectedJurList[0].crType === chkAll){
                                return 'All (default)'
                            } else if(selectedJurList[0].crType === allSubs){
                                return 'All subscribed (default)'
                            } else if(selectedJurList[0].crType === nonEU){
                                return 'Non-EU'
                            } else {
                                return selectedJurList[1].jurList.map((selectedJur, index) => {
                                    return (<>{selectedJur.crCode} {selectedJur.crName} <br/></>)
                                });
                            }
                        }else{
                            if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType){
                                return 'All subscribed (default)'
                            }else{
                                return 'All (default)'
                            }
                        }
                    })()
                }
                </label>
            </Col>
            <Col md={3}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target={"selectCountries" + (props.componentName ? props.componentName : "")}
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    {props.componentName ?
                        props.componentName === 'Holder' ? 
                            'Select Holder’s country'
                            : 'Select Representative’s country'
                        : 'Countries of your \n potential clients'}
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{width : '148.58px'}}
                    id={"selectCountries" + (props.componentName ? props.componentName : "")}
                    onClick={() => {
                        openCountryModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    Select countries
                </button>
            </Col>
        </Row>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select countries
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                            search(e.target.value);
                        }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {"OUT" === props.formik.values.inOutType ?
                    subsCountryCount > 0 ? 
                    <>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-6 form-check mb-2">
                            <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                            <i className="uil uil-award"></i> {props.formik.values.screenGrade} countries
                            </Label>
                        </div>
                    </Row>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-3 form-check mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id={chkAll}
                            checked={chkAllFlag}
                            onClick={(e)=>jurCheck(chkAll, e.target.checked, false)}
                            />
                            <Label
                            className="form-check-label"
                            htmlFor={chkAll}
                            >
                            Select all
                            </Label>
                        </div>
                        <div className="col-md-3 form-check mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id={nonEU}
                            checked={nonEUFlag}
                            onClick={(e)=>jurCheck(nonEU, e.target.checked, false)}
                            />
                            <Label
                            className="form-check-label"
                            htmlFor={nonEU}
                            >
                            Non-EU
                            </Label>
                        </div>
                    </Row>
                    </> 
                    :
                    <>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-6 form-check mb-2">
                            <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                            <i className="uil uil-award"></i> {props.formik.values.screenGrade == 'Gold' ? props.formik.values.screenGrade : 'Gold (' + props.formik.values.screenGrade + ')'} countries
                            </Label>
                        </div>
                    </Row>
                    <Row style={{margin : 'auto'}}>
                        <div className="col-md-12 mb-2">
                            <Label
                            className="form-check-label"
                            >
                            Contact us at <a href="mailto:global@wecrest.com">global@wecrest.com</a> to access all features.
                            </Label>
                        </div>
                    </Row>
                    </>
                : 
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked, false)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked, false)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                }
                {
                    getMultipleCountryList() && getMultipleCountryList().length ?
                    getMultipleCountryList().map((jur, index) => {
                        return (
                            jur.show && jur.buttonType === 'CHECK' ? 
                            <div className="form-check mb-2">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked, false)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }

                {"OUT" === props.formik.values.inOutType ?
                <Row style={{margin : 'auto', borderTop: '1px solid #f5f6f8'}}>
                    <div className="col-md-6 form-check mb-2 mt-2">
                        <Label style={{color: '#5c8dc6', marginLeft: '-1.5em'}}>
                        <i className="uil uil-gift"></i> Free countries
                        </Label>
                    </div>
                </Row>
                : null}

                {
                    getMultipleCountryList() && getMultipleCountryList().length ?
                    getMultipleCountryList().map((jur, index) => {
                        return (
                            jur.show && jur.buttonType === 'RADIO' ? 
                            <div className="form-check mb-2">
                                <input
                                type="radio"
                                className="form-check-input"
                                name="countryRadios"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked, true)}
                                />
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SelectMultipleCountry;