import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Input,
    FormGroup,
    Tooltip,
    Button,
    Modal,
    UncontrolledTooltip,
    FormFeedback,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import EditorModal from "../../components/Common/EditorModal"
import SignatureEditorModal from "../../components/Common/SignatureEditorModal"
import SelectJurisdictionsSettings from '../../components/Filter/SelectJurisdictionsSettings';
import SelectCountrySettings from '../../components/Filter/SelectCountrySettings';
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  getEmailingSettings,
  getSettingsEmailTemplates,
  deleteEmailTemplate,
  addSenderEmail,
  deleteSenderEmail,
  updateDisplayName,
  updateSenderEmailAddress,
  getUserSignature,
  deleteUserSignature,
  updateUserJurisdictionEmailSetting,
  deleteUserJurisdictionEmailSetting,
  updateAccountUnsubscribeInfo,
  setSettingsReducerValue,
  getOcmnShowStartup as onGetOcmnShowStartup,
  saveOcmnSettings as onSaveOcmnSettings,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const EmailingSettings = props => {
  const dispatch = useDispatch();

  const { accountInfo, emailTemplates, userSendEmailAddressList, userSignatureList, userJurisdictionEmailSettingList, updateUserJurisdictionEmailSettingInfo, loading, success, errorMsg, jurisdictionList, updateToast, updateToastType, userOneClickMnSetting } = useSelector(state => ({
    accountInfo: state.Settings.accountInfo,
    emailTemplates: state.Settings.emailTemplates,
    userSendEmailAddressList: state.Settings.userSendEmailAddressList,
    userSignatureList: state.Settings.userSignatureList,
    userJurisdictionEmailSettingList: state.Settings.userJurisdictionEmailSettingList,
    updateUserJurisdictionEmailSettingInfo: state.Settings.updateUserJurisdictionEmailSettingInfo,
    loading: state.Settings.loading,
    success: state.Settings.success,
    errorMsg: state.Settings.errorMsg,
    jurisdictionList: state.GeneralStatistics.jurisdictionList,
    updateToast: state.Settings.updateToast,
    updateToastType: state.Settings.updateToastType,
    userOneClickMnSetting: state.Settings.userOneClickMnSetting,
  }));

  const [deleteSendEmailAddressInfo, setDeleteSendEmailAddressInfo] = useState({seq:'-1'});
  const [previewEmail, setPreviewEmail] = useState({});
  const [deleteSignatureInfo, setDeleteSignatureInfo] = useState({seq:'-1'});
  const [previewSignatureInfo, setPreviewSignatureInfo] = useState({});

  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false);
  const [deleteEmailTempateIdx, setDeleteEmailTempateIdx] = useState('');

  const [jurOpen, setJurOpen] = useState(false);
  const [countryOpen, setCountryOpen] = useState(false);

  const [hagueJurOpen, setHagueJurOpen] = useState(false);
  const [hagueCountryOpen, setHagueCountryOpen] = useState(false);

  const [serviceName, setServiceName] = useState('');

  const closeLoding = () => {
    dispatch(setSettingsReducerValue('LOADING_FLAG', false));
  }

  // [S] Email Editor
  const editorModaRef = useRef(null);
  const editorModal = useRef(null);

  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [isInitEditor, setIsInitEditor] = useState(false);
  const [loadedEditor, setLoadedEditor] = useState(false);
  const [editorIdx, setEditorIdx] = useState(-1);
  
  function openEditor(idx) {
    if (!isInitEditor) {
      console.log('editor init start');
      setIsInitEditor(true);
    } else {
      console.log('editor inited');
    }
    
    setEditorIdx(idx);
    setIsOpenEditor(true);
    removeBodyCss();
  }

  function closeEditor() {
    console.log('closeEditor')
    dispatch(getSettingsEmailTemplates());
    setIsOpenEditor(false);
    setEditorIdx(-2);
    editorModaRef.current.clearEditorContent();
    document.body.classList.remove("modal-open");
    document.body.classList.remove("no_padding");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
    document.body.classList.add("modal-open");
    document.body.style.overflow = 'hidden';
  }
  
  const sigEditorModal = useRef(null);
  const [isOpenSigEditor, setIsOpenSigEditor] = useState(false);
  const [isInitSigEditor, setIsInitSigEditor] = useState(false);
  const [loadedSigEditor, setLoadedSigEditor] = useState(false);
  
  function openSigEditor(userSignature) {
    if (!isInitSigEditor) {
      console.log('sig editor init start')
      setIsInitSigEditor(true);
    } else {
      console.log('sig editor inited')
    }
    
    dispatch(setSettingsReducerValue('USER_SIGNATURE', userSignature));
    dispatch(setSettingsReducerValue('CLOSE_EDITOR_FLAG', false));
    setIsOpenSigEditor(true);
    removeBodyCss();
  }

  function closeSigEditor() {
    dispatch(getUserSignature());
    setIsOpenSigEditor(false);
    document.body.classList.remove("modal-open");
    document.body.classList.remove("no_padding");
    document.body.style.overflow = 'visible';
    document.body.style.paddingRight = '';
  }

  const handleCloseEmailSettingModal = (e) => {
    if (e.target.id === 'EditorModal' && (!editorModal.current || !editorModal.current.contains(e.target))) {
      closeEditor()
    }

    if (e.target.id === 'SigEditorModal' && (!sigEditorModal.current || !sigEditorModal.current.contains(e.target))) {
      closeSigEditor()
    }
  }

  useEffect(()=> {
    dispatch(getEmailingSettings());
    dispatch(getSettingsEmailTemplates());
    dispatch(onGetOcmnShowStartup());
    window.addEventListener('click', handleCloseEmailSettingModal);
    return () => {
      window.removeEventListener('click', handleCloseEmailSettingModal);
    }
  },[]);
  // [E] Editor

  const [jrCdList, setJrCdList] = useState([]);
  const [crCdList, setCrCdList] = useState([]);
  const [jrAddButtonFlag, setJrAddButtonFlag] = useState(false);
  const [crAddButtonFlag, setCrAddButtonFlag] = useState(false);

  const [hagueJrCdList, setHagueJrCdList] = useState([]);
  const [hagueCrCdList, setHagueCrCdList] = useState([]);
  const [hagueJrAddButtonFlag, setHagueJrAddButtonFlag] = useState(false);
  const [hagueCrAddButtonFlag, setHagueCrAddButtonFlag] = useState(false);

  useEffect(()=> {
    if (userJurisdictionEmailSettingList && userJurisdictionEmailSettingList.length) {

      // console.log('userJurisdictionEmailSettingList.length : ' + userJurisdictionEmailSettingList.length);

      let _jrCdList = [];
      let _crCdList = [];

      let _hagueJrCdList = [];
      let _hagueCrCdList = [];
      userJurisdictionEmailSettingList.map((jurisdictionEmailSetting, index2) =>{
        if (jurisdictionEmailSetting.serviceName == 'MadridNotifier') {
          if (jurisdictionEmailSetting.inOutType == 'IN' && jurisdictionEmailSetting.crCode != '00') {
            _jrCdList.push(jurisdictionEmailSetting.crCode);
          }

          if (jurisdictionEmailSetting.inOutType == 'OUT' && jurisdictionEmailSetting.crCode != '00') {
            _crCdList.push(jurisdictionEmailSetting.crCode);
          }
        } else if (jurisdictionEmailSetting.serviceName == 'HagueNotifier') {
          if (jurisdictionEmailSetting.inOutType == 'IN' && jurisdictionEmailSetting.crCode != '00') {
            _hagueJrCdList.push(jurisdictionEmailSetting.crCode);
          }

          if (jurisdictionEmailSetting.inOutType == 'OUT' && jurisdictionEmailSetting.crCode != '00') {
            _hagueCrCdList.push(jurisdictionEmailSetting.crCode);
          }
        }
      });

      // console.log('_jrCdList : ' + JSON.stringify(_jrCdList));
      // console.log('_crCdList : ' + JSON.stringify(_crCdList));

      setJrCdList(_jrCdList);
      setCrCdList(_crCdList);
      setHagueJrCdList(_hagueJrCdList);
      setHagueCrCdList(_hagueCrCdList);
    }
    
  },[userJurisdictionEmailSettingList]);

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      submitType: "",
      unsubscribeEmailYn: accountInfo.unsubscribeEmailYn,
      unsubscribeDescription: accountInfo.unsubscribeDescription,
      unsubscribeLinkText: accountInfo.unsubscribeLinkText,

      showStartup: userOneClickMnSetting.showStartup,
      ocmnAutoSendYn: userOneClickMnSetting.autoSendYn,

      emailAddress: "",
      displayName: "",
    },
    onSubmit: (values, {setErrors}) => {
      // console.log('crCdList 1 : ' + values["crCdList"])
      // console.log('country 1 : ' + values["country"])
      // if (values["crCdList"].length > 0) {
      //   values["country"] = values["crCdList"][0]
      // }

      // console.log('crCdList 2 : ' + values["crCdList"])
      // console.log('country 2 : ' + values["country"])
      let submitType = values["submitType"];

      if(submitType === "updateAccountUnsubscribeInfo"){
        const formData = new FormData();
        formData.append('unsubscribeEmailYn', values["unsubscribeEmailYn"]);
        formData.append('unsubscribeDescription', values["unsubscribeDescription"]);
        formData.append('unsubscribeLinkText', values["unsubscribeLinkText"]);
  
        dispatch(updateAccountUnsubscribeInfo(formData));
      } else if(submitType === "updateOcmnSettings"){
        dispatch(onSaveOcmnSettings({showStartup:values["showStartup"], autoSendYn:values["ocmnAutoSendYn"]}));
      }
    },
  });

  const showSuccessUpdateToast = () => {
    const ele = "success";
    const position = "toast-bottom-left"
    const title = ""
    let message = "Sender " + (updateToastType === "EMAIL" ? "email address" : "display name") + " successfully updated!"

    //Close Button
    const closeButton = false

    //Debug
    const debug = false

    //Progressbar
    const progressBar = false

    //Duplicates
    const preventDuplicates = false

    //Newest on Top
    const newestOnTop = false

    //position class
    let positionClass = "toast-bottom-left"

    //Show Easing
    const showEasing = "swing";

    //Hide Easing
    const hideEasing = "linear";

    //show method
    const showMethod = "fadeIn";

    //Hide method
    const hideMethod = "fadeOut";

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 4000;

    //extended timeout
    const extendedTimeOut = 1000;

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    toastr.success(message, title)
  }

  useEffect(()=> {
    if (updateToast) {
      showSuccessUpdateToast();
      dispatch(setSettingsReducerValue('UPDATE_TOAST', false));
    }
  },[updateToast]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Form 
            onSubmit={(e) => {
              e.preventDefault();
              filterValidation.handleSubmit();
              return false;
            }}
          >
         
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Emailing</CardTitle>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Sender email address
                  </label>
                  <label className="col-md-3 col-form-label">
                  Sender display name
                  </label>
                  <label className="col-md-3 col-form-label">
                  Status
                  </label>
                </Row>
                {
                  userSendEmailAddressList && userSendEmailAddressList.length ? 
                  userSendEmailAddressList.map((userSendEmailAddress, index) => {
                    return (
                      <Row className="mb-3">
                        <div className={userSendEmailAddress.confirmStatus === 'Confirmed' ? "col-md-3 col-form-label text-success" : "col-md-3 col-form-label text-warning"}>
                          {
                            JSON.parse(localStorage.getItem("authUser")).virtualLogIn === 'Y'?
                            <Input
                              id={"sender_email_address_"+index}
                              name="emailAddress"
                              className="form-control"
                              placeholder="Sender display name"
                              type="text"
                              onChange={(e) => {
                                let _userSendEmailAddressList = cloneDeep(userSendEmailAddressList);
                                _userSendEmailAddressList[index].emailAddress = e.target.value;

                                dispatch(setSettingsReducerValue('USER_SENDEMAIL_ADDRESS_LIST', _userSendEmailAddressList));
                              }}
                              onBlur={(e) => { 
                                if (e.target.value.trim() === '') {
                                  dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter a sender email address."));
                                  document.getElementById("sender_email_address_"+index).focus();
                                } else {
                                  if (userSendEmailAddressList[index].orgEmailAddress !== e.target.value.trim()) {
                                    // console.log('Sender display name : ' + e.target.value.trim());
                                    dispatch(updateSenderEmailAddress({userNo: userSendEmailAddress.userNo, seq: userSendEmailAddress.seq, emailAddress: e.target.value.trim()}));
                                  }
                                }
                              }}
                              value={userSendEmailAddress.emailAddress}
                            />
                            :
                            userSendEmailAddress.emailAddress
                          }
                        </div>
                        <div className={userSendEmailAddress.confirmStatus === 'Confirmed' ? "col-md-3 col-form-label text-success" : "col-md-3 col-form-label text-warning"}>
                          <Input
                            id={"sender_display_name_"+index}
                            name="sender_display_name"
                            className="form-control"
                            placeholder="Sender display name"
                            type="text"
                            onChange={(e) => {
                              let _userSendEmailAddressList = cloneDeep(userSendEmailAddressList);
                              _userSendEmailAddressList[index].displayName = e.target.value;

                              dispatch(setSettingsReducerValue('USER_SENDEMAIL_ADDRESS_LIST', _userSendEmailAddressList));
                            }}
                            onBlur={(e) => { 
                              if (e.target.value.trim() === '') {
                                dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter a sender display name."));
                                document.getElementById("sender_display_name_"+index).focus();
                              } else {
                                if (userSendEmailAddressList[index].orgDisplayName != e.target.value.trim()) {
                                  // console.log('Sender display name : ' + e.target.value.trim());
                                  dispatch(updateDisplayName({userNo: userSendEmailAddress.userNo, seq: userSendEmailAddress.seq, displayName: e.target.value.trim()}));
                                }
                              }
                            }}
                            value={userSendEmailAddress.displayName}
                          />
                        </div>
                        <div className="col-md-3 col-form-label d-flex gap-3">
                          <div className={userSendEmailAddress.confirmStatus === 'Confirmed' ? "text-success" : "text-warning"}>
                          {userSendEmailAddress.confirmStatus}
                          {userSendEmailAddress.confirmStatus == 'Confirmed' ?<i class="uil uil-check font-size-16 text-success me-2"></i>:"..."}
                          </div>
                          {userSendEmailAddress.defaultYn === 'N' ?
                          <Link to="#" 
                                onClick={() => {
                                  setDeleteSendEmailAddressInfo(userSendEmailAddress)
                                }}
                          >
                            <i className="uil-trash-alt" id={"deleteEmlIco"+index} style={{color:"red", fontSize:"18px"}}></i>
                            <UncontrolledTooltip placement="top" target={"deleteEmlIco"+index}>
                            Delete sender email address
                            </UncontrolledTooltip>
                          </Link>
                          : null}
                        </div>
                      </Row>
                    )
                  })
                  :
                  null
                }
                <Row className="mb-3">
                  <div className="col-md-3">
                    <Input
                      id="emailAddress"
                      name="emailAddress"
                      className="form-control"
                      placeholder="Sender email"
                      type="email"
                      onChange={filterValidation.handleChange}
                      onBlur={filterValidation.handleBlur}
                      value={filterValidation.values.emailAddress || ""}
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Sender display name"
                      name="displayName"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.displayName}
                    />
                  </div>
                  <label className="col-md-3">
                  <Button
                    type="button"
                    color="outline-primary"
                    className="w-md"
                    onClick={()=>{
                      let isDupe = false;
                      userSendEmailAddressList.map((userSendEmailAddress, index) => {
                        // console.log('check email >> ' + userSendEmailAddress.emailAddress + ' / ' + filterValidation.values.emailAddress.trim());
                        if (userSendEmailAddress.emailAddress == filterValidation.values.emailAddress.trim()) {
                          isDupe = true;
                        }
                      })

                      // console.log('isDupe >> ' + isDupe);

                      if (isDupe) {
                        dispatch(setSettingsReducerValue('ERROR_MSG', "'" + filterValidation.values.emailAddress + "' is already saved email address."));
                      } else {

                        if (filterValidation.values.emailAddress.trim() == '') {
                          dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter a sender email address."));
                        } else if (!/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,15}$/i.test(filterValidation.values.emailAddress.trim())) {
                          dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter a valid sender email address."));
                        } else if (filterValidation.values.displayName.trim() == '') {
                          dispatch(setSettingsReducerValue('ERROR_MSG', "Please enter a sender display name."));
                        } else {
                          dispatch(addSenderEmail({emailAddress: filterValidation.values.emailAddress.trim(), displayName: filterValidation.values.displayName.trim()}));
                          filterValidation.setFieldValue("emailAddress", "");
                          filterValidation.setFieldValue("displayName", "");
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                  </label>
                </Row>
              </CardBody>
            </Card>
          </Row>

          {deleteSendEmailAddressInfo.seq != '-1' ? (
            <SweetAlert
              title="Warning!"
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                dispatch(deleteSenderEmail(deleteSendEmailAddressInfo));
                setDeleteSendEmailAddressInfo({seq:'-1'});
              }}
              onCancel={() => setDeleteSendEmailAddressInfo({seq:'-1'})}
            >
              If this is your default sender email for any of your jurisdictions or Filer Rankings, you will need to select a new sender email.
            </SweetAlert>
          ) : null}

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Email template</CardTitle>
                {
                  emailTemplates && emailTemplates.length ? 
                  emailTemplates.map((emailTemplate, index) => {
                    return (
                      <Row className="mb-3">
                        <label className="col-md-6 col-form-label">
                          {emailTemplate.tmpTitle}
                        </label>
                        <div className="col-md-4 col-form-label d-flex gap-3">
                          <Link to="#" onClick={() => {openEditor(emailTemplate.idx)}}>
                            <i className="uil-envelope-edit" id={"editIco"+index} style={{color:"#5c8dc6", fontSize:"18px"}}></i>
                            <UncontrolledTooltip placement="top" target={"editIco"+index}>
                              Edit form
                            </UncontrolledTooltip>
                          </Link>
                          <Link to="#" onClick={() => {setPreviewEmail(emailTemplate)}}>
                            <i className="uil uil-file-search-alt" id={"emlPreview_"+index} style={{color:"#5c8dc6", fontSize:"18px"}}></i>
                            <UncontrolledTooltip placement="top" target={"emlPreview_"+index}>
                              Preview form
                            </UncontrolledTooltip>
                          </Link>
                          <Link to="#" onClick={() => {setDeleteEmailTempateIdx(emailTemplate.idx); setDeleteConfirmAlert(true);}}>
                            <i className="uil-trash-alt" id={"deleteIco"+index} style={{color:"red", fontSize:"18px"}}></i>
                            <UncontrolledTooltip placement="top" target={"deleteIco"+index}>
                            Delete form
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </Row>
                    )
                  })
                  :
                  null
                }
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="outline-primary"
                    className="w-md"
                    onClick={()=>{openEditor(-1)}}
                  >
                    Create new
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>

          {deleteConfirmAlert ? (
            <SweetAlert
              title="Warning!"
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setDeleteConfirmAlert(false);
                dispatch(deleteEmailTemplate({emailType:'ST', idx:deleteEmailTempateIdx}));
                setIsInitEditor(false);
              }}
              onCancel={() => setDeleteConfirmAlert(false)}
            >
              If this is your default template for any of your jurisdictions or Filer Rankings, you will need to select a new default template.
            </SweetAlert>
          ) : null}

          <EditorModal isOpenEditor={isOpenEditor} isInitEditor={isInitEditor} setIsInitEditor={setIsInitEditor} closeEditor={closeEditor} loadedEditor={loadedEditor} setLoadedEditor={setLoadedEditor} 
            editorModal={editorModal} ref={editorModaRef} emailType='ST' idx={editorIdx} />

          {previewEmail && Object.keys(previewEmail).length !== 0? (
            <Modal
              size="xl"
              isOpen={true}
              toggle={() => {
                setPreviewEmail({});
              }}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="myExtraLargeModalLabel"
                >
                  Temp title: {previewEmail.tmpTitle? previewEmail.tmpTitle : null}<br/>
                  Subject&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.subject? previewEmail.subject : null}<br/>
                  CC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailCC? previewEmail.emailCC : null}<br/>
                  BCC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {previewEmail.emailBCC? previewEmail.emailBCC : null}<br/>  
                </h5>
                <button
                  onClick={() => {
                    setPreviewEmail({});
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card className="mb-2">
                  <CardBody dangerouslySetInnerHTML={{__html:previewEmail.emailForm? previewEmail.emailForm : null}}></CardBody>
                </Card>
                {
                previewEmail.attachFiles && previewEmail.attachFiles.length?
                <Card className="mb-0">
                  <CardBody className="p-1">
                    <Table className="table mb-0">
                      <tbody>
                        {
                          previewEmail.attachFiles.map((attach, index)=>{
                            return (
                                <tr><td>{attach.fileName}</td></tr>
                              )
                            })
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                : null}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setPreviewEmail({});
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Modal>
          ) : null}

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Formatted email signatures</CardTitle>
                {
                  userSignatureList && userSignatureList.length ? 
                  userSignatureList.map((userSignature, index) => {
                    return (
                      <Row className="mb-3">
                        <label className="col-md-6 col-form-label">
                          {userSignature.sigName}
                        </label>
                        <div className="col-md-4 col-form-label d-flex gap-3">
                          {
                            userSignature.defaultYn == 'Y' ?
                            <Link to="#" onClick={() => {setPreviewSignatureInfo(userSignature)}}>
                              <i className="uil uil-file-search-alt" id={"sigPreview_"+index} style={{color:"#5c8dc6", fontSize:"18px"}}></i>
                              <UncontrolledTooltip placement="top" target={"sigPreview_"+index}>
                              Your default signature is automatically updated when you update your WeCrest account information
                              </UncontrolledTooltip>
                            </Link>
                            :
                            <>
                            <Link to="#" onClick={() => {openSigEditor(userSignature)}}>
                              <i className="uil-envelope-edit" id={"sigEditIco"+index} style={{color:"#5c8dc6", fontSize:"18px"}}></i>
                              <UncontrolledTooltip placement="top" target={"sigEditIco"+index}>
                                Edit signature
                              </UncontrolledTooltip>
                            </Link>
                            <Link to="#" onClick={() => {setPreviewSignatureInfo(userSignature)}}>
                              <i className="uil uil-file-search-alt" id={"sigPreview_"+index} style={{color:"#5c8dc6", fontSize:"18px"}}></i>
                              <UncontrolledTooltip placement="top" target={"sigPreview_"+index}>
                                Preview signature
                              </UncontrolledTooltip>
                            </Link>
                            <Link to="#" onClick={() => {setDeleteSignatureInfo(userSignature)}}>
                              <i className="uil-trash-alt" id={"sigDeleteIco"+index} style={{color:"red", fontSize:"18px"}}
                                onClick={() => {
                                  setDeleteSignatureInfo(userSignature)
                                }}></i>
                              <UncontrolledTooltip placement="top" target={"sigDeleteIco"+index}>
                              Delete signature
                              </UncontrolledTooltip>
                            </Link>
                            </>
                          }
                        </div>
                      </Row>
                    )
                  })
                  :
                  null
                }
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="outline-primary"
                    className="w-md"
                    onClick={()=>{openSigEditor({seq:'-1', sigName:'', sigForm: ''})}}
                  >
                    Create new
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>

          {deleteSignatureInfo.seq != '-1' ? (
            <SweetAlert
              title="Warning!"
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                dispatch(deleteUserSignature(deleteSignatureInfo));
                setDeleteSignatureInfo({seq:'-1'});
                if (isInitSigEditor) {
                  setIsInitSigEditor(false);
                }
              }}
              onCancel={() => setDeleteSignatureInfo({seq:'-1'})}
            >
              If this is your default formatted email signature for any of your jurisdictions or Filer Rankings, you will need to select a new default formatted email signature.
            </SweetAlert>
          ) : null}

          {previewSignatureInfo && Object.keys(previewSignatureInfo).length !== 0 ? (
            <Modal
              size="xl"
              isOpen={true}
              toggle={() => {
                setPreviewSignatureInfo({});
              }}
            >
              <div className="modal-header">
                <h5
                  className="modal-title mt-0"
                  id="myExtraLargeModalLabel"
                >
                  {previewSignatureInfo.sigName ? previewSignatureInfo.sigName : null}
                </h5>
                <button
                  onClick={() => {
                    setPreviewSignatureInfo({});
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Card className="mb-2">
                  <CardBody dangerouslySetInnerHTML={{__html:previewSignatureInfo.sigForm ? previewSignatureInfo.sigForm : null}}></CardBody>
                </Card>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setPreviewSignatureInfo({});
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Modal>
          ) : null}

          <SignatureEditorModal isOpenSigEditor={isOpenSigEditor} isInitSigEditor={isInitSigEditor} setIsInitSigEditor={setIsInitSigEditor} closeSigEditor={closeSigEditor} loadedSigEditor={loadedSigEditor} setLoadedSigEditor={setLoadedSigEditor} sigEditorModal={sigEditorModal}/>

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4" id="subscriptionSettings">Jurisdiction defaults</CardTitle>
                <div className="table-responsive">
                  <Table className="table mb-0" style={{borderTopColor: "transparent"}}>
                    <thead>
                      <tr>
                        <th style={{textAlign: "center"}}>Tool</th>
                        <th style={{textAlign: "center"}}>Jurisdiction</th>
                        <th style={{textAlign: "center"}}>Email template</th>
                        <th style={{textAlign: "center"}}>Sender email</th>
                        <th style={{textAlign: "center"}}>Email signature</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        userJurisdictionEmailSettingList && userJurisdictionEmailSettingList.length ?
                        userJurisdictionEmailSettingList.map((jurisdictionEmailSetting, index) => {
                          return (
                            jurisdictionEmailSetting.crCode == '00' ? 
                              <tr key={index}>
                                <td rowspan={jurisdictionEmailSetting.serviceName == 'MadridNotifier' || jurisdictionEmailSetting.serviceName == 'HagueNotifier' ? jurisdictionEmailSetting.rowspan : 1}>{jurisdictionEmailSetting.serviceName == 'MadridNotifier' ? "Madrid Notifier" : jurisdictionEmailSetting.serviceName == 'HagueNotifier' ? "Hague Notifier" : "Filer Rankings"} {jurisdictionEmailSetting.inOutType == 'IN' ? "(Inbound)" : "(Outbound)"}</td>
                                <td>Default</td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.emailFormIdx && jurisdictionEmailSetting.emailFormIdx != null && jurisdictionEmailSetting.emailFormIdx != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change email template?',
                                          type: 'update',
                                          emailFormIdx: e.target.value,
                                          senderEmailSeq: jurisdictionEmailSetting.senderEmailSeq,
                                          signatureSeq: jurisdictionEmailSetting.signatureSeq,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.emailFormIdx}
                                  >
                                    <option value=''>Please designate a template.</option>
                                  {
                                    emailTemplates && emailTemplates.length ? 
                                    emailTemplates.map((emailTemplate, index) => {
                                      return (
                                        <option value={emailTemplate.idx}>{emailTemplate.tmpTitle}</option>
                                      )
                                    })
                                    :
                                    null
                                  }
                                  </select>
                                  {
                                    (jurisdictionEmailSetting.serviceName == 'MadridNotifier'
                                    && jurisdictionEmailSetting.rowspan == 1 
                                    && ((jurisdictionEmailSetting.inOutType == 'IN' && jrAddButtonFlag) || (jurisdictionEmailSetting.inOutType == 'OUT' && crAddButtonFlag)))
                                    ||
                                    (jurisdictionEmailSetting.serviceName == 'HagueNotifier'
                                    && jurisdictionEmailSetting.rowspan == 1 
                                    && ((jurisdictionEmailSetting.inOutType == 'IN' && hagueJrAddButtonFlag) || (jurisdictionEmailSetting.inOutType == 'OUT' && hagueCrAddButtonFlag)))
                                    ?
                                    <>
                                      <br/>
                                      <Button
                                        type="button"
                                        color="outline-primary"
                                        className="w-md"
                                        onClick={()=>{
                                            setServiceName(jurisdictionEmailSetting.serviceName);
                                            // console.log(jurisdictionEmailSetting.serviceName + ' / ' + jurisdictionEmailSetting.inOutType + ' Add exception!');
                                            if (jurisdictionEmailSetting.inOutType == 'IN') {
                                              if (jurisdictionEmailSetting.serviceName == 'MadridNotifier') {
                                                setJurOpen(true);
                                              } else if (jurisdictionEmailSetting.serviceName == 'HagueNotifier') {
                                                setHagueJurOpen(true);
                                              }
                                            } else {
                                              if (jurisdictionEmailSetting.serviceName == 'MadridNotifier') {
                                                setCountryOpen(true);
                                              } else if (jurisdictionEmailSetting.serviceName == 'HagueNotifier') {
                                                setHagueCountryOpen(true);
                                              }
                                            }
                                        }}
                                      >
                                        Add exception
                                      </Button>
                                    </>
                                    : null
                                  }
                                </td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.senderEmailSeq && jurisdictionEmailSetting.senderEmailSeq != null && jurisdictionEmailSetting.senderEmailSeq != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change sender email?',
                                          type: 'update',
                                          emailFormIdx: jurisdictionEmailSetting.emailFormIdx,
                                          senderEmailSeq: e.target.value,
                                          signatureSeq: jurisdictionEmailSetting.signatureSeq,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.senderEmailSeq}
                                  >
                                    <option value=''>Please designate a email.</option>
                                  {
                                    userSendEmailAddressList && userSendEmailAddressList.length ? 
                                    userSendEmailAddressList.map((userSendEmailAddress, index) => {
                                      return (
                                        userSendEmailAddress.confirmStatus == 'Confirmed' ? <option value={userSendEmailAddress.seq}>{userSendEmailAddress.emailAddress}</option> : null
                                      )
                                    })
                                    :
                                    null
                                  }
                                  </select>
                                </td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.signatureSeq && jurisdictionEmailSetting.signatureSeq != null && jurisdictionEmailSetting.signatureSeq != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change email signature?',
                                          type: 'update',
                                          emailFormIdx: jurisdictionEmailSetting.emailFormIdx,
                                          senderEmailSeq: jurisdictionEmailSetting.senderEmailSeq,
                                          signatureSeq: e.target.value,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.signatureSeq}
                                  >
                                    <option value=''>Please designate a signature.</option>
                                  {
                                    userSignatureList && userSignatureList.length ? 
                                    userSignatureList.map((userSignature, index) => {
                                      return (
                                        <option value={userSignature.seq}>{userSignature.sigName}</option>
                                      )
                                    })
                                    :
                                    null
                                  }
                                  </select>
                                </td>
                              </tr>
                            : 
                              <tr key={index}>
                                <td>
                                  <Row>
                                  <div className="col-md-10">
                                    [{jurisdictionEmailSetting.crCode} {jurisdictionEmailSetting.crName}]
                                  </div>
                                  <div className="col-md-2">
                                    <Link to="#" 
                                          onClick={() => {
                                            dispatch(
                                              setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                              ,{
                                                title: '[' + jurisdictionEmailSetting.crCode + ' ' + jurisdictionEmailSetting.crName + '] Delete exception jurisdiction?',
                                                type: 'delete',
                                                userNo: jurisdictionEmailSetting.userNo,
                                                serviceName: jurisdictionEmailSetting.serviceName,
                                                inOutType: jurisdictionEmailSetting.inOutType,
                                                crCode: jurisdictionEmailSetting.crCode,
                                              }));
                                          }}
                                    >
                                      <i className="uil-trash-alt" id={"deleteJurIco"+index} style={{color:"red", fontSize:"18px"}}></i>
                                      <UncontrolledTooltip placement="top" target={"deleteJurIco"+index}>
                                      Delete exception jurisdiction
                                      </UncontrolledTooltip>
                                    </Link>
                                  </div>
                                  </Row>
                                </td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.emailFormIdx && jurisdictionEmailSetting.emailFormIdx != null && jurisdictionEmailSetting.emailFormIdx != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change email template?',
                                          type: 'update',
                                          emailFormIdx: e.target.value,
                                          senderEmailSeq: jurisdictionEmailSetting.senderEmailSeq,
                                          signatureSeq: jurisdictionEmailSetting.signatureSeq,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.emailFormIdx}
                                  >
                                    <option value=''>Please designate a template.</option>
                                    {
                                    emailTemplates && emailTemplates.length ? 
                                    emailTemplates.map((emailTemplate, index) => {
                                      return (
                                        <option value={emailTemplate.idx} tmpTitle={emailTemplate.tmpTitle}>{emailTemplate.tmpTitle}</option>
                                      )
                                    })
                                    :
                                    null
                                    }
                                  </select>
                                  {
                                    (jurisdictionEmailSetting.serviceName == 'MadridNotifier'
                                    && jurisdictionEmailSetting.inOutType != userJurisdictionEmailSettingList[index+1].inOutType && jurisdictionEmailSetting.rowspan > 1
                                    && ((jurisdictionEmailSetting.inOutType == 'IN' && jrAddButtonFlag) || (jurisdictionEmailSetting.inOutType == 'OUT' && crAddButtonFlag)))
                                    ||
                                    (jurisdictionEmailSetting.serviceName == 'HagueNotifier'
                                    && jurisdictionEmailSetting.inOutType != userJurisdictionEmailSettingList[index+1].inOutType && jurisdictionEmailSetting.rowspan > 1
                                    && ((jurisdictionEmailSetting.inOutType == 'IN' && hagueJrAddButtonFlag) || (jurisdictionEmailSetting.inOutType == 'OUT' && hagueCrAddButtonFlag)))
                                    ?
                                    <>
                                      <br/>
                                      <Button
                                        type="button"
                                        color="outline-primary"
                                        className="w-md"
                                        onClick={()=>{
                                            setServiceName(jurisdictionEmailSetting.serviceName);
                                            // console.log(jurisdictionEmailSetting.serviceName + ' / ' + jurisdictionEmailSetting.inOutType + ' Add exception!');
                                            if (jurisdictionEmailSetting.inOutType == 'IN') {
                                              if (jurisdictionEmailSetting.serviceName == 'MadridNotifier') {
                                                setJurOpen(true);
                                              } else if (jurisdictionEmailSetting.serviceName == 'HagueNotifier') {
                                                setHagueJurOpen(true);
                                              }
                                            } else {
                                              if (jurisdictionEmailSetting.serviceName == 'MadridNotifier') {
                                                setCountryOpen(true);
                                              } else if (jurisdictionEmailSetting.serviceName == 'HagueNotifier') {
                                                setHagueCountryOpen(true);
                                              }
                                            }
                                        }}
                                      >
                                        Add exception
                                      </Button>
                                    </>
                                    : null
                                  }
                                </td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.senderEmailSeq && jurisdictionEmailSetting.senderEmailSeq != null && jurisdictionEmailSetting.senderEmailSeq != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change sender email?',
                                          type: 'update',
                                          emailFormIdx: jurisdictionEmailSetting.emailFormIdx,
                                          senderEmailSeq: e.target.value,
                                          signatureSeq: jurisdictionEmailSetting.signatureSeq,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.senderEmailSeq}
                                  >
                                    <option value=''>Please designate a email.</option>
                                  {
                                    userSendEmailAddressList && userSendEmailAddressList.length ? 
                                    userSendEmailAddressList.map((userSendEmailAddress, index) => {
                                      return (
                                        userSendEmailAddress.confirmStatus == 'Confirmed' ? <option value={userSendEmailAddress.seq}>{userSendEmailAddress.emailAddress}</option> : null
                                      )
                                    })
                                    :
                                    null
                                  }
                                  </select>
                                </td>
                                <td>
                                  <select 
                                    className={jurisdictionEmailSetting && jurisdictionEmailSetting.signatureSeq && jurisdictionEmailSetting.signatureSeq != null && jurisdictionEmailSetting.signatureSeq != '' ? "form-select select-option-default" :  "form-select select-option-null"}
                                    name="entryCnt"
                                    onChange={(e) => {
                                      dispatch(
                                        setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO'
                                        ,{
                                          title: 'Change email signature?',
                                          type: 'update',
                                          emailFormIdx: jurisdictionEmailSetting.emailFormIdx,
                                          senderEmailSeq: jurisdictionEmailSetting.senderEmailSeq,
                                          signatureSeq: e.target.value,
                                          userNo: jurisdictionEmailSetting.userNo,
                                          serviceName: jurisdictionEmailSetting.serviceName,
                                          inOutType: jurisdictionEmailSetting.inOutType,
                                          crCode: jurisdictionEmailSetting.crCode,
                                        }));
                                    }}
                                    value={jurisdictionEmailSetting.signatureSeq}
                                  >
                                    <option value=''>Please designate a signature.</option>
                                  {
                                    userSignatureList && userSignatureList.length ? 
                                    userSignatureList.map((userSignature, index) => {
                                      return (
                                        <option value={userSignature.seq}>{userSignature.sigName}</option>
                                      )
                                    })
                                    :
                                    null
                                  }
                                  </select>
                                </td>
                              </tr>
                          )
                        })
                        :
                        null
                      }
                      
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>

          {updateUserJurisdictionEmailSettingInfo.title && updateUserJurisdictionEmailSettingInfo.title != ''? (
            <SweetAlert
              title={updateUserJurisdictionEmailSettingInfo.title}
              warning
              showCancel
              confirmButtonText="Confirm"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                if (updateUserJurisdictionEmailSettingInfo.type == 'update') {
                  dispatch(updateUserJurisdictionEmailSetting(updateUserJurisdictionEmailSettingInfo));
                } else {
                  dispatch(deleteUserJurisdictionEmailSetting(updateUserJurisdictionEmailSettingInfo));
                }
              }}
              onCancel={() => dispatch(setSettingsReducerValue('UPDATE_USER_JURISDICTION_EMAIL_SETTING_INFO', {}))}
            >
            </SweetAlert>
          ) : null}

          <SelectJurisdictionsSettings formik={filterValidation} jurOpen={jurOpen} setJurOpen={setJurOpen} jrCdList={jrCdList} setJrAddButtonFlag={setJrAddButtonFlag} serviceName={serviceName}/>
          <SelectCountrySettings formik={filterValidation} countryOpen={countryOpen} setCountryOpen={setCountryOpen} crCdList={crCdList} setCrAddButtonFlag={setCrAddButtonFlag} serviceName={serviceName}/>

          <SelectJurisdictionsSettings formik={filterValidation} jurOpen={hagueJurOpen} setJurOpen={setHagueJurOpen} jrCdList={hagueJrCdList} setJrAddButtonFlag={setHagueJrAddButtonFlag} serviceName={serviceName}/>
          <SelectCountrySettings formik={filterValidation} countryOpen={hagueCountryOpen} setCountryOpen={setHagueCountryOpen} crCdList={hagueCrCdList} setCrAddButtonFlag={setHagueCrAddButtonFlag} serviceName={serviceName}/>
          
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">One-Click Madrid Notifier</CardTitle>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Show One-Click Madrid Notifier on startup?
                  </label>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="showStartup"
                      id="showStartupY"
                      value="Y"
                      checked={filterValidation.values.showStartup === 'Y'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showStartupY"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="showStartup"
                      id="showStartupM"
                      value="M"
                      checked={filterValidation.values.showStartup === 'M'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showStartupM"
                    >
                      Don’t show again for one month
                    </label>
                  </div>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="showStartup"
                      id="showStartupF"
                      value="F"
                      checked={filterValidation.values.showStartup === 'F'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showStartupF"
                    >
                      Don’t show again forever
                    </label>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Ask for confirmation before sending email?
                  </label>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ocmnAutoSendYn"
                      id="ocmnAutoSendN"
                      value="N"
                      checked={filterValidation.values.ocmnAutoSendYn === 'N'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="ocmnAutoSendN"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ocmnAutoSendYn"
                      id="ocmnAutoSendM"
                      value="M"
                      checked={filterValidation.values.ocmnAutoSendYn === 'M'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="ocmnAutoSendM"
                    >
                      Don’t show again for one month
                    </label>
                  </div>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ocmnAutoSendYn"
                      id="ocmnAutoSendY"
                      value="Y"
                      checked={filterValidation.values.ocmnAutoSendYn === 'Y'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="ocmnAutoSendY"
                    >
                      Don’t show again forever
                    </label>
                  </div>
                </Row>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("submitType", "updateOcmnSettings");
                      filterValidation.handleSubmit(filterValidation.values);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    outline
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("showStartup", userOneClickMnSetting.showStartup);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">Unsubscribe settings</CardTitle>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                    Include unsubscribe option in all emails
                  </label>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="unsubscribeEmailYn"
                      id="includeUnsubscribe"
                      value="Y"
                      checked={filterValidation.values.unsubscribeEmailYn === 'Y'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="includeUnsubscribe"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="unsubscribeEmailYn"
                      id="notIncludeUnsubscribe"
                      value="N"
                      checked={filterValidation.values.unsubscribeEmailYn === 'N'}
                      onChange={filterValidation.handleChange} 
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notIncludeUnsubscribe"
                    >
                      No
                    </label>
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Unsubscribe description (optional)
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Unsubscribe description"
                      name="unsubscribeDescription"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.unsubscribeDescription}
                    />
                  </div>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3 col-form-label">
                  Unsubscribe link text (optional)
                  </label>
                  <div className="col-md-4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Unsubscribe link text"
                      name="unsubscribeLinkText"
                      onChange={filterValidation.handleChange}
                      value={filterValidation.values.unsubscribeLinkText}
                    />
                  </div>
                </Row>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("submitType", "updateAccountUnsubscribeInfo");
                      filterValidation.handleSubmit(filterValidation.values);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    outline
                    className="w-md"
                    onClick={()=>{
                      filterValidation.setFieldValue("unsubscribeEmailYn", accountInfo.unsubscribeEmailYn);
                      filterValidation.setFieldValue("unsubscribeDescription", accountInfo.unsubscribeDescription);
                      filterValidation.setFieldValue("unsubscribeLinkText", accountInfo.unsubscribeLinkText);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Row>
          </Form>

          {
            success ? 
            <SweetAlert
              title={success}
              timeout={success == 'Success' ? 2000 : 4000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(setSettingsReducerValue('SUCCESS', ""));
              }}
            ></SweetAlert>
            : null
          }

          {errorMsg !== '' ? (
            <SweetAlert
              warning
              onConfirm={() => {
                dispatch(setSettingsReducerValue('ERROR_MSG', ""));
              }}
              onCancel={() => {
                dispatch(setSettingsReducerValue('ERROR_MSG', ""));
              }}
            >{errorMsg}
            </SweetAlert>
            ) 
          : null}

          <LoadingModal loading={loading} setLoading={closeLoding}></LoadingModal>

        </div>
      </div>
    </React.Fragment>
  )
}

export default EmailingSettings;