import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import classnames from "classnames"

import {
  getSubscriptionItemList,
  changeSubscriptionItemList,
  saveSubscriptionItem,
  deleteSubscriptionItem,
  setAdmPricingManagementLoading,
  setAdmPricingManagementSuccess,
  setAdmPricingManagementError,
  setIsReload,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
import dateFormat from 'dateformat';

import '../../components/Common/Common.css';

const AdmPricingManagement = props => {
  const dispatch = useDispatch();

  const { loading, error, success, subscriptionItemList, totalPage, isReload, username} = useSelector(state => ({
    loading: state.AdmPricingManagement.loading,
    error: state.AdmPricingManagement.error,
    success: state.AdmPricingManagement.success,
    subscriptionItemList: state.AdmPricingManagement.subscriptionItemList,
    totalPage: state.AdmPricingManagement.totalPage,
    isReload: state.AdmPricingManagement.isReload,
    username: state.Profile.username,
  }));

  const [curPageInput, setCurPageInput] = useState(1);
  const [newItem, setNewItem] = useState({"modFlag":"I","type":"BDC","item":"","m1":"","m3":"","m6":"","m12":""});

  const viewSubscriptionItemList = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }

    if(perPage){
      filterValidation.setFieldValue("perPage", perPage);
    }

    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      sortType: "",
      perPage: 20,
      curPage: 1,
    },
    
    onSubmit: (values) => {
      const filterParam = {
        perPage: values["perPage"],
        curPage: values["curPage"],
        sortType: values["sortType"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getSubscriptionItemList(filterParam));
      }
    }
  })

  const setAdmDomainLoading = () => {
    dispatch(setAdmPricingManagementLoading(!loading));
  }

  const changeSubscriptionItem = (index, attr, value) => {
    let _subscriptionItemList = cloneDeep(subscriptionItemList);
    _subscriptionItemList[index][attr] = value;
    
    dispatch(changeSubscriptionItemList(_subscriptionItemList));
  }

  const changeNewItem = (attr, value) => {
    let _newItem = cloneDeep(newItem);
    _newItem[attr] = value;
    
    setNewItem(_newItem);
  }

  useEffect(()=> {
    if (isReload) {
      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values);
      dispatch(setIsReload(!isReload));
    }
  },[isReload]);

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-dollar-alt text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Pricing management</h5>
                        </div>
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                      </Media>
                    </div>
                  </Link>
                  {username === 'admin' ?
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <div>
                        <Row className="mb-3">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{textAlign: "center"}}>Item type</th>
                              <th style={{textAlign: "center"}}>Item</th>
                              <th style={{textAlign: "center"}}>1 month</th>
                              <th style={{textAlign: "center"}}>3 months</th>
                              <th style={{textAlign: "center"}}>6 months</th>
                              <th style={{textAlign: "center"}} colSpan={2}>12 months</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr >
                                <td align="center">
                                  <select 
                                    className="form-select" 
                                    name="entryCnt"
                                    onChange={(e) => {changeNewItem("type", e.target.value)}}
                                    value={newItem.type}
                                  >
                                    <option value="BDC">WeCrest Executive</option>
                                    <option value="UC">Unlock credits</option>
                                  </select>
                                </td>
                                <td align="center">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter item"
                                    maxLength={10}
                                    onChange={(e) => {changeNewItem("item", e.target.value)}}
                                    value={newItem.item}
                                  />
                              
                                </td>
                                <td align="center">
                                  <Input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter 1 month"
                                      onChange={(e) => {changeNewItem("m1", e.target.value)}}
                                      value={newItem.m1}
                                  />
                                </td>
                                <td align="center">
                                  <Input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter 3 months"
                                      onChange={(e) => {changeNewItem("m3", e.target.value)}}
                                      value={newItem.m3}
                                  />
                                </td>
                                <td align="center">
                                  <Input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter 6 months"
                                      onChange={(e) => {changeNewItem("m6", e.target.value)}}
                                      value={newItem.m6}
                                  />
                                </td>
                                <td align="center">
                                  <Input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter 12 months"
                                      onChange={(e) => {changeNewItem("m12", e.target.value)}}
                                      value={newItem.m12}
                                  />
                                </td>
                                <td style={{verticalAlign: "middle", textAlign: "center"}}>
                                  <Link to="#" onClick={(e) => {
                                    let _newItem = cloneDeep(newItem);
                                    dispatch(saveSubscriptionItem(_newItem, true));
                                    setNewItem({"modFlag":"I","type":"BDC","item":"","m1":"","m3":"","m6":"","m12":""});
                                  }}>
                                    <i className="bx bx-save font-size-22" id={"saveSubscriptionItem"}></i>
                                    <UncontrolledTooltip placement="top" target={"saveSubscriptionItem"}>
                                    Save item
                                    </UncontrolledTooltip>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                  : null
                  }
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                          <th style={{textAlign: "center"}}>Item type</th>
                          <th style={{textAlign: "center"}}>Item</th>
                          <th style={{textAlign: "center"}}>1 month</th>
                          <th style={{textAlign: "center"}}>3 months</th>
                          <th style={{textAlign: "center"}}>6 months</th>
                          <th style={{textAlign: "center"}} colSpan={2}>12 months ㅅㄷㄴㅅ</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          subscriptionItemList && subscriptionItemList.length && subscriptionItemList.map((subscriptionItem, index) => {
                            return (
                                <tr key={index}>
                                  <td align="center">
                                    {
                                    subscriptionItem.type === 'JN'? 'WeCrest Madrid subscription'
                                     : subscriptionItem.type === 'HN'? 'WeCrest Hague subscription'
                                     : subscriptionItem.type === 'UC'? 'Unlock credits'
                                     : "WeCrest Executive"
                                    }
                                  </td>
                                  <td align="center">
                                    {subscriptionItem.item}
                                  </td>
                                  <td align="center">
                                    {
                                    username === 'admin' ?
                                      <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter 1 month"
                                        onChange={(e) => {
                                          changeSubscriptionItem(index, "m1", e.target.value)
                                        }}
                                        onBlur={(e) => { 
                                          dispatch(saveSubscriptionItem(subscriptionItemList[index], false));
                                        }}
                                        value={subscriptionItem.m1}
                                    />
                                    : subscriptionItem.m1
                                    }
                                  </td>
                                  <td align="center">
                                    {
                                    username === 'admin' ?
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter 3 months"
                                        onChange={(e) => {
                                          changeSubscriptionItem(index, "m3", e.target.value)
                                        }}
                                        onBlur={(e) => { 
                                          dispatch(saveSubscriptionItem(subscriptionItemList[index], false));
                                        }}
                                        value={subscriptionItem.m3}
                                    />
                                    : subscriptionItem.m3
                                    }
                                  </td>
                                  <td align="center">
                                    {
                                    username === 'admin' ?
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter 6 months"
                                        onChange={(e) => {
                                          changeSubscriptionItem(index, "m6", e.target.value)
                                        }}
                                        onBlur={(e) => { 
                                          dispatch(saveSubscriptionItem(subscriptionItemList[index], false));
                                        }}
                                        value={subscriptionItem.m6}
                                    />
                                    : subscriptionItem.m6
                                    }
                                  </td>
                                  <td align="center">
                                    {
                                    username === 'admin' ?
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter 12 months"
                                        onChange={(e) => {
                                          changeSubscriptionItem(index, "m12", e.target.value)
                                        }}
                                        onBlur={(e) => { 
                                          dispatch(saveSubscriptionItem(subscriptionItemList[index], false));
                                        }}
                                        value={subscriptionItem.m12}
                                    />
                                    : subscriptionItem.m12
                                    }
                                  </td>
                                  <td align="center">
                                    {
                                      username === 'admin' && subscriptionItem.type !== 'JN' ?
                                      <Link to="#" onClick={() => {
                                        dispatch(deleteSubscriptionItem(subscriptionItemList[index]));
                                        }}
                                      >
                                        <i className="uil-trash-alt" id={"deleteManualIco_"+index} style={{color:"red", fontSize:"18px"}}></i>
                                        <UncontrolledTooltip placement="top" target={"deleteManualIco_"+index}>
                                        Delete item
                                        </UncontrolledTooltip>
                                      </Link>
                                      : null
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center gap-2">
                        <label
                          htmlFor="perPage"
                          >
                          Entries per page
                        </label>
                        <div style={{width: "100px"}}>
                          <select 
                            className="form-select" 
                            name="perPage"
                            onChange={e => {viewSubscriptionItemList(null, 1, e.target.value);}}
                            value={filterValidation.values.perPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-1">
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewSubscriptionItemList(null, 1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewSubscriptionItemList(null, Number(filterValidation.values.curPage)-1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<"}
                          </Button>
                        </div>
                        Page{" "}
                        <strong>
                          {filterValidation.values.curPage} of {totalPage}
                        </strong>
                        
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 70 }}
                          max={totalPage}
                          name="curPage"
                          defaultValue={filterValidation.values.curPage}
                          value={curPageInput}
                          onChange={(e) => { setCurPageInput(e.target.value)}}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              viewSubscriptionItemList(null, e.target.value);
                            }                      
                          }}
                        />
                        <div className="d-flex gap-1">
                          <Button 
                            type="button"
                            color="primary"
                            onClick={()=>{viewSubscriptionItemList(null, Number(filterValidation.values.curPage)+1);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewSubscriptionItemList(null, totalPage);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">>"}
                          </Button>
                        </div>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setAdmDomainLoading}></LoadingModal>
              : null
            }

            {
              success ? 
              <SweetAlert
                title={success}
                timeout={2000}
                showCloseButton={false}
                showConfirm={false}
                success
                onConfirm={() => {
                  dispatch(setAdmPricingManagementSuccess(""));
                }}
              ></SweetAlert>
              : null
            }

            {
              error ?
              <SweetAlert
                warning
                title={error}
                onConfirm={() => {
                  dispatch(setAdmPricingManagementError(""));
                }}
              >
              </SweetAlert>
              : null
            }

          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default AdmPricingManagement;