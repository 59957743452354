import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
// import calendar from "./calendar/reducer"

//E-commerce
// import ecommerce from "./e-commerce/reducer"

//chat
// import chat from "./chat/reducer"

//invoices
// import invoices from "./invoices/reducer"

//contacts
import Contacts from "./contacts/reducer"

//users
import User from "./user/reducer"

//users
import Dashboard from "./dashboard/reducer"

//generalStatistics
import GeneralStatistics from "./generalStatistics/reducer"

//multipleJurisdictions
import MultipleJurisdictions from "./multipleJurisdictions/reducer"

//multipleJurisdictions
import MultipleCountrys from "./multipleCountrys/reducer"

//CountryRankings
import CountryRankings from "./countryRankings/reducer"

//SpecificSearch
import SpecificSearch from "./specificSearch/reducer"

//editor
import Editor from "./editor/reducer"

//FilerRankings
import FilerRankings from "./filerRankings/reducer"

//Settings
import Settings from "./settings/reducer"

//MadridNotifier
import MadridNotifier from "./madridNotifier/reducer"

//Unlock
import Unlock from "./unlock/reducer"

//sendingAnalytics
import SendingAnalytics from "./sendingAnalytics/reducer"

//AdmDomainManagement
import AdmDomainManagement from "./admDomainManagement/reducer"

//AdmDomainManagement
import MembershipPlan from "./membershipPlan/reducer"

//ServiceManual
import ServiceManual from "./serviceManual/reducer"

//AdmPricingManagement
import AdmPricingManagement from "./admPricingManagement/reducer"

//AdmInvoiceManagement
import AdmInvoiceManagement from "./admInvoiceManagement/reducer"

//Community
import Community from "./community/reducer"

//EntityConnectManagement
import EntityConnectManagement from "./entityConnectManagement/reducer"

//AdmAccountManagement
import AdmAccountManagement from "./admAccountManagement/reducer"

//MyCaseManagement
import MyCaseManagement from "./myCaseManagement/reducer"

//Hague Notifier
import hagueNotifier from "./haguenotifier/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  // calendar,
  // chat,
  // ecommerce,
  // invoices,
  Contacts,
  User,
  Dashboard,
  GeneralStatistics,
  MultipleJurisdictions,
  MultipleCountrys,
  CountryRankings,
  SpecificSearch,
  Editor,
  FilerRankings,
  Settings,
  MadridNotifier,
  Unlock,
  SendingAnalytics,
  AdmDomainManagement,
  MembershipPlan,
  ServiceManual,
  AdmPricingManagement,
  AdmInvoiceManagement,
  Community,
  EntityConnectManagement,
  AdmAccountManagement,
  MyCaseManagement,
  hagueNotifier
})

export default rootReducer
