export const GET_MADRID_SETTINGS = "GET_MADRID_SETTINGS"
export const GET_MADRID_SETTINGS_SUCCESS = "GET_MADRID_SETTINGS_SUCCESS"
export const SAVE_MADRID_SETTINGS = "SAVE_MADRID_SETTINGS"
export const SAVE_MADRID_SETTINGS_SUCCESS = "SAVE_MADRID_SETTINGS_SUCCESS"
export const SET_SUCCESS = "SET_SUCCESS"
export const SET_RESULT = "SET_RESULT"
export const GET_MADRID_NOTIFIER = "GET_MADRID_NOTIFIER"
export const GET_MADRID_NOTIFIER_SUCCESS = "GET_MADRID_NOTIFIER_SUCCESS"
export const GET_MADRID_NOTIFIER_INFO = "GET_MADRID_NOTIFIER_INFO"
export const GET_MADRID_NOTIFIER_INFO_SUCCESS = "GET_MADRID_NOTIFIER_INFO_SUCCESS"
export const GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK = "GET_MADRID_NOTIFIER_INFO_SUCCESS_UNLOCK"
export const CHANGE_MADRID_NOTIFIER = "CHANGE_MADRID_NOTIFIER"
export const MULTIPLE_HIDE_MADRID = "MULTIPLE_HIDE_MADRID"
export const MULTIPLE_UNHIDE_MADRID = "MULTIPLE_UNHIDE_MADRID"
export const MULTIPLE_STAR_MADRID = "MULTIPLE_STAR_MADRID"
export const MULTIPLE_UNSTAR_MADRID = "MULTIPLE_UNSTAR_MADRID"
export const DOWN_MADRID_EXCEL = "DOWN_MADRID_EXCEL"
export const DOWN_MADRID_EXCEL_SUCCESS = "DOWN_MADRID_EXCEL_SUCCESS"
export const DOWN_MULTIPLE_MADRID_EXCEL = "DOWN_MULTIPLE_MADRID_EXCEL"
export const DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS = "DOWN_MULTIPLE_MADRID_EXCEL_SUCCESS"
export const DOWN_MADRID_PDF = "DOWN_MADRID_PDF"
export const DOWN_MADRID_PDF_SUCCESS = "DOWN_MADRID_PDF_SUCCESS"
export const DOWN_MULTIPLE_MADRID_PDF = "DOWN_MULTIPLE_MADRID_PDF"
export const DOWN_MULTIPLE_MADRID_PDF_SUCCESS = "DOWN_MULTIPLE_MADRID_PDF_SUCCESS"
export const SEND_MADRID_EMAIL = "SEND_MADRID_EMAIL"
export const PREVIEW_MADRID_EMAIL = "PREVIEW_MADRID_EMAIL"
export const PREVIEW_MADRID_EMAIL_SUCCESS = "PREVIEW_MADRID_EMAIL_SUCCESS"
export const SET_PREVIEW_EMAIL = "SET_PREVIEW_EMAIL"
export const CLEAR_MADRID_NOTIFIER = "CLEAR_MADRID_NOTIFIER"
export const UNLOCK_MADRID = "UNLOCK_MADRID"
export const UNLOCK_MADRID_SUCCESS = "UNLOCK_MADRID_SUCCESS"
export const SET_MADRID_SETTINGS = "SET_MADRID_SETTINGS"
export const SET_MADRID_LOADING = "SET_MADRID_LOADING"
export const STAR_TARGET = "STAR_TARGET"
export const UN_STAR_TARGET = "UN_STAR_TARGET"
export const HIDE_TARGET = "HIDE_TARGET"
export const UN_HIDE_TARGET = "UN_HIDE_TARGET"
export const SET_UPGRADE_ALERT_MESSAGE = "SET_UPGRADE_ALERT_MESSAGE"
export const SET_MADRID_SETTINGS_DRU = "SET_MADRID_SETTINGS_DRU"
export const SET_IS_DRU_SEARCH = "SET_IS_DRU_SEARCH"
export const CHANGE_SENT_RESULT = "CHANGE_SENT_RESULT"
export const SET_SENT_SUCCESS = "SET_SENT_SUCCESS"
export const GET_OCMN_LIST = "GET_OCMN_LIST"
export const GET_OCMN_LIST_SUCCESS = "GET_OCMN_LIST_SUCCESS"
export const SAVE_OCMN_SHOW_STARTUP = "SAVE_OCMN_SHOW_STARTUP"
export const SAVE_OCMN_SHOW_STARTUP_SUCCESS = "SAVE_OCMN_SHOW_STARTUP_SUCCESS"
export const SAVE_OCMN_AUTO_SEND_YN = "SAVE_OCMN_AUTO_SEND_YN"
export const SAVE_OCMN_AUTO_SEND_YN_SUCCESS = "SAVE_OCMN_AUTO_SEND_YN_SUCCESS"
export const GET_OCMN_SUGGESTED_CONTACTS = "GET_OCMN_SUGGESTED_CONTACTS"
export const GET_OCMN_SUGGESTED_CONTACTS_SUCCESS = "GET_OCMN_SUGGESTED_CONTACTS_SUCCESS"
export const SEND_OCMN_EMAIL = "SEND_OCMN_EMAIL"
export const SEND_OCMN_EMAIL_SUCCESS = "SEND_OCMN_EMAIL_SUCCESS"
export const SET_OCMN_OPEN = "SET_OCMN_OPEN"
export const SET_OCMN_LOADING = "SET_OCMN_LOADING"
export const SET_OCMN_INDEX = "SET_OCMN_INDEX"
export const OCMN_PREVIEW_MADRID_EMAIL = "OCMN_PREVIEW_MADRID_EMAIL"
export const OCMN_PREVIEW_MADRID_EMAIL_SUCCESS = "OCMN_PREVIEW_MADRID_EMAIL_SUCCESS"
export const SET_OCMN_PREVIEW_EMAIL = "SET_OCMN_PREVIEW_EMAIL"
export const UNLOCK_OCMN = "UNLOCK_OCMN"
export const CHANGE_OCMN_LIST_UNLOCK = "CHANGE_OCMN_LIST_UNLOCK"
export const ADD_OCMN_LOG = "ADD_OCMN_LOG"
export const CHANGE_OCMN_SUGGESTED_CONTACTS_LIST = "CHANGE_OCMN_SUGGESTED_CONTACTS_LIST"
export const SET_OCMN_SHOW_STARTUP = "SET_OCMN_SHOW_STARTUP"
