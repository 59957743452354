import React, {useState, useEffect, useRef, useMemo} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Collapse,
    Form,
    Input,
    Label,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    FormFeedback,
    UncontrolledTooltip,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingModal from "../../components/Common/LoadingModal"
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { DragableTable } from "../../components/Common/DragableTable";

import {
  getServiceManualList,
  updateServiceManual,
  updateServiceManualPriority,
  changeServiceManualList,
  changeServiceManualInfo,
  changeServiceManualResult,
  changeServiceManualLoading,
  changeIsReload,
  getPreviewServiceManual,
  setPreviewServiceManual,
  updateServiceManualViewCnt,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

import '../../components/Common/Common.css';

import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import styled from "styled-components";

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const ServiceManual = props => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    console.log('onDocumentLoadSuccess : ' + numPages);
    setNumPages(numPages);
  }

  const dispatch = useDispatch();
  
  const { loading, result, totalPage, serviceManualList, pageNames, isReload, previewServiceManual, previewPdf } = useSelector(state => ({
    loading: state.ServiceManual.loading,
    result : state.ServiceManual.result,
    totalPage: state.ServiceManual.totalPage,
    serviceManualList: state.ServiceManual.serviceManualList,
    pageNames: state.ServiceManual.pageNames,
    isReload: state.ServiceManual.isReload,
    previewServiceManual: state.ServiceManual.previewServiceManual,
    previewPdf: state.ServiceManual.previewPdf,
  }));

  const [inputServiceManual, setInputServiceManual] = useState({pageName:'Madrid Notifier', functionName:'', vodLink:''});

  const msg = [
      "Success"
    , "Please select a entity."
  ];

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const [curPageInput, setCurPageInput] = useState(1);

  const viewServiceManual = (sortType, curPage, perPage) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(perPage){
      filterValidation.setFieldValue("perPage", perPage);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      keyword: "",
      perPage: 20,
      curPage: 1,
    },
    
    onSubmit: (values) => {
      const filterParam = {
        keyword: values["keyword"],
        perPage: values["perPage"],
        curPage: values["curPage"],
      };

      let submitType = values["submitType"];
      
      if(submitType === "view"){
        dispatch(getServiceManualList(filterParam));
      }
    }
  })

  const setServiceManualListLoading = () => {
    dispatch(changeServiceManualLoading(!loading));
  }

  useEffect(()=> {
    if (isReload) {
      setInputServiceManual({pageName:'Madrid Notifier', functionName:'', vodLink:''});
      dispatch(changeIsReload(false));
      filterValidation.setFieldValue("submitType", "view");
      filterValidation.handleSubmit(filterValidation.values);
    }
  },[isReload]);

  useEffect(()=> {
    filterValidation.setFieldValue("submitType", "view");
    filterValidation.handleSubmit(filterValidation.values);
  },[]);

  return (
    <React.Fragment>
      <Form onSubmit={filterValidation.handleSubmit}>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <Link onClick={toggle} className="text-dark" to="#">
                    <div className="p-4">
                      <Media className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <i className="uil uil-book-open text-primary h2"></i>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">WeCrest Manual</h5>
                        </div>
                        <i className={isOpen ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"} style={{ cursor: "pointer"}}></i>
                      </Media>
                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <div>
                        <Row className="mb-3">
                          <label
                          htmlFor="toEmailMn"
                          className="col-md-2 col-form-label"
                          >
                          Key word
                          </label>
                          <div className="col-md-3">
                          <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter key word"
                              id="keyword"
                              name="keyword"
                              onChange={filterValidation.handleChange}
                              value={filterValidation.values.keyword}
                              onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                  setCurPageInput(1);
                                  filterValidation.setFieldValue("curPage", 1);
                                  filterValidation.setFieldValue("submitType", "view");
                                  filterValidation.handleSubmit(filterValidation.values);
                                }                      
                              }}
                          />
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light col-md-1"
                            onClick={()=>{
                              setCurPageInput(1);
                              filterValidation.setFieldValue("curPage", 1);
                              filterValidation.setFieldValue("submitType", "view");
                              filterValidation.handleSubmit(filterValidation.values);
                            }}
                          >
                            Search
                          </button>
                        </Row>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </Col>
            </Row>
            
            {previewServiceManual && Object.keys(previewServiceManual).length !== 0? (
              <Modal
                size="xl"
                isOpen={true}
                toggle={() => {
                  dispatch(setPreviewServiceManual({}));
                }}
                onOpened={() => {
                  setServiceManualListLoading();
                }}
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="myExtraLargeModalLabel"
                  >
                    Page&nbsp;&nbsp;:&nbsp;&nbsp;{previewServiceManual.pageName? previewServiceManual.pageName : null}<br/>
                    Function&nbsp;&nbsp;:&nbsp;&nbsp;{previewServiceManual.functionName? previewServiceManual.functionName: null}<br/>
                  </h5>
                  <button
                    onClick={() => {
                      dispatch(setPreviewServiceManual({}));
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {previewServiceManual.vodLink ?
                  <Card className="mb-2">
                    <CardBody style={{textAlign: "center"}}>
                    <iframe width="70%" className="reg-page-vod-height" src={previewServiceManual.vodLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </CardBody>
                  </Card>
                  : null}

                  {previewPdf != "" ?
                  <Card className="mb-0">
                    <CardBody>
                      <PDFDocumentWrapper>
                        <Document file={previewPdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error} >
                        {Array.apply(null, Array(numPages))
                          .map((x, i)=>i+1)
                          .map(page => <Page pageNumber={page}/> )}
                        </Document>
                      </PDFDocumentWrapper>
                    </CardBody>
                  </Card>
                  : null}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(setPreviewServiceManual({}));
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </Modal>
            ) : null}

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Page</th>
                            <th>Function</th>
                            <th>VOD</th>
                            <th>PDF</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          serviceManualList && serviceManualList.length ?
                          serviceManualList.map((serviceManual, index) => {
                            return (
                                <tr key={index}>
                                  <td style={{verticalAlign: "middle"}}>{serviceManual.pageName}</td>
                                  <td style={{verticalAlign: "middle"}}>
                                    <Link to="#"
                                      onClick={()=>{
                                        dispatch(getPreviewServiceManual(serviceManual));
                                      }}>{serviceManual.functionName}</Link>
                                  </td>
                                  <td style={{verticalAlign: "middle"}}>
                                    {serviceManual.vodLink ?
                                    <a href="#" target="_blank" rel="noreferrer"
                                    onClick={()=>{
                                      dispatch(updateServiceManualViewCnt(serviceManual));
                                      window.open(
                                        serviceManual.vodLink,
                                        '_blank' // <- This is what makes it open in a new window.
                                      );
                                    }}
                                    ><i className="mdi mdi-file-video text-primary font-size-22"></i></a>
                                    : null}
                                  </td>
                                  <td style={{verticalAlign: "middle"}}>
                                    {serviceManual.pdfLink ?
                                    <a href="#" target="_blank" rel="noreferrer"
                                    onClick={()=>{
                                      dispatch(updateServiceManualViewCnt(serviceManual));
                                      window.open(
                                        serviceManual.pdfLink,
                                        '_blank' // <- This is what makes it open in a new window.
                                      );
                                    }}
                                    ><i className="mdi mdi-file-pdf text-danger font-size-22"></i></a>
                                    : null}
                                  </td>
                                  <td style={{verticalAlign: "middle"}}>{serviceManual.viewCnt}</td>
                                </tr>
                            )
                          })
                          : null
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="d-flex align-items-center gap-2">
                        <label
                          htmlFor="perPage"
                          >
                          Entries per page
                        </label>
                        <div style={{width: "100px"}}>
                          <select 
                            className="form-select" 
                            name="perPage"
                            onChange={e => {viewServiceManual(null, 1, e.target.value);}}
                            value={filterValidation.values.perPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <div className="d-flex gap-1">
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, 1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<<"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, Number(filterValidation.values.curPage)-1);}}
                            disabled={(filterValidation.values.curPage === 1)}
                          >
                            {"<"}
                          </Button>
                        </div>
                        Page{" "}
                        <strong>
                          {filterValidation.values.curPage} of {totalPage}
                        </strong>
                        
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 70 }}
                          max={totalPage}
                          name="curPage"
                          defaultValue={filterValidation.values.curPage}
                          value={curPageInput}
                          onChange={(e) => { setCurPageInput(e.target.value)}}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              viewServiceManual(null, e.target.value);
                            }                      
                          }}
                        />
                        <div className="d-flex gap-1">
                          <Button 
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, Number(filterValidation.values.curPage)+1);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={()=>{viewServiceManual(null, totalPage);}}
                            disabled={(filterValidation.values.curPage === totalPage)}
                          >
                            {">>"}
                          </Button>
                        </div>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              loading ?
              <LoadingModal loading={loading} setLoading={setServiceManualListLoading}></LoadingModal>
              : null
            }

            {result !== -1 ? (
            <SweetAlert
              title={msg[result]}
              onConfirm={() => {
                dispatch(changeServiceManualResult(-1));
              }}
            />
            ) : null}
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default ServiceManual;