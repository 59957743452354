import {
  GET_HAGUE_NOTIFIER_SETTINGS,
  GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
  UPDATE_HAGUE_NOTIFIER_SETTINGS,
  GET_HAGUE_INFO,
  GET_HAGUE_INFO_SUCCESS,
  GET_HAGUE_NOTIFIER_INFO_LIST,
  GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS,
  GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK,
  CHANGE_HAGUE_NOTIFIER,
  GET_HAGUE_JURISDICTIONS,
  GET_HAGUE_JURISDICTIONS_SUCCESS,
  UPDATE_HAGUE_JURISDICTIONS,
  GET_HAGUE_COUNTRIES,
  GET_HAGUE_COUNTRIES_SUCCESS,
  SET_HAGUE_LOADING,
  GET_HAGUE_HOLDER_LIST,
  GET_HAGUE_HOLDER_LIST_SUCCESS,
  SAVE_HAGUE_NOTIFIER_SETTINGS,
  SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
  MULTIPLE_STAR_HAGUE,
  MULTIPLE_STAR_HAGUE_SUCCESS,
  MULTIPLE_UNSTAR_HAGUE,
  MULTIPLE_UNSTAR_HAGUE_SUCCESS,
  MULTIPLE_HIDE_HAGUE,
  MULTIPLE_HIDE_HAGUE_SUCCESS,
  MULTIPLE_UNHIDE_HAGUE,
  MULTIPLE_UNHIDE_HAGUE_SUCCESS,
  SET_HAGUE_SUCCESS,
  DOWN_HAGUE_EXCEL,
  DOWN_HAGUE_EXCEL_SUCCESS,
  STAR_HAGUE_TARGET,
  UNSTAR_HAGUE_TARGET,
  HIDE_HAGUE_TARGET,
  UNHIDE_HAGUE_TARGET,
  DOWN_MULTIPLE_HAGUE_EXCEL,
  DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS,
  DOWN_HAGUE_PDF,
  DOWN_HAGUE_PDF_SUCCESS,
  DOWN_MULTIPLE_HAGUE_PDF,
  DOWN_MULTIPLE_HAGUE_PDF_SUCCESS,
  SEND_HAGUE_EMAIL,
  SEND_HAGUE_EMAIL_SUCCESS,
  PREVIEW_HAGUE_EMAIL,
  PREVIEW_HAGUE_EMAIL_SUCCESS,
  SET_PREVIEW_HAGUE_EMAIL,
  UNCLOCK_HAGUE,
  SET_UPGRADE_ALERT_MESSAGE,
  SET_HAGUE_RESULT,
  CHANGE_SENT_HAGUE_RESULT,
  SET_HAGUE_SENT_SUCCESS,
} from "./actionTypes";

export const gethagueNotifierSettings = (data) => {
  return {
    type: GET_HAGUE_NOTIFIER_SETTINGS,
    payload: data,
  };
};

export const getHagueNotifierSettingsSuccess = (data) => {
  return {
    type: GET_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const updateHagueNotifierSettings = (data) => {
  return {
    type: UPDATE_HAGUE_NOTIFIER_SETTINGS,
    payload: data,
  };
};

export const getHagueInfo = (data) => {
  return {
    type: GET_HAGUE_INFO,
    payload: data,
  };
};

export const getHagueInfoSuccess = (data) => {
  return {
    type: GET_HAGUE_INFO_SUCCESS,
    payload: data,
  };
};

export const getHagueNotifierInfoList= (hagueNotifierList, inOutType)=>{
  return{
    type: GET_HAGUE_NOTIFIER_INFO_LIST,
    payload:{hagueNotifierList, inOutType}
  }
}

export const getHagueNotifierInfoListSuccess = (data)=>{
  return{
    type: GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS,
    payload:data
  }
}

export const getHagueNotifierInfoListSuccessUnlock =(data)=>{
  return{
    type: GET_HAGUE_NOTIFIER_INFO_LIST_SUCCESS_UNLOCK,
    payload: data
  }
}

export const changeHagueNotifier = (data) => {
  return {
    type: CHANGE_HAGUE_NOTIFIER,
    payload: data,
  };
}

export const getHagueJurisdictions = (data) => {
  return {
    type: GET_HAGUE_JURISDICTIONS,
    payload: data,
  };
};

export const getHagueJurisdictionsSuccess = (data) => {
  return {
    type: GET_HAGUE_JURISDICTIONS_SUCCESS,
    payload: data,
  };
};

export const updateHagueJurisdictions = (data) => {
  return {
    type: UPDATE_HAGUE_JURISDICTIONS,
    payload: data,
  };
};

export const getHagueCountries = (data) => {
  return {
    type: GET_HAGUE_COUNTRIES,
    payload: data,
  };
};

export const getHagueCountriesSuccess = (data) => {
  return {
    type: GET_HAGUE_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const setHagueLoading = (data) => {
  return {
    type: SET_HAGUE_LOADING,
    payload: data,
  };
};

export const getHagueHolderList = (data) => {
  return {
    type: GET_HAGUE_HOLDER_LIST,
    payload: data,
  };
};

export const getHagueHolderListSuccess = (data) => {
  return {
    type: GET_HAGUE_HOLDER_LIST_SUCCESS,
    payload: data,
  };
};

export const saveHagueNotifierSettings = (data) => {
  return {
    type: SAVE_HAGUE_NOTIFIER_SETTINGS,
    payload: data,
  };
};

export const saveHagueNotifierSettingsSuccess = (data) => {
  return {
    type: SAVE_HAGUE_NOTIFIER_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const multipleStarHague = (hagueNotifierList, inOutType) => {
  return {
    type: MULTIPLE_STAR_HAGUE,
    payload: {hagueNotifierList, inOutType},
  };
};

export const multipleStarHagueSuccess = (data) => {
  return {
    type: MULTIPLE_STAR_HAGUE_SUCCESS,
    payload: data,
  };
};

export const multipleUnstarHague = (hagueNotifierList, inOutType) => {
  return {
    type: MULTIPLE_UNSTAR_HAGUE,
    payload: {hagueNotifierList, inOutType},
  };
};

export const multipleUnstarHagueSuccess = (data) => {
  return {
    type: MULTIPLE_UNSTAR_HAGUE_SUCCESS,
    payload: data,
  };
};

export const multipleHideHague = (hagueNotifierList, inOutType) => {
  return {
    type: MULTIPLE_HIDE_HAGUE,
    payload: {hagueNotifierList, inOutType},
  };
};

export const multipleHideHagueSuccess = (data) => {
  return {
    type: MULTIPLE_HIDE_HAGUE_SUCCESS,
    payload: data,
  };
};

export const multipleUnhideHague = (hagueNotifierList, inOutType) => {
  return {
    type: MULTIPLE_UNHIDE_HAGUE,
    payload: {hagueNotifierList, inOutType},
  };
};

export const multipleUnhideHagueSuccess = (data) => {
  return {
    type: MULTIPLE_UNHIDE_HAGUE_SUCCESS,
    payload: data,
  };
};

export const setHagueSuccess = (data) => {
  return {
    type: SET_HAGUE_SUCCESS,
    payload: data,
  };
};

export const setHagueResults = (data) =>{
  return {
    type: SET_HAGUE_RESULT,
    payload: data,
  }
}

export const downHagueExcel = (data, fileName) => {
  return {
    type: DOWN_HAGUE_EXCEL,
    payload: {data, fileName},
  };
};

export const downHagueExcelSuccess = (data) => {
  return {
    type: DOWN_HAGUE_EXCEL_SUCCESS,
    payload: data,
  };
};

export const starHagueTarget = (targetGroupNo, inOutType, hagueNotifierList) => {
  return {
    type: STAR_HAGUE_TARGET,
    payload: {targetGroupNo, inOutType, hagueNotifierList},
  };
};

export const unstarHagueTarget = (targetGroupNo, inOutType, hagueNotifierList) => {
  return {
    type: UNSTAR_HAGUE_TARGET,
    payload: {targetGroupNo, inOutType, hagueNotifierList},
  };
};

export const hideHagueTarget = (targetGroupNo, inOutType, hagueNotifierList) => {
  return {
    type: HIDE_HAGUE_TARGET,
    payload: {targetGroupNo, inOutType, hagueNotifierList},
  };
};

export const unhideHagueTarget = (targetGroupNo, inOutType, hagueNotifierList) => {
  return {
    type: UNHIDE_HAGUE_TARGET,
    payload: {targetGroupNo, inOutType, hagueNotifierList},
  };
};

export const downMultipleHagueExcel = (data, fileName) => {
  return {
    type: DOWN_MULTIPLE_HAGUE_EXCEL,
    payload: {data, fileName},
  };
}

export const downMultipleHagueExcelSuccess = (data) => {
  return {
    type: DOWN_MULTIPLE_HAGUE_EXCEL_SUCCESS,
    payload: data,
  };
}

export const downHaguePdf = (data) => {
  return {
    type: DOWN_HAGUE_PDF,
    payload: {data},
  };
}

export const downHaguePdfSuccess = (data) => {
  return {
    type: DOWN_HAGUE_PDF_SUCCESS,
    payload: data,
  };
}

export const downMultipleHaguePdf = (data, fileName) => {
  return {
    type: DOWN_MULTIPLE_HAGUE_PDF,
    payload: {data, fileName},
  };
}

export const downMultipleHaguePdfSuccess = (data) => {
  return {
    type: DOWN_MULTIPLE_HAGUE_PDF_SUCCESS,
    payload: data,
  };
}

export const sendHagueEmail = (data) => {
  return {
    type: SEND_HAGUE_EMAIL,
    payload: data,
  };
}

export const sentHagueEmailSuccess = (data) => {
  return {
    type: SEND_HAGUE_EMAIL_SUCCESS,
    payload: data,
  };
}

export const previewHagueEmail = (data) => {
  return {
    type: PREVIEW_HAGUE_EMAIL,
    payload: data,
  };
}

export const previewHagueEmailSuccess = (data) => {
  return {
    type: PREVIEW_HAGUE_EMAIL_SUCCESS,
    payload: data,
  };
}

export const setPreviewHagueEmail = data => {
  return {
    type: SET_PREVIEW_HAGUE_EMAIL,
    payload: data,
  }
}

export const unlockHague = data => {
  return {
    type: UNCLOCK_HAGUE,
    payload: data,
  }
}

export const setUpgradeAlertMessage = data => {
  return {
    type: SET_UPGRADE_ALERT_MESSAGE,
    payload: data,
  }
}

export const changeSentHagueResult = data =>{
  return{
    type: CHANGE_SENT_HAGUE_RESULT,
    payload: data,
  }
}

export const setHagueSentSuccess = data =>{
  return{
    type: SET_HAGUE_SENT_SUCCESS,
    payload: data,
  }
}