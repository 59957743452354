import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Row,
    Card,
    CardBody,
    CardTitle,
    Button,
    UncontrolledTooltip,
  } from "reactstrap"
import LoadingModal from "../../components/Common/LoadingModal"
import CreateBillingProfile from "../../components/Common/CreateBillingProfile"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import {
  getUserInvoiceInfoList as onGetUserInvoiceInfoList,
  removeUserInvoiceInfo as onRemoveUserInvoiceInfo,
  getUserSubscriptionList as onGetUserSubscriptionList,
  modifyUserSubscriptionInvoiceInfo as onModifyUserSubscriptionInvoiceInfo,  
  setSettingsReducerValue,
} from "../../store/actions";
import { cloneDeep } from 'lodash';

const InvoicingSettings = props => {
  const dispatch = useDispatch();

  const { userInvoiceInfoList, userSubscriptionList, loading, success } = useSelector(state => ({
    userInvoiceInfoList: state.AdmInvoiceManagement.userInvoiceInfoList,
    userSubscriptionList: state.Settings.userSubscriptionList,
    loading: state.Settings.loading,
    success: state.Settings.success,
  }));
  
  const [isOpenBillingProfile, setIsOpenBillingProfile] = useState(false);
  const [removeBillingProfileError, setRemoveBillingProfileError] = useState('');
  const [removeBillingProfileSeq, setRemoveBillingProfileSeq] = useState(-1);
  const [changeSubscriptionInvoiceInfo, setChangeSubscriptionInvoiceInfo] = useState({});
  const billingProfileModalRef = useRef(null);

  const closeLoding = () => {
    dispatch(setSettingsReducerValue('LOADING_FLAG', false));
  }

  const createBillingProfile = () => {
    billingProfileModalRef.current.createBillingProfile();
  }

  const editBillingProfile = (userInvoiceInfo) => {
    billingProfileModalRef.current.editBillingProfile(userInvoiceInfo);
  }

  const removeBillingProfile = (chkSeq) => {
    let allowBillingProfileCrCodeList = [];

    userSubscriptionList.map((userSubscription, index) => {
      if (userSubscription.invoiceInfoSeq === chkSeq) {
        allowBillingProfileCrCodeList.push(userSubscription.crCode + ' ' + userSubscription.crName)
      }
    })

    if (allowBillingProfileCrCodeList.length === 0) {
      setRemoveBillingProfileSeq(chkSeq);
    } else {
      setRemoveBillingProfileError(<>This is your default billing profile for the following subscriptions, and you will need to select a new default billing profile before deleting this one:<br/>{JSON.stringify(allowBillingProfileCrCodeList)}.</>);
    }
  }

  const changeSubscriptionInvoiceInfoSeq = (index, newInvoiceInfoSeq) => {
    let _changeSubscriptionInvoiceInfo = cloneDeep(userSubscriptionList[index]);
    _changeSubscriptionInvoiceInfo.invoiceInfoSeq = newInvoiceInfoSeq;
    setChangeSubscriptionInvoiceInfo(_changeSubscriptionInvoiceInfo);
  }

  useEffect(()=> {
    dispatch(onGetUserInvoiceInfoList());
    dispatch(onGetUserSubscriptionList());
  },[]);

  useEffect(()=> {
    setIsOpenBillingProfile(false);
  },[userInvoiceInfoList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Card>
              <CardBody>
              <CardTitle className="h4">Billing profile</CardTitle>
                {
                  userInvoiceInfoList && userInvoiceInfoList.length ? 
                  userInvoiceInfoList.map((userInvoiceInfo, index) => {
                    return (
                      <Row className="mb-3">
                        <div className="col-md-4 col-form-label display-flex vertical-align-center">
                          {
                            userInvoiceInfo.invoiceTitle
                          }
                        </div>
                        <div className="col-md-3 col-form-label d-flex gap-3">
                          <Link to="#"
                            onClick={()=>{
                              editBillingProfile(userInvoiceInfo);
                            }}>
                            <i className="uil uil-file-edit-alt font-size-18" id={"editBillingProfile_"+index}></i>
                            <UncontrolledTooltip placement="top" target={"editBillingProfile_"+index}>
                              Edit billing profile
                            </UncontrolledTooltip>
                          </Link>
                          <Link to="#" 
                            onClick={() => {
                              removeBillingProfile(userInvoiceInfo.seq);
                            }}
                          >
                            <i className="uil uil-trash-alt font-size-18 text-danger" id={"deleteBillingProfile_"+index}></i>
                            <UncontrolledTooltip placement="top" target={"deleteBillingProfile_"+index}>
                            Delete billing profile
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </Row>
                    )
                  })
                  :
                  null
                }
                <Row>
                  <label className="col-md-3">
                  <Button
                    type="button"
                    color="outline-primary"
                    className="w-md"
                    onClick={()=>{
                      createBillingProfile();
                    }}
                  >
                    Create new
                  </Button>
                  </label>
                </Row>
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Subscription details</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-centered table-nowrap mb-0">
                          <thead className="table-light">
                              <tr>
                                  <th>Subscription</th>
                                  <th>Type</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>Billing profile</th>
                              </tr>
                          </thead>
                          <tbody>
                            {userSubscriptionList && userSubscriptionList.length?
                              userSubscriptionList.map((subscription, index)=>{
                                  return(
                                      subscription.crCode !== '00' &&  subscription.inOutType === 'IN' ?
                                      <tr>
                                          <td>{subscription.crCode} {subscription.crName} {subscription.subscriptionType === 'JN'? "(Madrid)" : "(Hague)"}</td>
                                          <td>{subscription.memberType}</td>
                                          <td>{subscription.startDate}</td>
                                          <td>{subscription.endDate}</td>
                                          <td>
                                            <select 
                                              className="form-select" 
                                              name="subscription.invoiceInfoSeq"
                                              onChange={(e) => {changeSubscriptionInvoiceInfoSeq(index, e.target.value)}}
                                              value={subscription.invoiceInfoSeq ? subscription.invoiceInfoSeq : 1}
                                            >
                                            {
                                              userInvoiceInfoList.map((userInvoiceInfo, index) => {
                                                return (
                                                  <option value={userInvoiceInfo.seq}>{userInvoiceInfo.invoiceTitle}</option>
                                                ) 
                                              })
                                            }
                                            </select>
                                          </td>
                                      </tr>
                                      : null
                                  )
                              })
                            :null}
                          </tbody>
                      </Table>
                    </div>
                </CardBody>
            </Card>
          </Row>

          <CreateBillingProfile ref={billingProfileModalRef} isOpenBillingProfile={isOpenBillingProfile} setIsOpenBillingProfile={setIsOpenBillingProfile} pageName='invoiceSetting'/>

          {
            success ? 
            <SweetAlert
              title={success}
              timeout={2000}
              showCloseButton={false}
              showConfirm={false}
              success
              onConfirm={() => {
                dispatch(setSettingsReducerValue('SUCCESS', ""));
              }}
            ></SweetAlert>
            : null
          }

          {
            removeBillingProfileError !== '' ?
            <SweetAlert
              warning
              title="Warning!"
              confirmBtnBsStyle="danger"
              onConfirm={() => {
                setRemoveBillingProfileError('');
              }}
            >
              {removeBillingProfileError}
            </SweetAlert>
            : null
          }

          {
            removeBillingProfileSeq !== -1 ?
            <SweetAlert
              warning
              title="Are you sure you want to delete this billing profile?"
              confirmBtnBsStyle="success"
              onConfirm={() => {
                let _removeBillingProfileSeq = removeBillingProfileSeq;
                dispatch(onRemoveUserInvoiceInfo({seq: _removeBillingProfileSeq}));
                setRemoveBillingProfileSeq(-1);
              }}
              showCancel
              cancelBtnBsStyle="danger"
              onCancel={() => {
                setRemoveBillingProfileSeq(-1);
              }}
            >
            </SweetAlert>
          : null}

          {JSON.stringify(changeSubscriptionInvoiceInfo) !== '{}' ? 
            <SweetAlert
              warning
              title="Change billing profile?"
              confirmBtnBsStyle="success"
              onConfirm={() => {
                let _changeSubscriptionInvoiceInfo = cloneDeep(changeSubscriptionInvoiceInfo);
                dispatch(onModifyUserSubscriptionInvoiceInfo(_changeSubscriptionInvoiceInfo));
                setChangeSubscriptionInvoiceInfo({});
              }}
              showCancel
              cancelBtnBsStyle="danger"
              onCancel={() => {
                setChangeSubscriptionInvoiceInfo({});
              }}
            />
          : null}

          <LoadingModal loading={loading} setLoading={closeLoding}></LoadingModal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoicingSettings;