import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    Media,
    Collapse,
    Table,
    UncontrolledTooltip,
    Button,
    Input,
    Modal,
  } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import {
  getConnectableEntityList as onGetConnectableEntityList,
  getMyEntityList as onGetMyEntityList,
  getMainDomain as onGetMainDomain,
  getSearchEntityList as onGetSearchEntityList,
  changeUserEntityConnectList as onChangeUserEntityConnectList,
  addUserEntityConnectClaim as onAddUserEntityConnectClaim,
  setEntityConnectManagementSuccess as onSetSuccess,
} from "../../store/actions";

import '../../components/Common/Common.css';
import WeCrestPagination from '../../components/WeCrestPagination'
import "toastr/build/toastr.min.css"
import { cloneDeep } from 'lodash';

const SearchEntity = props => {
  const dispatch = useDispatch();
  const searchResultsRef = useRef(null)

  const { mainDomain, connectableEntityTotalPage, connectableEntityList, myEntityTotalPage, myEntityList, totalPage, userEntityConnectList, isSearch, userDomainList } = useSelector(state => ({
    mainDomain: state.EntityConnectManagement.mainDomain,
    connectableEntityTotalPage: state.EntityConnectManagement.connectableEntityTotalPage,
    connectableEntityList: state.EntityConnectManagement.connectableEntityList,
    myEntityTotalPage: state.EntityConnectManagement.myEntityTotalPage,
    myEntityList: state.EntityConnectManagement.myEntityList,
    totalPage: state.EntityConnectManagement.totalPage,
    userEntityConnectList: state.EntityConnectManagement.userEntityConnectList,
    isSearch: state.EntityConnectManagement.isSearch,
    userDomainList: state.EntityConnectManagement.userDomainList,
  }));

  const [claimTargetGroupNo, setClaimTargetGroupNo] = useState(0);
  const [claimType, setClaimType] = useState("");
  const [claimDomain, setClaimDomain] = useState("");
  const [claimListName, setClaimListName] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  }
  
  const [isOpenSuggestions, setIsOpenSuggestions] = useState(true);
  const toggleSuggestions = () => {
    setIsOpenSuggestions(!isOpenSuggestions);
  }

  const [isOpenResults, setIsOpenResults] = useState(true);
  const toggleResults = () => {
    setIsOpenResults(!isOpenResults);
  }

  const [isOpenMyEntities, setIsOpenMyEntities] = useState(true);
  const toggleMyEntities = () => {
    setIsOpenMyEntities(!isOpenMyEntities);
  }

  const [idnmStatus, setIdnmStatus] = useState('Y');

  const [connectableEntityCurPageInput, setConnectableEntityCurPageInput] = useState(1);
  const viewConnectableEntityList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > connectableEntityTotalPage) {
        curPage = connectableEntityTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "viewConnectableEntity");
   
    if(curPage){
      filterValidation.setFieldValue("connectableEntityCurPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("connectableEntityCurPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("connectableEntityPageSize", pageSize);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const [myEntityCurPageInput, setMyEntityCurPageInput] = useState(1);
  const viewMyEntityList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > myEntityTotalPage) {
        curPage = myEntityTotalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "viewMyEntity");
   
    if(curPage){
      filterValidation.setFieldValue("myEntityCurPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("myEntityCurPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("myEntityPageSize", pageSize);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const [clickSearchBtn, setClickSearchBtn] = useState(false);
  const [curPageInput, setCurPageInput] = useState(1);

  const viewList = (sortType, curPage, pageSize) => {
    //curPage 유효성 체크
    if(curPage){
      if(curPage > totalPage) {
        curPage = totalPage;
      } else if (curPage <= 0) {
        curPage = 1;
      }
    }

    filterValidation.setFieldValue("submitType", "view");
   
    if(curPage){
      filterValidation.setFieldValue("curPage", curPage);
      setCurPageInput(curPage);
    }else{
      filterValidation.setFieldValue("curPage", 1);
      setCurPageInput(1);
    }
    if(pageSize){
      filterValidation.setFieldValue("pageSize", pageSize);
    }

    if(sortType){
      filterValidation.setFieldValue("sortType", sortType);
    }
    
    filterValidation.handleSubmit(filterValidation.values);
  }

  const filterValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      submitType: "view",
      sortType: "",
      searchEntity: "",
      searchDomain: "",
      searchStatus: "",
      pageSize: 20,
      curPage: 1,

      connectableEntityPageSize: 20,
      connectableEntityCurPage: 1,

      myEntityPageSize: 20,
      myEntityCurPage: 1,
    },
    
    onSubmit: (values) => {
      // console.log(JSON.stringify(values));
      let submitType = values["submitType"];
      
      if(submitType === "view"){
        const filterParam = {
          searchConnectableEntityYn:"N",
          searchEntity: values["searchEntity"],
          searchDomain: values["searchDomain"],
          searchStatus: values["searchStatus"],
          pageSize: values["pageSize"],
          curPage: values["curPage"],
          sortType: values["sortType"],
        };

        dispatch(onGetSearchEntityList(filterParam));
      } else if(submitType === "viewConnectableEntity"){
        const filterParam = {
          pageSize: values["connectableEntityPageSize"],
          curPage: values["connectableEntityCurPage"],
        };

        dispatch(onGetConnectableEntityList(filterParam));
      } else if(submitType === "viewMyEntity"){
        const filterParam = {
          pageSize: values["myEntityPageSize"],
          curPage: values["myEntityCurPage"],
        };

        dispatch(onGetMyEntityList(filterParam));
      }
    }
  })

  useEffect(() => { 
    dispatch(onGetConnectableEntityList({}));
    dispatch(onGetMyEntityList({}));
    dispatch(onGetMainDomain());
  }, []);

  useEffect(() => { 
    if (clickSearchBtn && isSearch && userEntityConnectList && userEntityConnectList.length !== 0 && searchResultsRef && searchResultsRef.current) {
      searchResultsRef.current.scrollIntoView()    
      setClickSearchBtn(false);
    }
  }, [isSearch]);

  const popupInput = () => {
    <input
      className="form-control"
      type="text"
      placeholder="Enter domain"
      onChange={(e) => {
        setClaimDomain(e.target.value);
      }}
      value={claimDomain}
    />
  }
  
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggle} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Search</h5>
                  </div>
                  <i className="uil uil-filter font-size-24 text-muted"></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpen}>
              <div className="p-4 border-top">
                  <div>
                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                      <label
                        htmlFor="entity-text-input"
                        className="col-md-1 col-form-label"
                        >
                        Entity
                      </label>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter entity"
                          name="searchEntity"
                          onChange={filterValidation.handleChange}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              if (e.target.value !== '') {
                              //   dispatch(onChangeUserEntityConnectList([]));
                              // } else {
                                setClickSearchBtn(true);
                                setCurPageInput(1);
                                filterValidation.setFieldValue("curPage", 1);
                                filterValidation.setFieldValue("searchEntity", e.target.value);
                                filterValidation.setFieldValue("submitType", "view");
                                filterValidation.handleSubmit(filterValidation.values);
                              }
                            }                      
                          }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                      <label
                        htmlFor="domain-text-input"
                        className="col-md-1 col-form-label"
                        >
                        Domain
                      </label>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter domain"
                          name="searchDomain"
                          onChange={filterValidation.handleChange}
                          onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                              if (e.target.value !== '') {
                              //   dispatch(onChangeUserEntityConnectList([]));
                              // } else {
                                setClickSearchBtn(true);
                                setCurPageInput(1);
                                filterValidation.setFieldValue("curPage", 1);
                                filterValidation.setFieldValue("searchDomain", e.target.value);
                                filterValidation.setFieldValue("submitType", "view");
                                filterValidation.handleSubmit(filterValidation.values);
                              }
                            }                      
                          }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                      <label
                          htmlFor="domain-text-input"
                          className="col-md-1 col-form-label"
                          >
                          Status
                      </label>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusAll"
                          onChange={filterValidation.handleChange}
                          value=""
                          checked={filterValidation.values.searchStatus === ''}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusAll"
                        >
                          All
                        </label>
                      </div>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusCSD"
                          onChange={filterValidation.handleChange}
                          value="CSD"
                          checked={filterValidation.values.searchStatus === 'CSD'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusCSD"
                        >
                          Connectable same domain
                        </label>
                      </div>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusCID"
                          onChange={filterValidation.handleChange}
                          value="CID"
                          checked={filterValidation.values.searchStatus === 'CID'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusCID"
                        >
                          Connectable different domain
                        </label>
                      </div>
                    </Row>
                    <Row className="mb-3" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                      <label
                          htmlFor="domain-text-input"
                          className="col-md-1 col-form-label"
                          >
                      </label>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusCWAA"
                          onChange={filterValidation.handleChange}
                          value="CWAA"
                          checked={filterValidation.values.searchStatus === 'CWAA'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusCWAA"
                        >
                          Connected with another account
                        </label>
                      </div>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusMyEntity"
                          onChange={filterValidation.handleChange}
                          value="MyEntity"
                          checked={filterValidation.values.searchStatus === 'MyEntity'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusMyEntity"
                        >
                          My entity
                        </label>
                      </div>
                      <div className="col-md-3 form-check" style={{paddingLeft : '2em'}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="searchStatus"
                          id="statusPending"
                          onChange={filterValidation.handleChange}
                          value="Pending"
                          checked={filterValidation.values.searchStatus === 'Pending'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusPending"
                        >
                          Pending
                        </label>
                      </div>
                    </Row>
                    <Row className="" style={{marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px"}}>
                      <label
                          htmlFor="example-text-input"
                          className="col-md-7 col-form-label"
                          >
                      </label>
                      <div className="col-md-2" style={{paddingRight: "0px"}}>
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={()=>{
                            setClickSearchBtn(true);
                            setCurPageInput(1);
                            filterValidation.setFieldValue("curPage", 1);
                            filterValidation.setFieldValue("submitType", "view");
                            filterValidation.handleSubmit(filterValidation.values);
                          }}
                        >
                        Search
                        </Button>
                      </div>
                    </Row>
                  </div>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>

      {mainDomain && mainDomain !== '' ? 
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggleSuggestions} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">WeCrest Suggestions</h5>
                  </div>
                  <i className={isOpenSuggestions ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpenSuggestions}>
              <div className="p-4 border-top">
                <Row className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="Representative"
                  >
                    Your domain is "{mainDomain}"
                  </label>
                </Row>
                <Row className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="Representative"
                  >
                    We found these entities with your domain "{mainDomain}":
                  </label>
                </Row>
                <Row className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="Representative"
                  >
                    If there any incorrect entities, please click "Incorrect", and we will update it accordingly.
                  </label>
                </Row>
                {connectableEntityList && connectableEntityList.length !== 0 ? 
                <Row>
                  <Col lg={12}>
                    <Card className="mb-2">
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{textAlign: "center"}}>Entity</th>
                              <th style={{textAlign: "center"}}>Domain</th>
                              <th style={{textAlign: "center"}}>Address</th>
                              <th style={{textAlign: "center"}}>Status</th>
                              <th style={{textAlign: "center", width: "230px"}}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                            connectableEntityList.map((entity, index)=>{
                              return (
                                <tr key={index}>
                                  <td align="left">
                                  {entity.targetGroupName}
                                  </td>
                                  <td align="left">
                                  {entity.domain}
                                  </td>
                                  <td align="left">
                                  {entity.address}
                                  </td>
                                  <td align="center">
                                  {entity.status === 'CSD' ? 'Connectable same domain' 
                                  : entity.status === 'CID' ? 'Connectable: different domain' 
                                  : entity.status === 'CWAA' ? 'Connected with another account' 
                                  : entity.status === 'MyEntity' ? 'My entity'
                                  : entity.status}
                                  </td>
                                  <td align="center">
                                  {
                                  entity.myClaimType === '' ?
                                    entity.status === 'CSD' ? /* ConnectableSameDomain */
                                      <>
                                      {/* Connect */}
                                      <button type="button" class="waves-effect waves-light btn btn-outline-success"
                                        onClick={() => {
                                          setClaimType("Connect");
                                          setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                          // dispatch(onConfirmUserEntityConnect({targetGroupNo: entity.targetGroupNo}));
                                        }}>
                                        <i className="uil uil-check font-size-14"></i> Correct
                                      </button>
                                      &nbsp;&nbsp;
                                      <button type="button" class="waves-effect waves-light btn btn-outline-danger"
                                        onClick={() => {
                                          setClaimType("IDNM");
                                          setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                        }}>
                                        <i className="uil uil-times font-size-14"></i> Incorrect
                                      </button>
                                      </>
                                    : entity.status === 'CID' ? /* ConnectableIncorrectDomain */
                                      <>
                                      {/* Incorrect domain + Connect */}
                                      <button type="button" class="waves-effect waves-light btn btn-outline-success"
                                        onClick={() => {
                                          setClaimType("IDC");
                                          // setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                          if (userDomainList && userDomainList.length > 0) {
                                            setClaimDomain(userDomainList[0].domain);
                                          }
                                        }}>
                                        <i className="uil uil-check font-size-14"></i> Correct
                                      </button>
                                      </>
                                    : entity.status === 'CWAA' ?
                                      <>
                                      {/* Connected with another account */}
                                      <button type="button" class="waves-effect waves-light btn btn-outline-danger"
                                        onClick={() => {
                                          setClaimType("CWAA");
                                          // setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                          if (userDomainList && userDomainList.length > 0) {
                                            setClaimDomain(userDomainList[0].domain);
                                          }
                                        }}>
                                        <i className="uil uil-times font-size-14"></i> Claim
                                      </button>
                                      </>
                                    : entity.status === 'MyEntity' ?
                                      <button type="button" class="waves-effect waves-light btn btn-outline-warning"
                                        onClick={() => {
                                          setClaimType("Disconnect");
                                          setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                          setClaimListName("connectableEntityList");
                                        }}>
                                        <i className="uil uil-times font-size-14"></i> Disconnect
                                      </button>
                                    : null
                                  : <span className="text-warning">
                                    {entity.myClaimType ==='IDNM' ? "You raised an issue with this entity: Entity has incorrect domain, and it is not my entity." 
                                      : entity.myClaimType ==='Connect' ?  "You requested to connect with this entity: it’s my entity."
                                      : entity.myClaimType ==='CWAA' ?  "You raised an issue with the item: it’s mine"
                                      : "You raised an issue with this entity: Entity has incorrect domain, and it’s my entity."}
                                  </span>
                                  }
                                  </td>
                                </tr>
                              )})
                            }
                            </tbody>
                          </Table>
                        </div>

                        <WeCrestPagination 
                          viewList={viewConnectableEntityList} 
                          pageSize={filterValidation.values.connectableEntityPageSize} 
                          curPage={filterValidation.values.connectableEntityCurPage}
                          totalPage={connectableEntityTotalPage}
                          curPageInput={connectableEntityCurPageInput}
                          setCurPageInput={setConnectableEntityCurPageInput}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :
                <Row>
                  <Col lg={12}>
                    <Card className="mb-2">
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{textAlign: "center"}}>No Suggestions</th>
                              </tr>
                            </thead> 
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                }
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
      : null}

      <div ref={searchResultsRef}></div> 
      {userEntityConnectList && userEntityConnectList.length !== 0 ? 
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggleResults} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Search results</h5>
                  </div>
                  <i className={isOpenResults ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpenResults}>
              <div className="p-4 border-top">
                <Row>
                  <Col lg={12}>
                    <Card className="mb-2">
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{textAlign: "center"}}>Entity</th>
                              <th style={{textAlign: "center"}}>Domain</th>
                              <th style={{textAlign: "center"}}>Address</th>
                              <th style={{textAlign: "center"}}>Status</th>
                              <th style={{textAlign: "center", width: "230px"}}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              userEntityConnectList.map((entity, index)=>{
                                return (
                                    <tr key={index}>
                                      <td align="left">
                                      {entity.targetGroupName}
                                      </td>
                                      <td align="left">
                                      {entity.domain}
                                      </td>
                                      <td align="left">
                                      {entity.address}
                                      </td>
                                      <td align="center">
                                      {entity.status === 'CSD' ? 'Connectable same domain' 
                                      : entity.status === 'CID' ? 'Connectable: different domain' 
                                      : entity.status === 'CWAA' ? 'Connected with another account' 
                                      : entity.status === 'MyEntity' ? 'My entity'
                                      : entity.status}
                                      </td>
                                      <td align="center">
                                      {
                                      entity.myClaimType === '' ?
                                        entity.status === 'CSD' ?
                                          <>
                                          {/* Connect */}
                                          <button type="button" class="waves-effect waves-light btn btn-outline-success"
                                            onClick={() => {
                                              setClaimType("Connect");
                                              setClaimDomain("");
                                              setClaimTargetGroupNo(entity.targetGroupNo);
                                              // dispatch(onConfirmUserEntityConnect({targetGroupNo: entity.targetGroupNo}));
                                            }}>
                                            <i className="uil uil-check font-size-14"></i> Correct
                                          </button>
                                          &nbsp;&nbsp;
                                          <button type="button" class="waves-effect waves-light btn btn-outline-danger"
                                            onClick={() => {
                                              setClaimType("IDNM");
                                              setClaimDomain("");
                                              setClaimTargetGroupNo(entity.targetGroupNo);
                                            }}>
                                            <i className="uil uil-times font-size-14"></i> Incorrect
                                          </button>
                                          </>
                                        : entity.status === 'CID' ?
                                          <>
                                          {/* Incorrect domain + Connect */}
                                          <button type="button" class="waves-effect waves-light btn btn-outline-success"
                                            onClick={() => {
                                              setClaimType("IDC");
                                              // setClaimDomain("");
                                              setClaimTargetGroupNo(entity.targetGroupNo);
                                              if (userDomainList && userDomainList.length > 0) {
                                                setClaimDomain(userDomainList[0].domain);
                                              }
                                            }}>
                                            <i className="uil uil-check font-size-14"></i> Correct
                                          </button>
                                          </>
                                        : entity.status === 'CWAA' ?
                                          <>
                                          {/* Connected with another account */}
                                          <button type="button" class="waves-effect waves-light btn btn-outline-danger"
                                            onClick={() => {
                                              setClaimType("CWAA");
                                              // setClaimDomain("");
                                              setClaimTargetGroupNo(entity.targetGroupNo);
                                              if (userDomainList && userDomainList.length > 0) {
                                                setClaimDomain(userDomainList[0].domain);
                                              }
                                            }}>
                                            <i className="uil uil-times font-size-14"></i> Claim
                                          </button>
                                          </>
                                        : entity.status === 'MyEntity' ?
                                          <button type="button" class="waves-effect waves-light btn btn-outline-warning"
                                            onClick={() => {
                                              setClaimType("Disconnect");
                                              setClaimDomain("");
                                              setClaimTargetGroupNo(entity.targetGroupNo);
                                              // dispatch(onDisConnectUserEntity({targetGroupNo: entity.targetGroupNo}));
                                              setClaimListName("userEntityConnectList");
                                            }}>
                                            <i className="uil uil-times font-size-14"></i> Disconnect
                                          </button>
                                        : null
                                      : <span className="text-warning">
                                        {entity.myClaimType ==='IDNM' ? "You raised an issue with this entity: Entity has incorrect domain, and it is not my entity." 
                                        : entity.myClaimType ==='Connect' ?  "You requested to connect with this entity: it’s my entity."
                                        : entity.myClaimType ==='CWAA' ?  "You raised an issue with the item: it’s mine"
                                        : "You raised an issue with this entity: Entity has incorrect domain, and it’s my entity."}
                                        </span>
                                      }
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                        </div>

                        <WeCrestPagination 
                          viewList={viewList} 
                          pageSize={filterValidation.values.pageSize} 
                          curPage={filterValidation.values.curPage}
                          totalPage={totalPage}
                          curPageInput={curPageInput}
                          setCurPageInput={setCurPageInput}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
      : null}

      {myEntityList && myEntityList.length !== 0 ? 
      <Row>
        <Col lg={12}>
          <Card>
            <Link onClick={toggleMyEntities} className="text-dark" to="#">
              <div className="p-4">
                <Media className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">My entities</h5>
                  </div>
                  <i className={isOpenMyEntities ? "mdi mdi-chevron-up accor-down-icon font-size-24" : "mdi mdi-chevron-down accor-down-icon font-size-24"}></i>
                </Media>
              </div>
            </Link>
            <Collapse isOpen={isOpenMyEntities}>
              <div className="p-4 border-top">
                <Row>
                  <Col lg={12}>
                    <Card className="mb-2">
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                              <th style={{textAlign: "center"}}>Entity</th>
                              <th style={{textAlign: "center"}}>Domain</th>
                              <th style={{textAlign: "center"}}>Address</th>
                              <th style={{textAlign: "center"}}>Status</th>
                              <th style={{textAlign: "center", width: "230px"}}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              myEntityList.map((entity, index)=>{
                                return (
                                  <tr key={index}>
                                    <td align="left">
                                    <><i className="uil uil-link font-size-18 text-primary"></i>&nbsp;</>{entity.targetGroupName}
                                    </td>
                                    <td align="left">
                                    {entity.domain}
                                    </td>
                                    <td align="left">
                                    {entity.address}
                                    </td>
                                    <td align="center">
                                    {entity.myClaimType === '' ? 'My entity'
                                    : entity.status}
                                    </td>
                                    <td align="center">
                                    {
                                    entity.myClaimType === '' ?
                                      <button type="button" class="waves-effect waves-light btn btn-outline-warning"
                                        onClick={() => {
                                          setClaimType("Disconnect");
                                          setClaimDomain("");
                                          setClaimTargetGroupNo(entity.targetGroupNo);
                                          setClaimListName("myEntityList");
                                        }}>
                                        <i className="uil uil-times font-size-14"></i> Disconnect
                                      </button>
                                    : <span className="text-warning">You requested to disconnect this entity: it’s not my entity</span>
                                    }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                            </tbody>
                          </Table>
                        </div>
                        
                        <WeCrestPagination 
                          viewList={viewMyEntityList} 
                          pageSize={filterValidation.values.myEntityPageSize} 
                          curPage={filterValidation.values.myEntityCurPage}
                          totalPage={myEntityTotalPage}
                          curPageInput={myEntityCurPageInput}
                          setCurPageInput={setMyEntityCurPageInput}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Card>
        </Col>
      </Row>
      : null}
      
      {alertMsg !== '' ? (
        <SweetAlert
          warning
          onConfirm={() => {
            setAlertMsg('');
          }}
          onCancel={() => {
            setAlertMsg('');
          }}
        >{alertMsg}
        </SweetAlert>
      ) : null}

      <Modal
          isOpen={claimTargetGroupNo > 0}
          toggle={() => {
            setClaimTargetGroupNo(0)
            setClaimListName("")
          }}
          scrollable={true}
          style={{top: "40%"}}
      >
        <h2 style={{textAlign: "center", marginTop: "10px"}}>
          {
          claimType === "Connect" ? "Correct domain It's mine" 
            : claimType === "IDNM" ? <>Not my entity</> 
              : claimType === "IDC" || claimType === "CWAA" ? <>Is this Entity yours?</> 
                : <>Are you sure want to disconnect this entity?</>
          }
        </h2>
        {
        claimType !== 'Connect'?
        <div style={{marginLeft: "10px", marginRight: "10px"}}>
          {
            claimType === 'Disconnect' || claimType === "IDNM"?
            <div style={{textAlign: "center"}}>
              <label>Do you know correct domain?</label>
            </div>
            : null
          }
          {
            claimType === 'Disconnect' || claimType === 'IDNM' || claimType === 'SENBDC' ?
              <div className="d-flex vertical-align-center">
                
                <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="idnmStatus"
                    id="idnmY"
                    onClick={() => {setIdnmStatus('Y')}}
                    value="Y"
                    checked={idnmStatus === 'Y'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="idnmY"
                  >
                    Yes
                  </label>
                </div>
                <div className="col-md-2 form-check" style={{paddingLeft : '2em'}}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="idnmStatus"
                    id="idnmN"
                    onClick={() => {
                      setIdnmStatus('N');
                      setClaimDomain('');
                    }}
                    value="N"
                    checked={idnmStatus === 'N'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="idnmN"
                  >
                    No
                  </label>
                </div>
                {idnmStatus === 'Y'?
                <div className="col-md-8">
                  <input
                    id="claimDomain"
                    key="claimDomainKey"
                    className="form-control"
                    type="text"
                    placeholder="Enter domain"
                    onChange={(e) => {
                      setClaimDomain(e.target.value);
                    }}
                    value={claimDomain}
                  />
                </div>
                : null}
              </div>
            : claimType === "IDC" || claimType === 'CWAA' ?
              <>
              <label>Select correct domain</label>
              {
                userDomainList && userDomainList.length > 0 ?
                userDomainList.map((userDomain, index)=>{
                  return (
                    <Row className={index < userDomainList.length - 1 ? "mb-3" : ""}>
                      <div className="col-md-5" style={{textAlign: "left"}}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rdoUserDomain"
                          id={"domain_" + index}
                          onClick={() => {setClaimDomain(userDomain.domain)}}
                          value={userDomain.domain}
                          checked={claimDomain === userDomain.domain}
                          style={{marginLeft: "20px"}}
                        />
                        &nbsp;
                        <label
                          className="form-check-label"
                          htmlFor={"domain_" + index}
                        >
                          {userDomain.domain}
                        </label>
                      </div>
                    </Row>
                  )
                })
                :
                null
              }
              </>
            : null
          }
        </div>
        : null}
        <div className="d-flex horizon-align-center gap-4" style={{marginTop: "10px", marginBottom: "10px"}}>
            <button
                type="button"
                onClick={() => {
                  setClaimTargetGroupNo(0);
                  setClaimListName("");
                }}
                className="btn btn-lg btn-danger waves-effect"
                data-dismiss="modal"
            >
                Cancel
            </button>
            <button
                type="button"
                className="btn btn-lg btn-success waves-effect waves-light"
                onClick={()=> {
                  if (claimType === 'Disconnect' || claimType === 'IDNM' || claimType === 'SENBDC') {
                    if (idnmStatus === 'Y') {
                      if (claimDomain == '') {
                        setAlertMsg("Please enter correct domain");
                        return;
                      } else if (claimDomain.indexOf("/") > -1) {
                        setAlertMsg("Domain cannot include slash('/').");
                        return;
                      } else if (claimDomain.indexOf(",") > -1) {
                        setAlertMsg("Domain cannot include comma(',').");
                        return;
                      }
                    } 
                  }
      
                  const filterParam = {
                    searchConnectableEntityYn:"N",
                    searchEntity: filterValidation.values.searchEntity,
                    searchDomain: filterValidation.values.searchDomain,
                    searchStatus: filterValidation.values.searchStatus,
                    pageSize: filterValidation.values.pageSize,
                    curPage: filterValidation.values.curPage,
                    sortType: filterValidation.values.sortType,
                  };

                  const myEntityFilterParam = {
                    pageSize: filterValidation.values.myEntityPageSize,
                    curPage: filterValidation.values.myEntityCurPage,
                  };

                  setClickSearchBtn(true);
                  dispatch(onAddUserEntityConnectClaim({targetGroupNo: claimTargetGroupNo, type: claimType, domain: claimDomain, screen: 'ConnectableEntity', claimListName: claimListName}, filterParam, myEntityFilterParam));
                  setClaimTargetGroupNo(0);
                  setClaimListName("");
                }}
            >
                Confirm
            </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SearchEntity;