import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
// import LayoutSaga from "./layout/saga"
// import calendarSaga from "./calendar/saga"
// import chatSaga from "./chat/saga"
// import ecommerceSaga from "./e-commerce/saga"
// import invoiceSaga from "./invoices/saga"
import userSaga from "./user/saga"
import DashboardSaga from "./dashboard/saga"
import GeneralStatisticsSaga from "./generalStatistics/saga"
import MultipleJurisdictionsSaga from "./multipleJurisdictions/saga"
import MultipleCountrysSaga from "./multipleCountrys/saga"
import CountryRankingsSaga from "./countryRankings/saga"
import SpecificSearchSaga from "./specificSearch/saga"
import editorSaga from "./editor/saga"
import FilerRankings from "./filerRankings/saga"
import Settings from "./settings/saga"
import MadridNotifier from "./madridNotifier/saga"
import Unlock from "./unlock/saga"
import contactsSaga from "./contacts/saga"
import SendingAnalyticsSaga from "./sendingAnalytics/saga"
import AdmDomainManagementSaga from "./admDomainManagement/saga"
import MembershipPlanSaga from "./membershipPlan/saga"
import ServiceManualSaga from "./serviceManual/saga"
import AdmPricingManagementSaga from "./admPricingManagement/saga"
import AdmInvoiceManagementSaga from "./admInvoiceManagement/saga"
import CommunitySaga from "./community/saga"
import EntityConnectManagementSaga from "./entityConnectManagement/saga"
import AdmAccountManagementSaga from "./admAccountManagement/saga"
import MyCaseManagementSaga from "./myCaseManagement/saga"
import hagueNotifierSaga from "./haguenotifier/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    // fork(LayoutSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(ecommerceSaga),
    // fork(invoiceSaga),
    fork(contactsSaga),
    fork(userSaga),
    fork(DashboardSaga),
    fork(GeneralStatisticsSaga),
    fork(MultipleJurisdictionsSaga),
    fork(MultipleCountrysSaga),
    fork(CountryRankingsSaga),
    fork(SpecificSearchSaga),
    fork(editorSaga),
    fork(FilerRankings),
    fork(Settings),
    fork(MadridNotifier),
    fork(Unlock),
    fork(SendingAnalyticsSaga),
    fork(AdmDomainManagementSaga),
    fork(MembershipPlanSaga),
    fork(ServiceManualSaga),
    fork(AdmPricingManagementSaga),
    fork(AdmInvoiceManagementSaga),
    fork(CommunitySaga),
    fork(EntityConnectManagementSaga),
    fork(AdmAccountManagementSaga),
    fork(MyCaseManagementSaga),
    fork(hagueNotifierSaga)
  ])
}
