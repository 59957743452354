import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
    Row,
    Tooltip,
    Col,
    Label,
    Modal,
} from "reactstrap"

import {
    getAllCountryList as onGetAllCountryList,
    changeCountryList as onChangeCountryList,
} from "../../store/actions";
import { cloneDeep } from 'lodash';
  
const SelectAllCountry = props => {
    const dispatch = useDispatch();

    const { countryList } = useSelector(state => ({
        countryList: state.GeneralStatistics.countryList,
    }));

    const [tright, settright] = useState(false);
  
    const [modal_standard, setmodal_standard] = useState(false);
    const [chkAllFlag, setChkAllFlag] = useState(false);
    const [nonEUFlag, setNonEUFlag] = useState(false);
    /**
     * selectedJurList[1] : {'crType' : str}
     * selectedJurList[2] : {'jurList' : [{countryVO},{countryVO},{}...]}
     */
    const [selectedJurList, setSelectedJurList] = useState([]);

    let chkAll = "All";
    let allSubs = "All-subs";
    let nonEU = "Non-eu";
    let individual = "Individual";

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    
    const openCountryModal = () => {
        let _countryList = cloneDeep(countryList);

        if(selectedJurList && selectedJurList.length != 0){
            let chkCnt = 0;
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;

            setChkAllFlag(false);
            setNonEUFlag(false);

            if(selectedJurList.length == 2){
                _countryList = _countryList.map((jur) => {
                    let checkedFlag = false;
                    selectedJurList[1].jurList.map((selectedJur, index) =>{
                    if(jur.crCode == selectedJur.crCode){
                        checkedFlag = true;
                        chkCnt++;
                        if(jur.euYn == 'N'){
                            chkNonEuCnt++;
                        }
                    }
                    });

                    jur.checked = checkedFlag;
                    
                    if(jur.euYn == 'N'){
                        nonEuCnt++; 
                    }
                    return jur;
                });

                if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
                    setNonEUFlag(true);
                }else{
                    setNonEUFlag(false);
                }
                
                if(countryList.length == chkCnt){
                    setChkAllFlag(true);
                }else{
                    setChkAllFlag(false);
                }

                dispatch(onChangeCountryList(_countryList));
            }
        } else {
            // SelectAllCountry 여러개 호출 하는 경우 최초에 로딩시 clear
            _countryList = _countryList.map((jur) => {
                jur.checked = false;
                return jur;
            });

            setNonEUFlag(false);
            setChkAllFlag(false);

            dispatch(onChangeCountryList(_countryList));
        }

        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const search = (keyword) => {
        keyword = keyword.toLowerCase();

        const _countryList = [];
        countryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if(jur.crCode.toLowerCase().includes(keyword) == true || (jur.crName && jur.crName.toLowerCase().includes(keyword) == true)){
            _jur.show = true;
            }else{
            _jur.show = false;
            }

            _countryList.push(_jur);
        }); 

        dispatch(onChangeCountryList(_countryList));
    }

    const jurCheck = (str, checked, isRadio) => {
        // console.log('jurCheck : ' + str + ' / ' + checked)
        let chkCnt = 0;
        let nonEuCnt = 0;
        let chkNonEuCnt = 0;

        const _countryList = [];
        countryList.map((jur, index) =>{
            let _jur = cloneDeep(jur);

            if((str == chkAll 
                || (str == nonEU && checked == false))
                && jur.show){
                _jur.checked = checked;
            }else if(str == nonEU && checked == true && jur.show){
                if(jur.euYn == 'N'){
                    _jur.checked = true;
                }else{
                    _jur.checked = false;
                }
            }else{
                if(jur.crCode == str){
                    _jur.checked = checked;
                }

                if (isRadio && jur.crCode !== str) {
                    _jur.checked = false;
                }
            }

            if(_jur.checked == true){
                chkCnt++;
                if(jur.euYn == 'N'){
                    chkNonEuCnt++;
                }
            }

            if(jur.euYn == 'N'){
                nonEuCnt++;
            }

            _countryList.push(_jur);
        }); 

        if(nonEuCnt == chkNonEuCnt && chkNonEuCnt == chkCnt){
            setNonEUFlag(true);
        }else{
            setNonEUFlag(false);
        }
        
        if(countryList.length == chkCnt){
            setChkAllFlag(true);
        }else{
            setChkAllFlag(false);
        }

        dispatch(onChangeCountryList(_countryList));
    }

    const jurConfirm = () => {
        let _crCdList = [];

        countryList.map((jur, index) =>{
            if(jur.checked == true){
                _crCdList.push(jur.crCode);
            }
        });

        let _values = cloneDeep(props.formik.values);

        if (props.componentName==='Holder') {
            _values.holderCdList = _crCdList;
        } else if (props.componentName==='Representative') {
            _values.repCdList = _crCdList;
        } else {
            _values.crCdList = _crCdList;
        }

        // console.log('jurConfirm : ' + props.componentName);
        
        props.formik.setValues(_values);

        // openCountryModal();
        closeCountryModal(_crCdList);
    }

    const closeCountryModal = (_crCdList) => {
        htmlSetting(_crCdList)
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    const htmlSetting = (crCdList) => {
        if(crCdList && crCdList.length && !(crCdList.length === 1 && crCdList[0] === "")){
            let _selectedJurList = [];
            let _tempList = [];
            let crType = "";
            let nonEuCnt = 0;
            let chkNonEuCnt = 0;
            
            countryList.map((jur, index) =>{
                if(crCdList.includes(jur.crCode)){
                    _tempList.push(jur);
                    if(jur.euYn == 'N'){
                        chkNonEuCnt++;
                    }
                }
                
                if(jur.euYn == 'N'){
                    nonEuCnt++;
                }
            });
            
            //console.log("nonEuCnt: "+nonEuCnt+" //chkNonEuCnt: "+ chkNonEuCnt+" //crCdList.length: "+crCdList.length);
            //console.log("htmlSetting crCdList.length : "+ crCdList.length + "//// countryList.length : " + countryList.length + "//// props.formik.values.inOutType : "+ props.formik.values.inOutType);
            if(crCdList.length === countryList.length){
                if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType && props.componentName !== 'Representative'){
                    crType = allSubs;
                }else{
                    crType = chkAll;
                }
            }else if(nonEuCnt === chkNonEuCnt && chkNonEuCnt === crCdList.length){
                crType = nonEU;
            }else {
                crType = individual;
            }
            
            _selectedJurList.push({'crType' : crType});
            _selectedJurList.push({'jurList' : _tempList});

            //saveSetting 기능에 필요
            if(props.formik.values.crType !== undefined 
                || (props.componentName==='Holder' && props.formik.values.holderType !== undefined)
                || (props.componentName==='Representative' && props.formik.values.repType !== undefined)){
                // let _values = cloneDeep(props.formik.values);

                if (props.componentName==='Holder') {
                    props.formik.setFieldValue("holderType", crType);
                } else if (props.componentName==='Representative') {
                    props.formik.setFieldValue("repType", crType);
                } else {
                    props.formik.setFieldValue("crType", crType);
                }
            }

            setSelectedJurList(_selectedJurList);
        }else{
            let crType = chkAll;

            if(props.formik.values.crType !== undefined 
                || (props.componentName==='Holder' && props.formik.values.holderType !== undefined)
                || (props.componentName==='Representative' && props.formik.values.repType !== undefined)){

                if (props.componentName==='Holder') {
                    props.formik.setFieldValue("holderType", crType);
                } else if (props.componentName==='Representative') {
                    props.formik.setFieldValue("repType", crType);

                    // console.log("repType setFieldValue : "+ crType);
                    // let _values = cloneDeep(props.formik.values);
                    // console.log("_values : "+ JSON.stringify(_values));
                } else {
                    props.formik.setFieldValue("crType", crType);
                }
            }

            setSelectedJurList([]);
        }
    }

    useEffect(()=> {
        if (props.componentName === 'Representative') {
            dispatch(onGetAllCountryList({inOutType : ""}));
        } else {
            dispatch(onGetAllCountryList({inOutType : props.formik.values.inOutType}));
        }

        if(countryList.length > 0){
            if (props.componentName && props.componentName === 'Holder') {
                htmlSetting(props.formik.values.holderCdList);
            } else if (props.componentName && props.componentName === 'Representative') {
                htmlSetting(props.formik.values.repCdList);
            } else {
                htmlSetting(props.formik.values.crCdList);
            }
        }
    },[props.formik.values.inOutType]);

    // useEffect(()=> {
    //     if(!props.componentName || (props.componentName !== 'Holder' && props.componentName !== 'Representative' && countryList && countryList.length > 0)){
    //         // console.log('htmlSetting crCdList : ' + props.formik.values.crCdList);
    //         htmlSetting(props.formik.values.crCdList);
    //     }
    // },[JSON.stringify(props.formik.values.crCdList)]);

    // useEffect(()=> {
    //     if(props.componentName && props.componentName === 'Holder' && countryList && countryList.length > 0){
    //         // console.log('htmlSetting holderCdList : ' + props.formik.values.holderCdList);
    //         htmlSetting(props.formik.values.holderCdList);
    //     }
    // },[JSON.stringify(props.formik.values.holderCdList)]);

    // useEffect(()=> {
    //     if(props.componentName && props.componentName === 'Representative' && countryList && countryList.length > 0){
    //         // console.log('htmlSetting repCdList : ' + props.formik.values.repCdList);
    //         htmlSetting(props.formik.values.repCdList);
    //     }
    // },[JSON.stringify(props.formik.values.repCdList)]);
    
    useEffect(()=> {
        //search settings 불러올때 inOutType하고 crCdList가 동시에 변경됨
        //onGetCountryList()이 완료되기 전에 htmlSetting()이 호출되면서 html이 잘못 나오는 이슈 보완
        if(countryList.length > 0){
            // console.log('htmlSetting countryList : ' + props.componentName);
            if (props.componentName && props.componentName === 'Holder') {
                htmlSetting(props.formik.values.holderCdList);
            } else if (props.componentName && props.componentName === 'Representative') {
                htmlSetting(props.formik.values.repCdList);
            } else {
                htmlSetting(props.formik.values.crCdList);
            }
        }
    },[countryList.length]);

    return (
        <>
        <Row className="mb-3">
            <Col md={props.firstColMd ? props.firstColMd : 3}>
                <label
                    className="col-form-label"
                >
                    {props.componentName ? 
                        props.componentName === 'RadioCountry' || props.componentName === 'RadioRegist'? 'Home country' 
                            : props.componentName === 'Holder' ? 'Holder’s country'
                                : props.componentName === 'Representative' ? 'Representative’s country' 
                                : 'Countries of origin'
                    : 'Countries of origin'}
                </label>
            </Col>
            <Col md={props.componentName && props.componentName === 'RadioCountry' ? 4 : props.componentName && props.componentName === 'RadioRegist' ? 5 : 3}>
                <label
                    className="col-form-label"
                >
                {
                    (()=> {
                        if (selectedJurList && selectedJurList.length != 0) {
                            if(selectedJurList[0].crType === chkAll){
                                return 'All (default)'
                            } else if(selectedJurList[0].crType === allSubs){
                                return 'All subscribed (default)'
                            } else if(selectedJurList[0].crType === nonEU){
                                return 'Non-EU'
                            } else {
                                return selectedJurList[1].jurList.map((selectedJur, index) => {
                                    return (<>{selectedJur.crCode} {selectedJur.crName} <br/></>)
                                });
                            }
                        }else{
                            
                            if(props.formik.values.inOutType && "OUT" === props.formik.values.inOutType && props.componentName !== 'Representative'){
                                return 'All (default)'
                            }else{
                                return 'All (default)'
                            }
                            //return 'All (default)'
                        }
                    })()
                }
                </label>
            </Col>
            <Col md={props.secondColMd ? props.secondColMd : 3} style={props.componentName && props.componentName === 'RadioRegist' ? {textAlign: "right"} : null}>
                <Tooltip
                    placement="right"
                    isOpen={tright}
                    target={"selectCountries" + (props.componentName ? props.componentName : "")}
                    toggle={() => {
                        settright(!tright)
                    }}
                >
                    <span style={{whiteSpace : 'pre'}}>
                        {props.componentName ?
                            props.componentName === 'RadioCountry' || props.componentName === 'RadioRegist' ? 'Home country' 
                                : props.componentName === 'Holder' ? 'Select Holder’s country'
                                    : props.componentName === 'Representative' ? 'Select Representative’s country'
                                        : 'Countries of your \n potential clients'
                        : 'Countries of your \n potential clients'}</span>
                </Tooltip>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={!props.componentName || props.componentName !== 'RadioRegist' ? {width : '148.58px'} : null}
                    id={"selectCountries" + (props.componentName ? props.componentName : "")}
                    onClick={() => {
                        openCountryModal();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                >
                    {props.componentName && props.componentName === 'RadioCountry' ? 'Select country' : props.componentName && props.componentName === 'RadioRegist' ? 'Select' : 'Select countries'}
                </button>
            </Col>
        </Row>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
            openCountryModal()
            }}
            scrollable={true}
        >
            <div className="modal-header" style={{marginRight: "20px"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select {props.componentName && props.componentName === 'RadioCountry' ? 'country' : 'countries'}
                </h5>
                <div className="app-search" style={{padding : '0'}}>
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        style={{border: "solid 1px"}}
                        placeholder="Search..."
                        onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                            search(e.target.value);
                        }                      
                        }}
                    />
                    <span className="uil-search"></span>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        openCountryModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {props.componentName && (props.componentName === 'RadioCountry' || props.componentName === 'RadioRegist') ? null 
                : 
                <Row style={{margin : 'auto'}}>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={chkAll}
                        checked={chkAllFlag}
                        onClick={(e)=>jurCheck(chkAll, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={chkAll}
                        >
                        Select all
                        </Label>
                    </div>
                    <div className="col-md-3 form-check mb-2">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id={nonEU}
                        checked={nonEUFlag}
                        onClick={(e)=>jurCheck(nonEU, e.target.checked)}
                        />
                        <Label
                        className="form-check-label"
                        htmlFor={nonEU}
                        >
                        Non-EU
                        </Label>
                    </div>
                </Row>
                }
                {
                    countryList && countryList.length ?
                    countryList.map((jur, index) => {
                        return (
                            jur.show? 
                            <div className="form-check mb-2">
                                {props.componentName && (props.componentName === 'RadioCountry' || props.componentName === 'RadioRegist') ? 
                                <input
                                type="radio"
                                className="form-check-input"
                                name="countryRadios"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked, true)}
                                />
                                :
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={jur.crCode}
                                checked={jur.checked}
                                onClick={(e)=>jurCheck(jur.crCode, e.target.checked)}
                                />
                                }
                                <Label
                                className="form-check-label"
                                htmlFor={jur.crCode}
                                >
                                {jur.crCode} {jur.crName}
                                </Label>
                            </div>
                            
                            : null
                        )
                    })
                    :
                    null
                }
            
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                    openCountryModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=> jurConfirm()}
                >
                    Confirm
                </button>
            </div>
        </Modal>
        </>
    )
};

export default SelectAllCountry;