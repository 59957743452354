import React, { useEffect } from "react";
import { Container, Row, Col, CardBody, Card, Form, FormFeedback, Input } from "reactstrap";
//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import DashboardCard from "../../components/Common/DashboardCard";
import MadridChart from "./madridChart";
import FilerChart from "./filerChart";
import TopFiler from "./topFiler";
import SubscriptionDetails from "./subscriptionDetails";
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingModal from "../../components/Common/LoadingModal"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import {
  getDashboardCardInfo as onGetDashboardCardInfo,
  sendFeedback as onSendFeedback,
  setResult as onSetResult,
  setDashboardCardLoading as onSetDashboardCardLoading,
  setOcmnLoading as onSetOcmnLoading,
} from "../../store/actions";
import { round } from "lodash";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading, monthData, wecrestMax, emailCnt, monthlyData, subsDetails, rankingList, result, userGrade, ocmnOpen, ocmnList, ocmnLoading } = useSelector(state => ({
    loading: state.Dashboard.loading,
    monthData: state.Dashboard.monthData,
    wecrestMax: state.Dashboard.wecrestMax,
    emailCnt: state.Dashboard.emailCnt,
    monthlyData: state.Dashboard.monthlyData,
    subsDetails: state.Dashboard.subsDetails,
    rankingList: state.Dashboard.rankingList,
    result: state.FilerRankings.result,
    userGrade: state.Dashboard.userGrade,
    ocmnOpen: state.MadridNotifier.ocmnOpen,
    ocmnList: state.MadridNotifier.ocmnList,
    ocmnLoading: state.MadridNotifier.ocmnLoading,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,
    initialValues: {
      textarea: ''
    },
    validationSchema: Yup.object({
      textarea: Yup.string().required("Please Enter Your comments"),
    }),
    onSubmit: (values) => {
      dispatch(onSendFeedback({feedback : values["textarea"]}));
    }
  });

  useEffect(() => { 
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (obj.adminYn === 'Y' && (!obj.virtualLogIn || obj.virtualLogIn === 'N')) {
        window.location.href='/admMyHome';
      }
    }

    dispatch(onGetDashboardCardInfo());
  }, []);

  const reports = [
    {
      id: 1,
      title: "Refusals today",
      value: monthData && monthData.length? monthData[0].todayRefusals : 0,
      decimal: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      icon: monthData && monthData.length ? 
            (
              monthData[1].refusalCnt == 0 ? ""
              : monthData[0].refusalCnt - monthData[1].refusalCnt > 0? "mdi mdi-arrow-up-bold" 
              : monthData[0].refusalCnt - monthData[1].refusalCnt < 0? "mdi mdi-arrow-down-bold" : ""
            )
            : "",
      title: "Refusals last 30 days",
      value: monthData && monthData.length? monthData[0].refusalCnt : 0,
      prefix: "",
      suffix: "",
      badgeValue: monthData && monthData.length ?
                  (
                    monthData[1].refusalCnt == 0 ? '-' : 
                    (monthData[0].refusalCnt > 0 && monthData[1].refusalCnt > 0) ? round((monthData[0].refusalCnt - monthData[1].refusalCnt)/monthData[1].refusalCnt*100)+'%' : '-'
                  ) : '-',
      decimal: 0,
      color: monthData && monthData.length ?
              (
                (monthData[0].refusalCnt > 0 || monthData[1].refusalCnt > 0) && round((monthData[0].refusalCnt - monthData[1].refusalCnt)/monthData[1].refusalCnt*100) > 0? "success" 
                : round((monthData[0].refusalCnt - monthData[1].refusalCnt)/monthData[1].refusalCnt*100) < 0 ? "danger" : ""
              )
              : "" ,
      desc: "Compared to previous 30 days",

    },
    {
      id: 3,
      icon: monthData && monthData.length ?
            (
              monthData[1].sentMnEmailCnt == 0 ? ""
              : monthData[0].sentMnEmailCnt - monthData[1].sentMnEmailCnt > 0? "mdi mdi-arrow-up-bold" 
              : monthData[0].sentMnEmailCnt - monthData[1].sentMnEmailCnt < 0? "mdi mdi-arrow-down-bold" : ""
            )
            : "",
      title: "Emails sent last 30 days",
      value: monthData && monthData.length? monthData[0].sentMnEmailCnt : 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: monthData && monthData.length && monthData[0].sentMnEmailCnt && monthData[1].sentMnEmailCnt ?
                  (
                    monthData[1].sentMnEmailCnt == 0 ? '-' : 
                    (monthData[0].sentMnEmailCnt > 0 && monthData[1].sentMnEmailCnt > 0) ? round((monthData[0].sentMnEmailCnt - monthData[1].sentMnEmailCnt)/monthData[1].sentMnEmailCnt*100)+'%' : '-'
                  ) : '-',
      color: monthData && monthData.length ?
            (
              (monthData[0].sentMnEmailCnt > 0 || monthData[1].sentMnEmailCnt > 0) && round((monthData[0].sentMnEmailCnt - monthData[1].sentMnEmailCnt)/monthData[1].sentMnEmailCnt*100) > 0? "success" 
              : round((monthData[0].sentMnEmailCnt - monthData[1].sentMnEmailCnt)/monthData[1].sentMnEmailCnt*100) < 0? "danger" : ""
            ) 
            : "",
      desc: "Compared to previous 30 days",
    },
    {
      id: 4,
      title: "Send rate last 30 days",
      value: monthData && monthData.length? monthData[0].sendRate : 0,
      prefix: "",
      suffix: "%",
      badgeValue: wecrestMax && wecrestMax.sendRate? wecrestMax.sendRate+'%' : '-',
      color: "",
      desc: "Best rate among WeCrest member firms",
    },
    {
      id: 5,
      icon: emailCnt && emailCnt.thisMonthEmailCnt? "mdi mdi-arrow-up-bold" : null,
      title: "Contacts in your database",
      value: emailCnt && emailCnt.totalEmailCnt? emailCnt.totalEmailCnt : 0,
      prefix: "",
      suffix: "",
      badgeValue: emailCnt && emailCnt.thisMonthEmailCnt? emailCnt.thisMonthEmailCnt : '-',
      color: emailCnt && emailCnt.thisMonthEmailCnt && emailCnt.thisMonthEmailCnt > 0? "success" : "",
      desc: "Since 30 days ago",
    },
  ];

  const setDashboardCardLoading = () => {
    dispatch(onSetDashboardCardLoading(!loading));
  }

  const setOcmnLoading = () => {
    dispatch(onSetOcmnLoading(!ocmnLoading));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {
          /*
          loading ?
          <LoadingModal loading={loading} setLoading={setDashboardCardLoading}></LoadingModal>
          : null
          */
        }
        {
          /*
          ocmnOpen && ocmnList.length === 0 && ocmnLoading?
          <LoadingModal loading={ocmnLoading} setLoading={setOcmnLoading}></LoadingModal>
          :
          null
          */
        }
        <Container fluid>
          {/*
          <Breadcrumbs title="Minible" breadcrumbItem="Dashboard" />
          */}
          <Row>
            <DashboardCard reports={reports} />
          </Row>
          <Row>
            <Col xl={12}>
              <MadridChart data={monthlyData}/>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <FilerChart data={monthlyData}/>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <TopFiler data={rankingList} userGrade={userGrade}/>
            </Col>
            <Col xl={4}>
              <Card style={{height : '95%'}}>
                  <CardBody>
                      <h4 className="card-title mb-4">Tutorial videos 1</h4>
                      <SimpleBar style={{ maxHeight: "321px" }}>
                      <iframe width="100%" height="321px" src="https://www.youtube.com/embed/LDexveQNy8c?si=5YBPzBTr3RJx9ZGW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      
                      {/*
                      <iframe width="100%" height="321px" src="https://www.youtube.com/embed/videoseries?si=eYQFFJbYtXVx99oQ&amp;list=PLOnqRhQ7lYWEviHUdBzOOR4CW4dGfcJSK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      <iframe width="100%" height="321px" src="https://www.youtube.com/embed/videoseries?list=PLOnqRhQ7lYWEviHUdBzOOR4CW4dGfcJSK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      */}
                      </SimpleBar>
                  </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card style={{height : '95%'}}>
                  <CardBody>
                    <Form onSubmit={validation.handleSubmit}>
                      <h4 className="card-title mb-4">Feedback or questions?</h4>
                      <SimpleBar style={{ maxHeight: "321px" }}>
                      <div className={validation.touched.textarea && validation.errors.textarea ? null:"mb-3"}>
                        <Input
                          name="textarea"
                          placeholder="Write your comments here"
                          type="textarea"
                          rows="11"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.textarea || ""}
                          invalid={
                            validation.touched.textarea && validation.errors.textarea ? true : false
                          }
                        />
                        {validation.touched.textarea && validation.errors.textarea ? (
                          <FormFeedback type="invalid">{validation.errors.textarea}</FormFeedback>
                        ) : null}
                      </div>
                      <button type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >Submit</button>
                      </SimpleBar>
                    </Form>
                  </CardBody>
              </Card>
            </Col>
          </Row>
          <SubscriptionDetails data={subsDetails} />
        </Container>
        {result !== -1 ? (
          <SweetAlert
            onConfirm={() => {
              dispatch(onSetResult(-1));
              validation.resetForm();
            }}
          >send success
          </SweetAlert>
          ) : null}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;